import { Button, Form, Input, Modal } from 'antd'
import { useTranslation } from 'react-i18next';

export const PhoneEdit = ({phoneForm, handleCancel, enabled, setEnabled, fieldId}: any) => {
  
  const { t } = useTranslation();

  return (
    <>
            <Form.Item name='mainPhone'
              style={{ width: '80%' }}
              rules={[{ pattern: new RegExp(/^[0-9]+$/), message: t('view.forms.fields.phone.rule') as string }]}>
              <Input id={fieldId}
              onChange={(e) => { setEnabled(e.target.value !== '' ? e.target.id : '') }}
              onFocus={(e) => { setEnabled(e.target.value !== '' ? e.target.id : '') }} />
            </Form.Item>

            <Form.List name="additionalPhones">
              {(fields, { add, remove }) => {
                return (
                  <>
                    {
                      fields.map((field, index) => (
                        <div key={field.key}>
                          {
                            <div style={{ display: 'flex' }}>
                              <Form.Item name={[index]}
                                style={{ width: '80%' }}
                                rules={[{ pattern: new RegExp(/^[0-9]+$/), message: t('view.forms.fields.phone.rule') as string }] }>
                                <Input />
                              </Form.Item>
                              {
                                fields.length > 0 && <button
                                  style={{ width: '20%', height: '32px', backgroundColor: "transparent", border: "none", fontWeight: 600, padding: "0 1em", cursor: "pointer" }}
                                  onClick={() => remove(field.name)} >
                                  X
                                </button>
                              }
                            </div>
                          }
                        </div>
                      ))
                    }
                    {(fields.length < 2) &&
                      <button type='button' style={{ paddingBottom: "1em", border: "none", backgroundColor: "transparent", color: "#2781DD", fontWeight: 600, cursor: "pointer" }} onClick={() => add()}>
                        { t('view.forms.fields.phone.add') }
                      </button>}
                  </>)
              }}
            </Form.List>
            <div><Button
                  disabled={!!phoneForm.getFieldsError().filter(({ errors }:any) => errors.length).length || enabled !== fieldId}
                  onClick={() => {
                    Modal.confirm({
                      title: t('view.forms.modal.bulk.title'),
                      content: t('view.forms.modal.bulk.content', { field: t('dictionary.phone').toLowerCase() }),
                      okText: t('dictionary.confirm'),
                      cancelText: t('dictionary.cancel'),
                      onOk: phoneForm.submit
                    })
                  }}
                  type="primary">
                  {t('dictionary.save')}
                </Button>
                  <Button
                    style={{ background: 'white', color: 'dodgerblue', marginLeft: '20px' }}
                    onClick={() => { 
                      handleCancel!() }}
                    type="primary">
                    { t('dictionary.cancel') }
                  </Button>
                </div>
          </>
  )
}
