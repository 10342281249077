import { Form, message } from 'antd'
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../../../../../store/AuthContext/AuthContext'
import { getProjectStorage } from '../../../../../../store/storageContext'
import { authFetch } from '../../../../../../common/request/authFetch'
import EditableInputItem from '../../../business/forms/EditableInputItem'
import { useContext } from 'react';
import { GlobalVariableContext } from '../../../../../../store/GlobalVariableContext/GlobalVariableContext';
import { DrawerContext } from '../../../../../../store/DrawerContext/DrawerContext';

export const WebEdit = (props: {url ?: string, locationsIds : string[], setEdit?:  any, edit?:boolean, setCurrentURL : (url : string) => void, isMassive: boolean}) => {    
    
    const { groupAccount } = useAuth();

    const [webForm] = Form.useForm();
    const { t } = useTranslation();

    const {updateGlobalData, setUpdateGlobalData } = useContext(GlobalVariableContext)
    const { setLogsDrawer } = useContext(DrawerContext)

  
    const submitWeb = async(values:any) => {
        const data = {		
			field: 'WEB',		
			web: values.web,
      locationsNames: props.locationsIds
		};


    if (props.isMassive) {
        await authFetch(`${process.env.REACT_APP_API}/projects/` + getProjectStorage() + '/' + groupAccount + '/locations', { method: 'PATCH', data: data })
        .then((res) => {
          if (res) {	
            message.loading(t('view.forms.modal.bulk.triggered'), 5);					
            setUpdateGlobalData(!updateGlobalData)
            props.setEdit(false);
            setTimeout(() => {
              setLogsDrawer(true);
            }, 500)
          } else {
            message.error(t('view.forms.modal.bulk.error'), 5);							
          }
        });		
    } else {
      await authFetch(`${process.env.REACT_APP_API}/projects/` + getProjectStorage() + '/' + groupAccount + '/' + props.locationsIds[0], { method: 'PATCH', data: data })
      .then((res) => {

        if (res) {
          message.success(t('view.forms.modal.single.success', { field: t('dictionary.web').toLowerCase() }), 3);							
          props.setEdit(false);
          props.setCurrentURL(values.web);
          setUpdateGlobalData(!updateGlobalData)
        } else {
          // TODO Mostrar error
          message.error(t('view.forms.modal.single.error'), 3)
        }
      });		
    }
    } 
    return (
        <div>
            <Form layout='vertical' form={webForm} onFinish={(values) => submitWeb(values)} initialValues={{web:props.url}}>					
              <EditableInputItem 
                  form={webForm}
                  field='web' 
                  value={props.url}
                  label={props.isMassive ? (t('dictionary.website') as string) : undefined}                       
                  onSubmitCallback={webForm.submit}
                  rules={[{ type: 'url', message: t('view.forms.fields.website.rule') as string }]}
                  isMassive={props.isMassive}
                  edit={props.edit}
                  setEdit={props.setEdit}
                />                        
              </Form>	
        </div>
    )
}