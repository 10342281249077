import { useTranslation } from "react-i18next";
import { useFetch } from "../../../../../hooks/useFetch/useFetch";
import { useAuth } from "../../../../../store/AuthContext/AuthContext";
import { getProjectStorage } from "../../../../../store/storageContext";
import { useLocation } from "react-router-dom";
import { useContext, useState } from "react";
import { SpinLZ } from "../../../../components/design/Spinner/SpinLZ";
import { BlockReviewsKpis } from "../../blocks/BlockReviewsKpis";
import { BlockReviewsGlobal } from "../../blocks/BlockReviewsGlobal";
import { BlockReviewsByLocation } from "../../blocks/BlockReviewsByLocation";
import { DrawerContext } from "../../../../../store/DrawerContext/DrawerContext";
import { Drawer } from "antd";


export const LocationStatisticsDrawer = () => {

    const { groupAccount } = useAuth();
    
    const [reviewsKPIs, setReviewsKPIs] = useState<any>(null);
    const [reviewsData, setReviewsData] = useState<any>();
    const [locationInfo, setLocationInfo] = useState<any>();
    const [isLoading, setIsLoading] = useState(true);
    
    const { locationStatisticsDrawer, setLocationStatisticsDrawer} = useContext(DrawerContext)

    const { t } = useTranslation();
    const location = useLocation();
    
    useFetch(`${process.env.REACT_APP_API}/projects/` + getProjectStorage() + '/' + groupAccount + '/summary/reviews?locationId=' + locationStatisticsDrawer, {}, [],    
        (data: any) => {
            setReviewsKPIs(data.reviewsKPIs);
            setReviewsData(data);
            setIsLoading(false);
            setLocationInfo(data.location);
        });

    return (

        <Drawer
            title={locationInfo && 
                <>
                    <div>{locationInfo.name}</div>
                    <small style={{color:'grey'}}>{locationInfo.address}</small>
                </>
            }
            placement="right"
            width={'55%'}
            visible={location != null}
            onClose={() => setLocationStatisticsDrawer(null)}
            bodyStyle={{ background: '#F4F7FA' }}>              		                                
                {isLoading ? <SpinLZ /> : <div className="card-container">                    
                    <h2>Totales</h2>
                    {reviewsKPIs && <BlockReviewsKpis reviewsKPIs={reviewsKPIs}/>}
                    <h2>Último año:</h2>
                    {reviewsData && <BlockReviewsGlobal data={reviewsData}/>}                    
                    <br/>
                    {reviewsData && <BlockReviewsByLocation data={reviewsData.locationsKpis} showButtons={false}/>}                                        
                </div>
                }
        </Drawer>
    )
}