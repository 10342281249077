import { SyncOutlined } from '@ant-design/icons';
import { Col, message, Progress, Row, Switch } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { authRequest } from '../../../common/request/authRequest';


const GroupAccount = (props: {account: any, project: string, enableGroups: number, setEnableGroups: any} ) => {

  const [locationsNumber, setLocationsNumber] = useState(0);  
  const [isLoading, setIsLoading] = useState(false);  
  const [count, setCount] = useState(2);
  const [percent, setPercent] = useState(5);
  const { t } = useTranslation();

   useEffect(() => {	     
     authRequest(`${process.env.REACT_APP_API}/projects/${props.project}/${props.account.id}/locations/sync`, { method: 'POST' })     
		.catch(() => {
      message.error(t('view.errors.loadBusiness'));      
    });
  },[]);   
  
  useEffect(() => {
    
    setIsLoading(true);
    
    authRequest(`${process.env.REACT_APP_API}/projects/${props.project}/${props.account.id}/locations/count`, {})
      .then((response) => {

        let previousLocationsNumber = locationsNumber;
        
        setLocationsNumber(response);
        setTimeout(() => {
          if (locationsNumber == previousLocationsNumber && count > 4 || count > 40) {
            setCount((count) => count + 1);
            setPercent(Math.floor(100 - (100/count)));
          } else {
            setPercent(100);
            setIsLoading(false);
          }
        }, 2000);
      })
      .catch(() => {message.error(t('view.errors.loadBusiness', { name: props.account.name }))});
      }
,[count]);    

const addGroup = (e : boolean) => {
  
  authRequest(`${process.env.REACT_APP_API}/users/principal/projects/${props.project}/${props.account.id}?activate=${e}`, { method: 'PATCH' })
  .then((response) => {
    if (e) {
      message.success(t('view.forms.modal.group.success', { name: props.account.name }))
      props.setEnableGroups(props.enableGroups + 1)
    } else {
      message.success(t('view.forms.modal.group.disconnect', { name: props.account.name }))      
      props.setEnableGroups(props.enableGroups - 1)
    }
  }).catch(() => {
      message.error(t('view.forms.modal.group.error', { name: props.account.name }))
    });
}

  return (  
    <Row style={{width:'100%'}}>
      <Col span={4}>      
        {<Switch checkedChildren="Activado" unCheckedChildren="Desactivado"  loading={isLoading} disabled={!isLoading && locationsNumber==0} onChange={(e) => {addGroup(e)}}/>}
      </Col>
      <Col span={10}>
        {props.account.name}        
        <small style={{ color: 'grey' }}><br />{props.account.type}</small>        
        </Col>
        <Col span={1}>      
          {isLoading && <SyncOutlined spin />  }            
        </Col>
        <Col span={7}>      
          <Progress percent={percent} />        
          <small>{locationsNumber} {t('dictionary.shops').toLowerCase()} </small>         
        </Col>  
    </Row>             
	)
}

export default GroupAccount;