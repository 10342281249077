import { Result } from "antd";
import { useTranslation } from 'react-i18next';

const Error403 = () => {

  const { t } = useTranslation();

  return (
    <Result
    status="403"
    title="403"
    subTitle={t('view.errors.403')}    
  />)
}

export default Error403;