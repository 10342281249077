import { CheckOutlined, CloudDownloadOutlined, LoadingOutlined, SyncOutlined, SearchOutlined, UploadOutlined } from '@ant-design/icons';
import { Badge, Card, Spin, Input, Switch } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFetch } from '../../../hooks/useFetch/useFetch';
import ProjectsAddModal from '../menuAdmin/4_2_projects/ModalAddProject/ProjectsAddModal';
import TableLZM from '../../components/design/table/TableLZM';
import { ButtonLZ } from '../../components/design/Buttons/ButtonLZ';
import { customRequest } from '../../../common/request/customRequest';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import { authFetch } from '../../../common/request/authFetch';
import { UploadWebsModal } from './UploadWebsModal';

export const ExternalWebs = () => {

    const [uploadModal, setUploadModal] = useState(false);
    const [updateData, setUpdateData] = useState(false);
    const [projects, setProjects] = useState([]);
    const [isSynchronizing, setIsSynchronizing] = useState(false);


    const { data, isLoading } = useFetch(`${process.env.REACT_APP_API}/externalWebsites`, {}, [updateData]);

    async function changeStatus(e: boolean, record:any) {
        await authFetch(`${process.env.REACT_APP_API}/externalWebsites/status?url=${record.url}&status=${e}`, { method: 'POST' }).then(()=>{
            setUpdateData(!updateData)
        });

    }

    const columns = [
        {
            title: 'Url',
            key: 'url',
            dataIndex: 'url',
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
            }: FilterDropdownProps) => {
                return (
                    <Input
                        autoFocus
                        placeholder="Url"
                        value={selectedKeys[0]}
                        onChange={(e: any) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    ></Input>
                );
            },
            filterIcon: () => {
                return <SearchOutlined />;
            },
            onFilter: (value: any, record: any) => {
                return record.url.toLowerCase().includes(value.toLowerCase());

            },
        },
        {
            title: 'Título',
            key: 'title',
            dataIndex: 'title',
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
            }: FilterDropdownProps) => {
                return (
                    <Input
                        autoFocus
                        placeholder="Título"
                        value={selectedKeys[0]}
                        onChange={(e: any) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    ></Input>
                );
            },
            filterIcon: () => {
                return <SearchOutlined />;
            },
            onFilter: (value: any, record: any) => {
                return record.title.toLowerCase().includes(value.toLowerCase());
            },
        },
        {
            title: 'Teléfono 1',
            key: 'phone1',
            dataIndex: 'phone1',
        },
        {
            title: 'Teléfono 2',
            key: 'phone2',
            dataIndex: 'phone2',
        },
        {
            title: 'Dirección',
            key: 'address',
            dataIndex: 'address',
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
            }: FilterDropdownProps) => {
                return (
                    <Input
                        autoFocus
                        placeholder="Dirección"
                        value={selectedKeys[0]}
                        onChange={(e: any) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    ></Input>
                );
            },
            filterIcon: () => {
                return <SearchOutlined />;
            },
            onFilter: (value: any, record: any) => {
                return record.address.toLowerCase().includes(value.toLowerCase());

            },
        },
        {
            title: 'Contactado',
            key: 'contacted',
            dataIndex: 'contacted',
            render: (text: string, record: any) => (
                <Switch size='small' checked={record.contacted} onChange={(e) => {changeStatus(e,record)}} />
            ),
            filters: [
                { text: 'Contactados', value: true },
                { text: 'No contactados', value: false },
              ],
              onFilter: (value: any, record: any) => record.contacted === value,
        },

    ]


    /* Webs Externas */

    return (
        <>
            <Card className='main-card-content'>
                <div style={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between', marginBottom: '10px' }}>
                    <h1 className='title-card'>Webs Externas</h1>
                    <ButtonLZ color="dark-white" size='small' icon={<UploadOutlined />} onClick={() => setUploadModal(true)} children={undefined} />
                </div>
                <TableLZM exportable={true} columns={columns} dataSource={data} rowKey={'url'} loading={isLoading} />

            </Card>

            {uploadModal && (
                <UploadWebsModal
                    visible={uploadModal}
                    onFinish={() => {
                        setUploadModal(false);
                        setUpdateData(!updateData);
                    }}
                />
            )}
        </>
    )
}


