import { PictureOutlined } from '@ant-design/icons';
import { Tag, Modal, Tabs, Alert } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import UploadInput from '../../../components/model/business/forms/UploadInput';

const LogoPanel = ({
	selectedRowKeys,
	visible,
	onFinish,
	onCancel,
}: any) => {

	const [enabled, setEnabled] = useState('');

	const { t } = useTranslation();


	const onFormCancel = async (values: any) => {
		setEnabled('');
		onCancel();
	}

	return (
		<Modal visible={visible} footer={null} onCancel={onFormCancel} width={'60%'}>

			<h2 style={{ paddingTop: '5px', paddingBottom: '5px', paddingRight: '15px' }}>{t('locals.page.management.modals.logo.title')}</h2>
			<Alert message={<div>{selectedRowKeys.length} locales seleccionados</div>} type="info" showIcon />

			<div style={{marginTop:'20px'}}>
				<UploadInput
					selectedKeys={selectedRowKeys}
					onFinish={() => onFinish()}
					category="LOGO"
					label={t('locals.page.management.modals.logo.label')}
					textBtn={t('dictionary.apply')}
				/>
			</div>

		</Modal>
	)
}

export default LogoPanel;