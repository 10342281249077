import { BellOutlined, CustomerServiceOutlined, PoweroffOutlined, FileDoneOutlined, SettingFilled, UserAddOutlined, CheckCircleFilled } from '@ant-design/icons';
import { Badge, Button, Divider, Dropdown, Layout, Select, Spin, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFetch } from '../../../../hooks/useFetch/useFetch';
import { useAuth } from '../../../../store/AuthContext/AuthContext';
import { getProjectStorage, setProjectStorage } from '../../../../store/storageContext';
import GoogleAccounts from '../../../components/model/accounts/GoogleAccounts';
import Logo from '../../../components/model/logos/Logo';
import ProfileProjects from '../../../components/model/projects/ProfileProjects';
import MainMenu from '../../../layouts/main/MainMenu';
import Notification from '../Notifications/Notifications';
import MainHeaderPublic from './MainHeaderPublic';
import './MainHeader.scss'
import { ButtonLZ } from '../../../components/design/Buttons/ButtonLZ';
import LanguageSelector from '../../../components/model/language/Selector';
import { authFetch } from '../../../../common/request/authFetch';
import { Client } from '@stomp/stompjs';
import { NotificationView } from '../../Notifications/modal/NotificationView';
import { ROLES } from '../roles/Roles';

const { Header } = Layout;

const MainHeader = () => {

	const { t } = useTranslation();
	const [projects, setProjects] = useState();
	const [isMenuVisible, setIsMenuVisible] = useState(false);
	const [isPublic, setIsPublic] = useState(true);
	const [updateHeader, setUpdateHeader] = useState(false);

	const [updateData, setUpdateData] = useState(false);
	const [isMenuVisibleNot, setIsMenuVisibleNot] = useState(false);

	const [showHeaderContent, setShowHeaderContent] = useState(false);
	const [googleAccounts, setGoogleAccounts] = useState(new Map<any, any>());
	const history = useHistory();
	const location = useLocation();
	const { logOut, profile, setProfile, project, setProject, groupAccount, setGroupAccount } = useAuth();

	useFetch(`${process.env.REACT_APP_API}/users/principal/projectNames`, {}, [], (data) => { // updateHeader

		setProjects(data.map);

		const emptyProjects = !data.defaultValue;

		if (emptyProjects) {
			setShowHeaderContent(false);
		}

		if (!data.defaultValue) {
			setProject('');
		} else if (!getProjectStorage()) {
			setProject(data.defaultValue);
		}
		if (!emptyProjects) {
			setShowHeaderContent(true);
		}
	});

	useEffect(() => {
		if (project != getProjectStorage()) { // When project combo change					
			setProjectStorage(project);
		}
	}, [project])

	useFetch(`${process.env.REACT_APP_API}/users/principal/profile`, {}, [updateHeader], (data) => {

		setProfile(data);
		if ("REGISTERED" == data.status) {
			history.push('/vincular', { from: location });
			setIsPublic(true);
		} else if ("CONFIGURED" == data.status) {
			history.push('/vincular', { from: location });
			setIsPublic(true);
		} else if ("ACTIVE" == data.status) {
			setIsPublic(false);
		}
	});

	const accountOverlay = () => {		

		return (
			<div style={{ fontSize: '13px', background: 'white', minWidth: '300px', borderRadius: '10px', boxShadow: '0px 0px 20px -8px', margin: '8px' }}>
				<div style={{ background: 'white', minHeight: '70px', borderRadius: '10px 10px 0px 0px', display: 'flex', flexDirection: 'column', columnGap: '4px', justifyContent: 'center', alignItems: 'center', padding: '10px' }}>
					<p style={{ marginBottom: '0px' }}>{profile.email}</p>
					<b style={{ margin: '10px 0px', fontSize: '12px' }}>						
						{profile.roles.map(
							(role: string, i: number) => (
									<div style={{color:'steelblue'}}><CheckCircleFilled/> {ROLES[role]}</div>
								)
							)
						}
					</b>
					<ButtonLZ shape='round' size='small' color='yellow' onClick={() => { logOut() }}>
						<PoweroffOutlined />
					</ButtonLZ>
				</div>
				
				<Divider orientation='left'>{t('dictionary.myPreferences')}</Divider>
				<div style={{ background: '#F4F7FA', border: '5px', borderColor: 'black', fontSize: '12px', borderRadius: '4px', padding: '8px', margin: '12px'}}>
					<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
						<b style={{ marginLeft: '5px' }}>{t('dictionary.language')}</b>
						<LanguageSelector />					
					</div>										
				</div>
				
				<Divider orientation='left'>{t('dictionary.myBusiness')}</Divider>
				<ProfileProjects profile={profile} updateHeader={updateHeader} setUpdateHeader={setUpdateHeader} />
				<Divider style={{ margin: '8px 0px' }} />
			</div>
		);
	}
/*
	useEffect(() => {
		const client = new Client({
			brokerURL: `${process.env.REACT_APP_NOTIFICATIONS}/ws`,
			onConnect: () => {
				client.subscribe('/topic/notifications', (message) => {
					setUpdateData((prevUpdateData) => !prevUpdateData);

				});
			},
		});

		client.activate();
	}, []);

	const { data: storesData }: any = useFetch(`${process.env.REACT_APP_API}/users/notifications/last`, {}, [updateData]);


	const updateNotifications = async () => {

		await authFetch(`${process.env.REACT_APP_API}/users/notifications`, { method: 'PATCH' }).then(() => {
			setUpdateData(!updateData);
		})
	}

	const notificationOverlay = () => {


		return (
			<div>
				<div className='accountOverlay-notifications'>
					<h5>Notificaciones</h5>
					<div>
						<div>
							{storesData ?
								<><NotificationView notification={storesData}></NotificationView></> : <Spin className='spinner' />
							}
						</div>
						<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
							<Link onClick={() => { setIsMenuVisibleNot(false) }} to='/notificaciones'>Ver más</Link>
						</div>
					</div>
				</div>
			</div>
		);
	}*/

	return (
		isPublic ?
			<MainHeaderPublic />
			:
			<Header className='header'>
				<div style={{ display: 'flex', width: '100%' }}>
					<Logo style={{ display: 'inline', width: '48px', marginRight: '20px', marginLeft: '10px' }} />
					<MainMenu />
				</div>
				<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
					<div className='overlays-container'  >
						<Tooltip title='Soporte'>
							<a href='https://localizame.atlassian.net/servicedesk/customer/portal/1' target='blank'><CustomerServiceOutlined style={{ fontSize: '18px', color: 'grey' }} /></a>
						</Tooltip>

						<Tooltip title='Release notes'>
							<a href='https://localiza.me/release-notes' target='blank' >
								<FileDoneOutlined style={{ fontSize: '18px', color: 'grey' }} />
							</a>
						</Tooltip>				

						{/*<Badge style={{ marginTop: '20px' }} color='var(--primary)' showZero={false} count={storesData?.notReaded} size="small">
							<Dropdown visible={isMenuVisibleNot} onVisibleChange={setIsMenuVisibleNot} overlay={notificationOverlay} trigger={['click']}>
								<BellOutlined style={{ fontSize: '18px', color:'grey' }} onClick={() => { updateNotifications() }} />
							</Dropdown>
						</Badge>*/}

					</div>
					{
						showHeaderContent &&
						<>
							<Select value={project} onChange={value => setProject(value)} bordered={false} style={{ minWidth: '100px', textAlign: 'right' }}>
								{projects && Object.keys(projects).map(project => {
									return (
										<Select.Option key={project} value={projects[project]}>
											{project}
										</Select.Option>);
								})}
							</Select>

						</>
					}
					{
						showHeaderContent &&
						<>
							<GoogleAccounts project={project}
								groupAccount={groupAccount} setGroupAccount={setGroupAccount}
								googleAccounts={googleAccounts} setGoogleAccounts={setGoogleAccounts}
							/>
						</>
					}
					<Dropdown visible={isMenuVisible} onVisibleChange={setIsMenuVisible} overlay={accountOverlay} trigger={['click']}>
						<Button className='buton-profile' shape='round' onClick={() => setIsMenuVisible(true)}>
							{profile.name}
							<span className='profile-email'>{profile.email}</span>
						</Button>
					</Dropdown>
				</div>
			</Header>
	)
};

export default MainHeader;