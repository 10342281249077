import { Form, message } from 'antd'
import  { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../../../../../store/AuthContext/AuthContext';
import { getProjectStorage } from '../../../../../../store/storageContext';
import { authFetch } from '../../../../../../common/request/authFetch';
import EditableInputItem from '../../../business/forms/EditableInputItem';
import { GlobalVariableContext } from '../../../../../../store/GlobalVariableContext/GlobalVariableContext';

export const StoreCodeEditEdit = (props: {locationsIds : string[], setEdit : (isEdit: boolean) => void, edit:boolean, storeCode ?: string, setCurrentStoreCode : (name : string) => void}) => {

    const { groupAccount } = useAuth();
    const [nameForm] = Form.useForm();
    const { t } = useTranslation();

    const {updateGlobalData, setUpdateGlobalData } = useContext(GlobalVariableContext)

    const submitStoreCode = async(values:any) => {

        const data = {
          field: 'STORE_CODE',
          storeCode: values.storeCode,
          locationsNames: props.locationsIds
        };

        await authFetch(`${process.env.REACT_APP_API}/projects/` + getProjectStorage() + '/' + groupAccount + '/' + props.locationsIds[0], { method: 'PATCH', data: data })
        .then((res) => {
          
          if (res) {
            message.success(t('view.forms.modal.single.success', { field: 'Código de Tienda' }), 3);
            props.setEdit(false);
            props.setCurrentStoreCode(values.storeCode);
            setUpdateGlobalData(!updateGlobalData);            
          } else {
            message.error(t('view.forms.modal.single.error'), 3)
          }
    });
    
    }
    return (
        <div>
            <Form layout='vertical' form={nameForm} onFinish={(values) => submitStoreCode(values)} initialValues={{name:props.storeCode}}>
            <EditableInputItem
              form={nameForm}
              value={props.storeCode}
              field='storeCode'              
              onSubmitCallback={nameForm.submit}
              edit={props.edit}
              setEdit={props.setEdit}
              isMassive={false}
              />
          </Form>
        </div>
    )
}







