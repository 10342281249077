import { Card } from "antd";
import { useState } from 'react'
import { useAuth } from '../../../../store/AuthContext/AuthContext';
import { useFetch } from "../../../../hooks/useFetch/useFetch";
import { getProjectStorage } from "../../../../store/storageContext";
import { useTranslation } from 'react-i18next';
import './LocationsSummary.css';
import { SpinLZ } from "../../../components/design/Spinner/SpinLZ";
import { Synchronized } from "../../../components/design/Synchronized/Synchronized";
import { SCHEDULEDJOB } from "../../../../common/constants/services";
import { BlockInfoKpis } from "../blocks/BlockInfoKpis";
import { BlockConversion } from "../blocks/BlockConversion";


export const LocationsSummary = () => {

    const { groupAccount } = useAuth();

    const [isLoading, setIsLoading] = useState(true);
    const [kpis, setKpis] = useState<any>();
    const [conversionData, setConversionData] = useState<any>();    

    const { t } = useTranslation();


    useFetch(`${process.env.REACT_APP_API}/projects/` + getProjectStorage() + '/' + groupAccount + '/summary/locations', {}, [],
        (data: any) => {
            setConversionData(data);            
            setKpis(data.kpis);
            setIsLoading(false);
        });

    return (
        <div>
            <Card className='main-card-content' id='element-to-capture'>

                    <Synchronized service={[SCHEDULEDJOB.GBP_LOCATIONS]}/>	

                    <h1 className='title-card'>Estadísticas</h1>

                    {isLoading ? <SpinLZ /> : <div className="card-container">

                    <BlockInfoKpis kpis={kpis}/>
                    <BlockConversion data={conversionData}/>
                
                </div>
                }
            </Card>
        </div>
    )
}