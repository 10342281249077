import axios from "axios";
import { getTokenForStorage } from "../store/storage";
import { getProjectStorage } from "../store/storageContext";

interface UpdatePic {
    type: string,
    config: any
}

export const updatePics = ({ config, type }: UpdatePic): Promise<any> => {
    const jwtToken: string | null = getTokenForStorage()
    const projectId: string | null = getProjectStorage()
    const accountId: string | null = localStorage.getItem(projectId);

    return axios.request({
        ...config, 
        url: `${process.env.REACT_APP_API}/projects/${projectId}/${accountId}/locations/${type}`,
        headers: {
            Authorization: `Bearer ${jwtToken}`
        }
    }).then(response => response.data)
}