import { CheckCircleFilled, CheckCircleOutlined, CloseCircleFilled, CloseCircleOutlined, DeleteOutlined, PlusCircleOutlined, WarningFilled } from "@ant-design/icons";
import { Button, Card, Popconfirm, Spin, Switch, Tooltip, message } from "antd";
import { useState } from "react";
import moment from "moment";
import { useAuth } from "../../../store/AuthContext/AuthContext";
import { useFetch } from "../../../hooks/useFetch/useFetch";
import TableLZM from "../../components/design/table/TableLZM";
import { GeneratedReports } from "./publish/GeneratedReports";
import { authFetch } from "../../../common/request/authFetch";
import reportsNotSelected from '../../../assets/reportsNotSelected.png'
import { AddModalTemplatesModal } from "./templates/AddModalTemplatesModal";
import { ReportPreviewDrawer } from "./report/ReportPreviewDrawer";

export const PageTemplates = () => {

    const { groupAccount } = useAuth();
    const [newTemplate, setNewTemplate] = useState(false);
    const [updateData, setUpdateData] = useState(false);      
    const [selectedTemplate, setSelectedTemplate] = useState<any>();

    const frequencyByKey: any = {
        'WEEKLY': 'Semanal',
        'MONTHLY': 'Mensual',
        'QUATERLY': 'Trimestral',
        'YEARLY' : 'Anual'
    };

    const typeByKey: any = {
    'BASIC': 'Básico',
    'COMPLETE': 'Completo'    
    };

    const { data: reports } = useFetch(
        `${process.env.REACT_APP_API}/${groupAccount}/reports/config`,
        {},
        [updateData]
    );


    const handleDelete = async (templateId: string) => {
        await authFetch(
            `${process.env.REACT_APP_API}/templates/${templateId}`,
            { method: 'DELETE', data: templateId }
        ).then(() => { setUpdateData(!updateData); setSelectedTemplate(null) })

    };

    const columns = [       
        {
            title: 'Nombre Informe', 
            editable: true,
            render: (text: any, record: any) => (                
                <div>
                    <b>{record.name}</b>                
                    <div style={{color:'steelblue'}}>{frequencyByKey[record.frequency]}</div>                
                    <small style={{color:'grey'}}>{typeByKey[record.type]}</small>                
                </div>
            )
        },
        {
            title: 'Bloques',
            align: 'left',
            editable: true,
            render: (text: any, record: any) => (                
                <>
                    {record.conversion && <div><CheckCircleOutlined style={{color:'steelblue'}}/> <small>Conversión</small></div>}
                    {record.info && <div><CheckCircleOutlined style={{color:'steelblue'}}/> <small>Información</small></div>}
                    {record.reviews && <div><CheckCircleOutlined style={{color:'steelblue'}}/> <small>Reseñas</small></div>}
                    {record.content && <div><CheckCircleOutlined style={{color:'steelblue'}}/> <small>Publicaciones</small></div>}                    
                </>
            )
        },
        {
            align: 'center',
            render: (text: any, record: any) => (                
                <>
                <div style={{display:'flex', justifyContent:'space-between'}}>	                    
                    <Popconfirm title="Se borrarán todos los informes generados. Confirmar eliminación" onConfirm={() => handleDelete(record.id)}>
                        <DeleteOutlined style={{ color: 'dodgerblue' }} />
                    </Popconfirm>                           
				</div>
                </>
            )
        }
    ];
    
    const [selectedRowKey, setSelectedRowKey] = useState(null);

    const onSelectRow = (record: any ) => {
        setSelectedTemplate(record);
        const rowKey = record.name;
        setSelectedRowKey(rowKey);
    };

    const rowClassName = (record: { name: null; }) => {
        return record.name === selectedRowKey ? 'selected-row' : '';
    };

    return (
        <div>
            <Card className='main-card-content'>
                
                <h1 className='title-card'>Informes</h1>
                <div className="buttonWrapper" >                
                    <Button style={{ float: 'right'}} shape='round' type='primary' onClick={() => setNewTemplate(true)}>
                        <PlusCircleOutlined /> Informe periódico
                    </Button>						
				</div>
                {reports && 
                    <div style={{display:'flex', marginTop:'40px', height:'80vh'}}>
                        <div style={{ width:'40%', paddingRight:'10px' }}>
                            <TableLZM
                                size='small'
                                rowClassName={rowClassName}
                                onRow={(record: any) => ({
                                    onClick: () => onSelectRow(record),
                                    style: { cursor: 'pointer' },
                                })}
                                dataSource={reports}
                                columns={columns}               
                                style={{ color: 'grey'}}/>                
                        </div>
                        <div style={{ width:'60%', padding:'20px', borderRadius:'40px 0px 0px 0px', border:'1px solid lightgrey' }}>
                            {selectedTemplate ? 
                                <GeneratedReports templateId={selectedTemplate.id} title={selectedTemplate.name}/>
                                :
                                <div className="no-row-select" style={{background:'transparent'}}>
                                    <img src={reportsNotSelected} />
                                    <h1 >No tienes ningún informe seleccionado</h1>
                                    <span>Selecciona alguno</span>
                                </div>
                            }
                        </div>
                    </div>
                }
                {newTemplate && <AddModalTemplatesModal onFinish={() => {setNewTemplate(false); setUpdateData(!updateData)}}/>}                
            </Card>
        </div>
    )
}