import { EditOutlined } from '@ant-design/icons'
import { useState, useContext } from 'react'
import { useTranslation } from 'react-i18next';
import { ServiceItemsView } from './ServiceItemsView';
import { ServiceEdit } from './ServiceEdit';
import { useFetch } from '../../../../../../hooks/useFetch/useFetch';
import { getProjectStorage } from '../../../../../../store/storageContext';
import { useAuth } from '../../../../../../store/AuthContext/AuthContext';
import ServiceEditModal from './modal/ServiceEditModal';
import { GlobalVariableContext } from '../../../../../../store/GlobalVariableContext/GlobalVariableContext';


export const ServiceItems = (props: { serviceItems: any, locationsIds: string[] }) => {

    const [edit, setEdit] = useState(false);
    const [updateData, setUpdateData] = useState(false);
    const { t } = useTranslation();
    const { groupAccount } = useAuth();
    const [editVisible, setEditVisible] = useState(false);

    const {updateGlobalData } = useContext(GlobalVariableContext)

    const handleMouseEnter = () => {
        setEditVisible(true);
      };
    
      const handleMouseLeave = () => {
        setEditVisible(false);
      };

    
    //const {data, isLoading} = useFetch(`${process.env.REACT_APP_API}` + '/projects/' + getProjectStorage() + `/${groupAccount}` + `/${props.locationsIds}/servicesItems`, {}, [updateGlobalData]);

    return (
        <div  
        // onMouseEnter={handleMouseEnter}
        // onMouseLeave={handleMouseLeave}
        // style={{backgroundColor:editVisible ? 'var(--light-grey)' : ''}}
        >
           <span
            //  onClick={() => setEdit(true)} 
             style={{ textDecoration: "none", fontSize: "16px", color: "#3C4043", marginLeft:"1em", 
            //  cursor:'pointer'
              }}>
                {t('dictionary.services')}
                {/* {editVisible && <EditOutlined />} */}
            </span>
                           
            {!edit && <ServiceItemsView serviceItems={props.serviceItems}/>}
                
            {edit && 
            <ServiceEditModal locationsIds={props.locationsIds} onFinish={() => {
					  setEdit(false);
					  setUpdateData(!updateData);
                }}             
                
                />

                
            }

        </div>
    )
}