import { Collapse, Form, Input } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

const FormItemOffer = () => {

  const { t } = useTranslation();	

  return (		
	<>
		<Collapse ghost>						
			<Collapse.Panel key="1" style={{background:'#f5f9fb', margin:'15px 0px'}} header={<>
				<b>{t('view.forms.header.offerInfo')}</b>
				<small style={{ marginLeft: '10px', color: 'darkgrey' }}>
					{t('view.forms.header.fieldset')}
				</small>
			</>}>
				<Form.Item label={t('dictionary.voucherCode')} name='code'>
					<Input/>
				</Form.Item>							
				<Form.Item label={t('dictionary.redeemOnlineUrl')} name='redeemOnlineUrl' rules={[{ required: false, type: 'url' }]}>
					<Input/>
				</Form.Item>							
				<Form.Item label={t('dictionary.termsAndConditions')} name='termsAndConditions'>
					<Input.TextArea/>
				</Form.Item>
			</Collapse.Panel>
		</Collapse>
	</>							
	)
}

export default FormItemOffer;