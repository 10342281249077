
export const VERIFY_STATUS: any = {
    HAS_VOICE_OF_MERCHANT: "Verificado",		
    HAS_BUSINESS_AUTHORITY: "Autoridad de Negocio",
    NOT_REGISTERED: "No Verificado"		    
};

export const VERIFY_STATUS_COLOR: any = {
    HAS_VOICE_OF_MERCHANT: "processing",		
    HAS_BUSINESS_AUTHORITY: "warning",
    NOT_REGISTERED: "default"		    
};
