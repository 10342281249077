
import { AppstoreAddOutlined, DoubleLeftOutlined, DoubleRightOutlined, UsergroupAddOutlined } from '@ant-design/icons';
import { Layout as AntdLayout, Menu } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../../store/AuthContext/AuthContext';
import { AdminRoutes } from '../../../routing/AdminRoutes';

const { Sider, Content } = AntdLayout;
const grey = '#bab6b6';
const black = "#051235";

export const AdminLayout = () => {

	const location = useLocation();
  	const pathName = location.pathname;
	const { collapsed, setCollapsed } = useAuth();
	const { t } = useTranslation();		

	return (
		<AntdLayout>			
			<Sider width={140} className='container-space' collapsed={collapsed} collapsible={true} trigger={null}>	
				<aside>
					<nav>
						<ul>
							<Link to="/admin/usuarios">
								<li className={`${(pathName == '/admin/usuarios') ? 'item-selected' : 'item-no-selected' }${` item-space`}`}>
									<div>
										<UsergroupAddOutlined style={{color: (pathName == '/admin/usuarios') ? black : grey }} className='icon-menu-item'/>
										<span className={`${(pathName == '/admin/usuarios') ? 'selected' : 'no-selected' }`}>Business</span>
									</div>
								</li>
							</Link>
							<Link to="/admin/app">
								<li className={`${(pathName == '/admin/app') ? 'item-selected' : 'item-no-selected' }${` item-space`}`}>
									<div>
										<UsergroupAddOutlined style={{color: (pathName == '/admin/app') ? black : grey }} className='icon-menu-item'/>
										<span className={`${(pathName == '/admin/app') ? 'selected' : 'no-selected' }`}>Pocket</span>
									</div>
								</li>
							</Link>
							<Link to="/admin/proyectos">
								<li className={`${(pathName == '/admin/proyectos') ? 'item-selected' : 'item-no-selected' }${` item-space`}`}>
									<div>
										<AppstoreAddOutlined style={{color: (pathName == '/admin/proyectos') ? black : grey }} className='icon-menu-item'/>
										<span className={`${(pathName == '/admin/proyectos') ? 'selected' : 'no-selected' }`}>Proyectos</span>
									</div>
								</li>
							</Link>
						</ul>
					</nav>
				</aside>
			</Sider>
			
			<Content className='container-space'>
				<AdminRoutes />				
			</Content>
			
		</AntdLayout>
	)
};
