import { Alert, Layout, Result } from 'antd';
import { useTranslation } from 'react-i18next';
import { Content } from 'antd/lib/layout/layout';

export const NonResponsive = () => {
    const { t } = useTranslation();

    return (
        <Layout style={{ minHeight: '100vh', background: 'white' }}>
        <Content style={{
				width: '100vw',
                display:'flex',
                flexDirection:'column',
                justifyContent:'center',				
                alignItems:'center'				
			}}>
        <Result status="warning" title={t('view.errors.nonResponsive')} extra={ <Alert message={t('view.errors.nonResponsiveWidth')} /> } />
            </Content>
        </Layout>
    )
}
