import { useContext, useState } from "react";
import { DrawerContext } from "../../../../store/DrawerContext/DrawerContext";
import { Card, Divider, Drawer, Image, Modal, Tag } from "antd";
import { getProjectStorage } from "../../../../store/storageContext";
import { useFetch } from "../../../../hooks/useFetch/useFetch";
import { defaultFormat } from "../../../../common/utils/formatDate";
import { POSTTYPES } from "../../../../common/constants/postTypes";
import { EnvironmentOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { authFetch } from "../../../../common/request/authFetch";
import { useAuth } from "../../../../store/AuthContext/AuthContext";

export const PostsDrawer = () => {

    const { postsDrawer, setPostsDrawer } = useContext(DrawerContext);
    const { groupAccount } = useAuth();

    const [updateData, setUpdateData] = useState(false);

    const { data, isLoading }: any = useFetch(
        `${process.env.REACT_APP_API}/projects/${getProjectStorage()}/${postsDrawer}/postsByLocal`,
        {},
        [updateData],
    );
    const { t } = useTranslation();

   
    const deletePost = async(value: any) => {
        await authFetch(`${process.env.REACT_APP_API}/projects/${getProjectStorage()}/${groupAccount}/locations?locationsIds=${value.name}` , { method: 'DELETE'}).then(()=>{
            setUpdateData(!updateData)
        })
      }
    return (
        <>
            {postsDrawer && (
                <Drawer
                    placement="right"
                    width={'45%'}
                    visible={postsDrawer != null}
                    onClose={() => setPostsDrawer(null)}
                    bodyStyle={{ background: '#F4F7FA' }}
                    title={<div style={{ display: 'flex', gap: '15px' }}><EnvironmentOutlined /> <h4>{data[0]?.addressLine}</h4></div>}
                >

                    <h3 style={{ margin: '0px 10px' }}>{t('dictionary.posts')}</h3>

                    {data.map((p: any) => <div style={{ margin: '20px 25px' }}>
                        <div style={{ fontSize: '16px', display: 'block' }}>
                            {
                                p?.mediaItemsURL?.map((googleUrl: string) => {
                                    return (<Image width={'100%'} src={googleUrl} preview={false} style={{ borderRadius: '20px 20px 0px 0px', display: 'inline' }} />)
                                })
                            }
                        </div>
                        <div style={{ padding: '15px', textAlign: 'justify', background: 'white', color: 'rgba(0, 0, 0, 0.5)', border: '1px solid rgba(0, 0, 0, 0.1)' }}>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '10px' }}>
                                <b>{defaultFormat(p?.createTime)}</b>
                                <Tag style={{ borderRadius: '20px' }} color={'blue'}>{POSTTYPES[p?.topicType]}</Tag>
                            </div>
                            <p>{p?.summary}</p>

                            <div style={{display:'flex', justifyContent:'flex-end', margin:'0px 10px'}}>
                            <a

                                onClick={
                                    () => {
                                        Modal.confirm({
                                            title: t('locals.drawer.tabs.photo.delete.title'),
                                            okText: t('dictionary.confirm'),
                                            cancelText: t('dictionary.cancel'),
                                            onOk: () => deletePost(p),
                                        });
                                    }
                                }>
                                {t('dictionary.delete')}
                            </a>
                            </div>
                        </div>

                        <Divider />
                    </div>)}
                </Drawer>
            )}
        </>
    );
};
