import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Alert, Badge, Button, Card, Form, Input, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import logoLocalizameBackground from '../../../assets/logo-localizame-background.png';
import planeBackground from '../../../assets/plane.jpg';
import { authFetch } from '../../../common/request/authFetch';

const RecoverPassword = () => {

	const [formError, setFormError] = useState(false);
	const [form] = Form.useForm();
	const location = useLocation();
	const history = useHistory();
	const pathName = location.pathname.split('/');
	const email = pathName[pathName.length - 1];
	const verifyToken = new URLSearchParams(location.search).get('verificationToken');
	const { t } = useTranslation();

	useEffect(() => {
		form.setFieldsValue({ email: email });
	}, [])

	const onFinishReset = async (values: any) => {

		const data = {
			...values,
			email,
			verificationToken: verifyToken
		};

		await authFetch(`${process.env.REACT_APP_API}/users/recover?verificationToken=${verifyToken}`, { method: 'PUT', data })
			.then((isOk) => {
				if (isOk) {
					message.success(t('view.forms.modal.password.success'));
					history.push('/', { from: location });
				} else {
					message.error(t('view.forms.modal.password.error'));
				}
			}
			);
	}

	return (
		<div style={{ backgroundImage: `url(${planeBackground})`, backgroundSize: '80%', backgroundRepeat: 'no-repeat', backgroundPosition: 'right top', width: '100%', height: '80vh', display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
			<div style={{ backgroundImage: `url(${logoLocalizameBackground})`, backgroundSize: '45%', backgroundRepeat: 'no-repeat', backgroundPosition: 'right top', width: '100%', height: '80vh', display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
				<Badge.Ribbon placement='end' text={t('view.forms.fields.password.title')} color="#3498DB">

					<Card bordered={true} style={{ border: '2px solid #d9e9f7', boxShadow: '4px 7px #f5f9fb', borderRadius: '10px', padding: '40px', width: '45vw', alignContent: 'right', marginLeft: '10vw', background: '#f5f9fb' }}>
						<Form layout='vertical' form={form} name='basic' onFinish={onFinishReset} autoComplete='off'>
							<Form.Item name='email' label={t('dictionary.user')}>
								<Input disabled={true} prefix={<UserOutlined />} />
							</Form.Item>
							<Form.Item label={t('dictionary.password')} name='password'

								rules={[
									{
										required: true,
										message: t('view.forms.fields.password.required') as string,
									},
									{
										min: 8,
										message: t('view.forms.fields.password.characters') as string,
									},
									{
										pattern: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d!@#$%^&*()_+={}\[\]:;"'<>,.?/~`|\\-]*$/,
										message:  t('view.forms.fields.password.letters') as string,
									},
									{
										pattern: /^(?=.*[a-z])(?=.*[A-Z])/,
										message:  t('view.forms.fields.password.upperCase') as string,
									},
								]}

								// rules={[{ required: true, message: t('view.forms.fields.password.required') as string }]}
								
								>
								<Input.Password placeholder={t('view.forms.fields.password.placeholder') as string} prefix={<LockOutlined />} />
							</Form.Item>
							<Form.Item label={t('view.forms.fields.password.confirm')} name='password2' validateTrigger='onBlur' rules={[
								{
									required: true,
									message: t('view.forms.fields.password.new') as string
								},
								({ getFieldValue }) => ({
									validator(_, value) {
										if (!value || getFieldValue('password') === value) {
											return Promise.resolve();
										}
										return Promise.reject(new Error(t('view.forms.fields.password.mismatch') as string));
									},
								}),
							]}>
								<Input.Password placeholder={t('view.forms.fields.password.repeat') as string} prefix={<LockOutlined />} />
							</Form.Item>
							<Button shape='round' style={{ width: '100%' }} type="primary" htmlType="submit">
								{t('dictionary.change')}
							</Button>
							<div></div>
						</Form>
						{
							formError && <Alert style={{ marginTop: '12px' }} type='error' message={t('view.forms.modal.login.error')} />
						}
					</Card>
				</Badge.Ribbon>
			</div>
		</div>
	);
}

export default RecoverPassword;