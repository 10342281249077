import moment from "moment";

const MINUTES_IN_HOUR = 60;

export const defaultFormat = (localDateTime: string) => {
    const date = moment(localDateTime);
    const formattedDate  = date.format("lll");
    return formattedDate
}

export const defaultFormatWithOutHours = (localDateTime: string) => {
    const date = moment(localDateTime);
    const formattedDate  = date.format("lll");
    return formattedDate
}

export function formatDaysAgo(numMin: number) {

  if (numMin >= MINUTES_IN_HOUR) {
      const numHours = Math.floor(numMin / MINUTES_IN_HOUR);
      return `${numHours} horas`;
    } else {
      return `${numMin} minutos`;
    }
  }