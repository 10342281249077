import { Card, Collapse, Tabs, Typography, Modal, Tag } from "antd"
import { ButtonLZ } from "../../../components/design/Buttons/ButtonLZ"
import { useState } from "react";
import { useFetch } from "../../../../hooks/useFetch/useFetch";
import { useAuth } from "../../../../store/AuthContext/AuthContext";
import { useTranslation } from "react-i18next";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { authFetch } from "../../../../common/request/authFetch";
import { AddClientWebModal } from "./modal/AddClientWebModal";

const { Paragraph } = Typography;

export const WidgetWebs = () => {

    const [addWeb, setAddWeb] = useState(false);
    const [updateData, setUpdateData] = useState(false);
    const { groupAccount } = useAuth();
    const { t } = useTranslation();

    const { data, isLoading }: any = useFetch(
        `${process.env.REACT_APP_API}/client/web/${groupAccount}`,
        {},
        [updateData],
    );

    const sites = `${process.env.SITES_LOCALIZA_ME}`;

    const deleteWeb = async () => {
        await authFetch(`${process.env.REACT_APP_API}/client/web/${groupAccount}`,{ method: 'DELETE' }).then(()=>{
            setUpdateData(!updateData)
        })
    }

    return (
        <Card className='main-card-content'>
            <h1 className='title-card'>Widgets para Web <Tag color='purple'>MÓDULO EXTRA: WEB</Tag></h1>
            
            <br/>
            
            {data ?
                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: '5px' }}>                    
                    <Tag color='blue' style={{borderRadius:'8px'}}><b>URL:</b> {data.url}</Tag>
                    <Tag color='blue' style={{borderRadius:'8px'}}><b>Clave API:</b> {data.id}</Tag>
                    <EditOutlined onClick={() => { setAddWeb(true) }} />
                    <DeleteOutlined 
                     onClick={
                        () => {
                            Modal.confirm({
                                title: '¿Eliminar esta web?',
                                okText: t('dictionary.confirm'),
                                cancelText: t('dictionary.cancel'),
                                onOk: () => deleteWeb(),
                            });
                        }
                    } />
                </div> 
                :
                <>
                    <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                        <ButtonLZ size="small" onClick={() => { setAddWeb(true) }}>Añadir Clave API</ButtonLZ>
                    </div>
                </>
            }

            {!!data &&
                <div style={{ width: '80%' }}>

                <Tabs size='large' style={{ marginTop: 20 }} defaultActiveKey="1" >

                    <Tabs.TabPane
                        tab={<h3>Reseñas</h3>}
                        key="1">
                        <h3>Previsualización:</h3>

                        <div style={{ border: '3px dashed lightgrey', padding: '10px', marginBottom: '10px' }}>
                            <iframe
                                style={{ height: '280px', width: '100%' }}
                                src={`${process.env.REACT_APP_SITES}/widgets/reviews/${data.id}`}
                                title="Widget Reviews">
                            </iframe>
                        </div>

                        <Collapse>
                            <Collapse.Panel key="1" header={'Código JavaScript a insertar en tu web'}>
                                <div>
                                    <code>
                                        {'/* Importar este script en el archivo index.html */'}
                                        <br />
                                        <Paragraph copyable>{`<script src="${process.env.REACT_APP_SITES}/widgets-reviews.js"></script>`}</Paragraph>
                                        <br />
                                        <br />
                                        {'/* Añadir este tag en la parte del body donde quieras mostrar las reseñas */'}
                                        <br />
                                        <Paragraph copyable>{`<reviews-widget id='${data.id}'></reviews-widget>`}</Paragraph>
                                    </code>
                                </div>
                            </Collapse.Panel>

                        </Collapse>
                    </Tabs.TabPane>
                </Tabs>
            </div>
            }
            {addWeb &&
                <AddClientWebModal
                    initialValue={data?.url}
                    visible={addWeb}
                    onFinish={() => { setAddWeb(false); setUpdateData(!updateData) }}
                />}
        </Card>
    )
}