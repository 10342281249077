interface iconsGoogleInsterface{
  style: string;
  name: string
}

export const IconsGoogle = ({ style, name}: iconsGoogleInsterface) => {
  return (
    <span className={style}>{name}</span>
  )
}
