import React, { useEffect, useState } from 'react';
import { Modal, Form, Input, Select, message, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import { authFetch } from '../../../../../common/request/authFetch';
import { authRequest } from '../../../../../common/request/authRequest';


const UsersAssignProjectsModal = ({
	selectedUser,
	visible,
	onFinish,
}: any) => {

	const [form] = Form.useForm();
	const [projects, setProjects] = useState();
	const { t } = useTranslation();
	const [isLoading, setIsLoading] = useState(false);
	
	useEffect(() => {
		setIsLoading(true);
	
		authRequest(`${process.env.REACT_APP_API}/projects/projectNames`, {})
		.then((response) => {		
			setProjects(response.map);
		})
		.catch(() => {message.error(t('view.errors.loadProjects'))});
		},[t]);
	
	const onFormFinish = async (values: any) => {
		const data = {
			...values,
			roles: [values.roles],
			status: 'CREATED'
		};

		await authFetch(`${process.env.REACT_APP_API}/users/` + selectedUser.email + '/projects', { method: 'POST', data: data });
		onFinish();
	};

  return (
		<Modal visible={visible} onOk={() => form.submit()} onCancel={onFinish} afterClose={form.resetFields}>
			<h2>{t('dictionary.assignNewProject')}</h2>
			<table style={{marginLeft:'10%', border:'false', tableLayout:'fixed', width:'80%'}}>
				<tr>
					<td style={{width:'25%'}}>
						<b>{t('dictionary.user')}</b>
					</td>
					<td>
						{selectedUser.email}
					</td>				
				</tr>
				<tr>
					<td>
						<b>{t('dictionary.customers')}</b>
					</td>
					<td>
					{				
						selectedUser.projects && selectedUser.projects.map((val: string) => {
							return (<Tag key={val} color='blue'>{val}</Tag>)
						})
					}
					</td>				
				</tr>
			</table>
			
			<br/>
		
			<Form layout='vertical' form={form} onFinish={onFormFinish}>
				<Form.Item label={t('dictionary.project')} name='project' rules={[{ required: true }]}>
				<Select>
					{
						projects && Object.keys(projects).map(project => {
							return (
							<Select.Option key={project} value={projects[project]}>
								{
									project
								}
							</Select.Option>)
						})
					}
				</Select>
				</Form.Item>
			</Form>
		</Modal>
	)
}

export default UsersAssignProjectsModal;