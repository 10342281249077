import React from 'react';
import { Form, Input} from 'antd';
import { useTranslation } from 'react-i18next';

const FormItemTitle = () => {		

  const { t } = useTranslation();	

  return (		
		<Form.Item
			label={t('view.forms.fields.title.label')}
			name='title'
			rules={[{ required: true, message: t('view.forms.fields.title.required') as string }]}
		>
			<Input placeholder={t('view.forms.fields.title.placeholder') as string}/>
		</Form.Item>							
	)
}

export default FormItemTitle;