import { Button, Card, Col, Divider, Input, Progress, Rate, Row, Tag } from "antd";
import { Column, RingProgress } from "@ant-design/charts";
import { DoubleRightOutlined, ForwardOutlined, LineChartOutlined, SearchOutlined, ShopOutlined, StarFilled } from "@ant-design/icons";
import MathUtils from "../../../../common/utils/MathUtils";
import { useTranslation } from "react-i18next";
import { getColor } from "../../../../common/utils/ColorRate";
import moment from "moment";
import { FilterDropdownProps } from "antd/lib/table/interface";
import { Location } from "../../../components/model/business/FieldsGroup";
import { SpinLZ } from "../../../components/design/Spinner/SpinLZ";
import TableLZM from "../../../components/design/table/TableLZM";
import { Increment } from "../../../../common/utils/Increment";
import { ColorStar, ColorStarExtended } from "../../../../common/utils/ColorStar";
import { useContext } from "react";
import { DrawerContext } from "../../../../store/DrawerContext/DrawerContext";

export const BlockReviewsByLocation = (props: {data: any, showButtons: boolean}) => {

	const { locationStatisticsDrawer, setLocationStatisticsDrawer} = useContext(DrawerContext)

    const columns: any = [	
        {
			title: 'Puntuación',
			key: 'rateAvgNew',			
			align: 'center',
			sorter: (a: any, b: any) => b.reviewsKPIs?.rateAvgNew - a.reviewsKPIs?.rateAvgNew,
			render: (text: string, record: any) => (
                <><div style={{ display: 'flex', justifyContent: 'center' }}>
                    <ColorStar
                        style={{ fontSize: '18px' }}
                        value={record.reviewsKPIs?.rateAvgNew}/>								
                </div>
				<small>{record.reviewsKPIs?.numberOfReviews} reseñas</small>
				</>)			
		},
		{
			title: 'Establecimiento',
			key: 'location',									
			sorter: (a: any, b: any) => a.locality?.localeCompare(b.locality),
			filterDropdown: ({
			  setSelectedKeys,
			  selectedKeys,
			  confirm,			  
			}: FilterDropdownProps) => {
			  return (
				<Input
				  autoFocus
				  //placeholder="Filtro"
				  value={selectedKeys[0]}
				  onChange={(e: any) => {
					setSelectedKeys(e.target.value ? [e.target.value] : []);
					confirm({ closeDropdown: false });
				  }}
				  onPressEnter={() => {
					confirm();
				  }}
				  onBlur={() => {
					confirm();
				  }}
				></Input>
			  );
			},
			filterIcon: () => {
			  return <SearchOutlined />;
			},
			onFilter: (value: any, record: any) => {
				return record.title?.toLowerCase().includes(value?.toLowerCase()) 
				|| record.administrativeArea?.toLowerCase().includes(value?.toLowerCase())
				|| record.locality?.toLowerCase().includes(value?.toLowerCase());
			},
			render: (text: string, record: any) => (
				<Location record={record} drawer={false}/>									
			)
		},	
        {
			title: 'Evolución',
			key: 'rateAvgOld',			
			align: 'center',
			sorter: (a: any, b: any) => b.reviewsKPIs?.rateAvgOld - a.reviewsKPIs?.rateAvgOld,
			render: (text: string, record: any) => (
                <>
				<div style={{ display: 'flex', justifyContent: 'center' }}>
					{record.reviewsKPIs?.rateAvgIncrement && (<><ColorStarExtended
                        style={{ fontSize: '18px' }}
                        value={record.reviewsKPIs?.rateAvgOld}/>		
						<ForwardOutlined style={{ fontSize: '18px', margin:'0px 10px' }}	/>
						</>)
					}
					<ColorStarExtended
                        style={{ fontSize: '18px' }}
                        value={record.reviewsKPIs?.rateAvgNew}/>						
                </div>
				{record.reviewsKPIs?.rateAvgIncrement && 
					<>
						<Increment value={record.reviewsKPIs.rateAvgIncrement} numberOfDecimals={4}/><small style={{color:'grey'}}>en 30 días</small>
					</>
				}				
				</>)			
		},				
		{
			title: 'Clientes',
			key: 'reviewsPerMonth',
			align: 'center',			
			render: (text: string, record: any) => 
			<>
				<div><b>{record.reviewsKPIs?.reviewsPerMonth?.toFixed(1)} reseñas/mes</b></div>
                <small>{'+'}{record.reviewsKPIs?.numberOfRecentReviews} en 30 días</small>					
			</>			
		},		        
		{
			title: '%Contestadas',
			key: 'repliedRatio',
			dataIndex: 'repliedRatio',
			align: 'center',
			render: (text: string, record: any) => (
				<Progress width={50} type="circle" strokeColor='green' percent={Math.round(100 * record.reviewsKPIs?.repliedRatio)} />	
			)
		},
		{
			title: '',
			key: 'buttons',			
			align: 'center',
			render: (text: string, record: any) => (
				props.showButtons && 
				<div style={{display:'flex', justifyContent:'space-between'}}>	
					<Button onClick={()=>{setLocationStatisticsDrawer(record.locationId)}} size='small' style={{ borderRadius: '20px', background:'var(--primary)',  border:`solid 1.3px RoyalBlue`, color: "white" }}>    
						<LineChartOutlined style={{ color: 'white' }}/> 								
					</Button>
				</div>
			)
		}		
	]

    return (        
        <TableLZM 
            rowKey='location'
            scroll={{ x: true }}						
            columns={columns}
            dataSource={props.data}
            loading={false}/>
    )
}