import { Button } from 'antd'
import { ButtonLZInterface } from '../../../../interfaces/ButtonLZInterface'
import './buttonLZ.sass'

export const ButtonLZ = ({ref, color, onClick, htmlType, type = 'primary', icon, size, disabled, href, target, shape ,children, style}: ButtonLZInterface) => {
  return (
    <Button 
        ref={ref}
        className={!disabled ? color : ''}
        shape={(shape) ? shape : 'round'}
        onClick={onClick}
        htmlType={htmlType}
        type={type}
        size={size}
        icon={icon}
        disabled={disabled}
        href={href}
        target={target}
        style={style}
    >
      {children}
    </Button>
  )
}
