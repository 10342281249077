
export const WebView = ({url}:any) => {
  return (
    <div className="url-box">
        <div style={{margin: "1em 0 0 0", display:"flex",justifyContent:"flex-start"}}>
            <span style={{ color: "#666" }}>
                {url}
            </span>
        </div>
</div>
  )
}
