import { useState } from 'react'
import { EditOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next';
import { DescriptionView } from './DescriptionView';
import { DescriptionEdit } from './DescriptionEdit';
import { useIsAuthorized } from '../../../../../pages/common/roles/Roles';

export const Description = (props: { descriptionStatus: string, locationsIds: string[] }) => {
    const [edit, setEdit] = useState(false);
    const [editVisible, setEditVisible] = useState(false);
    const isAuthorized = useIsAuthorized();

    const [currentDescription, setCurrentDescription] = useState(props.descriptionStatus);
    const { t } = useTranslation();

    const handleMouseEnter = () => {
        setEditVisible(true);
      };
    
    const handleMouseLeave = () => {
        setEditVisible(false);
    };

    return (
        <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} style={{backgroundColor: editVisible ? 'var(--light-grey)' : ''}}>
            
            <span style={{ textDecoration: "none", fontSize: "16px", color: "#3C4043", marginLeft: "1em", cursor:'pointer' }} onClick={() => setEdit(true)}>
                {t('dictionary.description')}
                {editVisible && isAuthorized(['ROLE_ADMIN', 'ROLE_AGENT', 'ROLE_CLIENT', 'ROLE_GUEST_INFO_EDIT']) && <EditOutlined />}
            </span>
            {
                edit ? 
                    <DescriptionEdit 
                        isMassive={false} 
                        currentDescription={currentDescription} 
                        locationsIds={props.locationsIds} 
                        setEdit={setEdit} 
                        edit={edit} 
                        setCurrentDescription={setCurrentDescription} 
                        />
                    : 
                    <DescriptionView value={currentDescription!} />                    
            }
        </div>
    )
}
