import React, { useState } from 'react';
import Questions from './Questions';
import { useAuth } from '../../../../../store/AuthContext/AuthContext';
import { useFetch } from '../../../../../hooks/useFetch/useFetch';
import { getProjectStorage } from '../../../../../store/storageContext';
import QuestionDetails from '../../../../pages/menuReviews/3_1_Reviews/Questions/QuestionDetails';


export default function QuestionsContainer() {

    const [selectedQuestion, setSelectedQuestion] = useState<any>(null);
    const { groupAccount } = useAuth();
    const [questionsData, setQuestionsData] = useState([]);
    const [refreshQuestions, setRefreshQuestions] = useState(false);

    const handleQuestionClick = (question: React.SetStateAction<null>) => {
        setSelectedQuestion(question);
    };
    
    useFetch(`${process.env.REACT_APP_API}/projects/` + getProjectStorage() + '/' + groupAccount + '/questions', {}, [refreshQuestions], (data) => {
        setQuestionsData(data.questions);
    });

    return (
        <>
            
            <div style={{ position: 'relative', marginTop: '50px' }}>
                <div>
                    <Questions
                        questionsData={questionsData}
                        handleQuestionClick={handleQuestionClick} />
                </div>
                <div>
                    <QuestionDetails 
                        questionId={selectedQuestion ? selectedQuestion.name : null} 
                        onChange={() => {
                            setRefreshQuestions(!refreshQuestions);                   
                        }}/>
                </div>
            </div>
           
        </>
    );
}