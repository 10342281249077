import { useTranslation } from 'react-i18next';

export const Period = (props: {data: any}) => {  

  //const { t } = useTranslation();
 
  let openTimeMinuteString= props.data.openTimeMinute.toString();
  let closeTimeMinuteString= props.data.closeTimeMinute.toString();
 
   return (
     <div>
         <p style={{color:'SeaGreen'}}>
           {props.data.openTimeHour}:{openTimeMinuteString.length > 1 ? props.data.openTimeMinute : "00" } - {props.data.closeTimeHour}:{closeTimeMinuteString.length > 1 ? props.data.closeTimeMinute : "00" } 
         </p>       
     </div>
   )
 }
