import { CheckCircleFilled, ExclamationCircleFilled, WarningFilled } from "@ant-design/icons";
import { ActionAlert } from "./ActionAlert";

export const AlertsView = (props : {alerts: any[]}) => {

  return (
    <>      
        {
          props.alerts.map((alert: any) => (
            <ActionAlert 
              description={alert.description}
              severity={alert.severity} />            
          ))
        }       
    </>    
  )
};