import { Alert, Form, Input, Modal, Tabs } from "antd";
import { useState } from "react";
import { customRequest } from "../../../common/request/customRequest";
import { STATUS_CODE } from "../../../common/constants/statusCode";
import { ButtonLZ } from "../../components/design/Buttons/ButtonLZ";

export const UploadWebsModal = (props: { visible: boolean, onFinish: any }) => {

	const [form] = Form.useForm();

	const [file, setFile] = useState<any>(null);
	const [errorMessage, setErrorMessage] = useState<any>(null);
	const [selectedTab, setSelectedTab] = useState<string>('1'); 


	const onFormFinish = async (values: any) => {

		const config = {
			headers: {
				'content-type': 'multipart/form-data'
			}
		}

		let form_data = new FormData();
		form_data.append('file', file);

		const endpoint = selectedTab == '1' ? 'upload' : 'uploadText';

		const response = await customRequest(`${process.env.REACT_APP_API}/externalWebsites/${endpoint}`, { ...config, method: 'POST', accept: 'text/csv', data: form_data }, 'Archivo subidas con éxito')
		if (response?.statusCode === STATUS_CODE.BAD_REQUEST) {
			setErrorMessage(response.message)
		} else {
			props.onFinish();
		}
	}

	const onChange = (e: any) => {
		setFile(e.target.files[0])
	}

	const changeKey = (e: any) => {
		setSelectedTab(e)
	}


	return (

		<Modal visible={props.visible} onOk={() => form.submit()} onCancel={props.onFinish} afterClose={form.resetFields}
			footer={[
				<div style={{ padding: '10px' }}>
					<ButtonLZ key="back" color='dark-white' onClick={props.onFinish}>
						Cancelar
					</ButtonLZ>
					<ButtonLZ key="submit" onClick={() => form.submit()}>
						Guardar
					</ButtonLZ>
				</div>
			]}>
			<h2>Subir webs</h2>


			<Tabs defaultActiveKey="1" onChange={changeKey}>
				<Tabs.TabPane tab={<span>Url</span>} key='1' />
				<Tabs.TabPane tab={<span>Texto</span>} key='2' />
			</Tabs>


			<Form layout='vertical' form={form} onFinish={onFormFinish} >
				<Form.Item name='reportFile' rules={[{ required: true }]}>
					<Input type={'file'} accept={'.csv'} onChange={(event) => { onChange(event) }} onClick={(event) => { onChange(null) }}>
					</Input>
				</Form.Item>
			</Form>

			{/* <a href="https://silvergold-s3-plantillas.s3.eu-west-1.amazonaws.com/Plantilla_Creacion_Productos.csv">Plantilla productos</a> */}

			{errorMessage && <Alert
				type='error'
				message={errorMessage}
				banner
			/>
			}
		</Modal>
	)
}