import { Button, Image } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import logo from '../../../../assets/logo-localizame.png';

const Logo = ({ style }: any) => {

  const history = useHistory();
  const location = useLocation();
  
  return (
    <div style={{height:'100%'}}>
      <Button  type="link" onClick={() => history.push('/home', { from: location })}>
        <Image preview={false} style={style} src={logo} />
      </Button>
    </div>
  )
}

export default Logo;