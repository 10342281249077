import { CheckCircleTwoTone, ClockCircleTwoTone, SearchOutlined } from '@ant-design/icons';
import { Input, Rate, Tag } from 'antd';
import { useTranslation } from 'react-i18next';

import { FilterDropdownProps } from 'antd/lib/table/interface';
import TableLZM from '../../../design/table/TableLZM';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { ColorStar } from '../../../../../common/utils/ColorStar';


const Reviews = ({ reviewsData, handleReviewClick, width = '56%' }: any) => {

    const { t } = useTranslation();

    const [filteredRows, setFilteredRows] = useState(reviewsData?.length);

    useEffect(()=>{ 
        setFilteredRows(reviewsData?.length)

    }, [reviewsData])

    const columns: any = [

        {
            title: '',
            key: 'reviewReplyComment',
            dataIndex: 'reviewReplyComment',
            align: 'center',
            width: '5%',
            render: (text: string, record: any) => (
                <>
                    {record.reviewReplyComment ? <CheckCircleTwoTone style={{ fontSize: '18px' }} twoToneColor='#52c41a' />
                        :
                        <ClockCircleTwoTone style={{ fontSize: '18px' }} twoToneColor='#F0B27A' />
                    }
                </>
            ),
            filters: [
                { text: 'Contestadas', value: 'ANSWERED' },
                { text: 'No contestadas', value: 'NOT_ANSWERED' },
            ],
            //defaultFilteredValue : ['NOT_ANSWERED'], 
            onFilter: (value: string, record: any) => {
                if (value === 'ANSWERED') {
                    return !!record.reviewReplyComment;
                } else if (value === 'NOT_ANSWERED') {
                    return !record.reviewReplyComment;
                }
                return false;
            },
        },
        {
            title: 'Reseñas',
            key: 'text',
            dataIndex: 'text',
            width: '45%',
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
            }: FilterDropdownProps) => {
                return (
                    <Input
                        autoFocus
                        placeholder="Nombre o Texto"
                        value={selectedKeys[0]}
                        onChange={(e: any) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    ></Input>
                );
            },
            filterIcon: () => {
                return <SearchOutlined />;
            },
            onFilter: (value: string, record: any) => {
                const searchWords = value.toLowerCase().split(' ');
                const searchText = record.comment?.originalText?.toLowerCase();
                return searchWords.every((word) => searchText && searchText.includes(word)) || record.reviewerDisplayName.toLowerCase().includes(value.toLowerCase());
            },
            sorter: (a: any, b: any) => moment(b.createdTime) > moment(a.createdTime),
            render: (text: string, record: any) => {
                const maxLength = 100;
                const truncatedText = !!record.comment.originalText && record.comment.originalText.length > maxLength ? record.comment.originalText.slice(0, maxLength) + '...' : record.comment.originalText;

                return (
                    <>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '10px', marginBottom: '10px' }}>
                            <img src={record.reviewerProfilePhotoUrl} style={{ width: '30px' }} />
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                                <div>{record.reviewerDisplayName}</div>
                                <div style={{ fontSize: '10px', color: 'gray' }}>
                                    {moment(record.createdTime).format('DD MMM YYYY')}
                                </div>
                            </div>
                        </div>
                        <p style={{ color: 'var(--grey)', fontSize: 12 }}>{truncatedText}</p>
                    </>
                );
            }
        },
        {
            title: 'Puntuación',
            key: 'starRating',
            dataIndex: 'starRating',
            align: 'center',
            filters: [
                { text: <Rate value={5} style={{ fontSize: '16px' }} />, value: 5 },
                { text: <Rate value={4} style={{ fontSize: '16px' }} />, value: 4 },
                { text: <Rate value={3} style={{ fontSize: '16px' }} />, value: 3 },
                { text: <Rate value={2} style={{ fontSize: '16px' }} />, value: 2 },
                { text: <Rate value={1} style={{ fontSize: '16px' }} />, value: 1 },
            ],
            onFilter: (value: string, record: any) => record.starRating === value,
            render: (text: string, record: any) => (record.starRating &&
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <ColorStar
                        style={{ fontSize: '18px' }}
                        value={Math.round(10 * record.starRating) / 10}/>
                </div>)
        },
        {
            title: 'Dirección',
            key: 'locality',
            dataIndex: 'locality',
            align: 'center',
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
            }: FilterDropdownProps) => {
                return (
                    <Input
                        autoFocus
                        placeholder="Provincia, Ciudad, CP o Dirección"
                        value={selectedKeys[0]}
                        onChange={(e: any) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    ></Input>
                );
            },
            filterIcon: () => {
                return <SearchOutlined />;
            },
            onFilter: (value: any, record: any) => {
                const locality = record.locality ? record.locality.toLowerCase() : '';
                const addressLine = record.addressLine ? record.addressLine.toLowerCase() : '';
                const postalCode = record.postalCode ? record.postalCode.toLowerCase() : '';

                return locality.includes(value.toLowerCase()) ||
                    addressLine.includes(value.toLowerCase()) ||
                    postalCode.includes(value.toLowerCase());
            },
            render: (text: string, record: any) => (
                <div>
                    {record.title}
                    {record.addressLine ? <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {<span>{record.locality}</span>}
                        {<small>{record.addressLine}</small>}</div>
                        : <p style={{ color: 'grey', fontStyle: 'italic' }}>Sin ubicación</p>
                    }

                </div>
            )

        },
    ]

    const [selectedRowKey, setSelectedRowKey] = useState(null);

    const onSelectRow = (record: { name: any; }) => {
        handleReviewClick(record)
        const rowKey = record.name;
        setSelectedRowKey(rowKey);
    };

    const rowClassName = (record: { name: null; }) => {
        return record.name === selectedRowKey ? 'selected-row' : '';
    };

    return (

        <div style={{ width: width }}>
            {/*<div style={{ color: 'grey', font: '26px' }}>{`${filteredRows} de ${reviewsData?.length}`}</div>*/}

            <TableLZM size='small'
                rowClassName={rowClassName}
                onRow={(record: { name: any; }) => ({
                    onClick: () => onSelectRow(record),
                    style: { cursor: 'pointer' },
                })} columns={columns} dataSource={reviewsData}
                rowKey="name"
                scroll={{ y: 400 }}
                /*onChange={(pagination: any, filters: any, sorter: any, extra: any) => {
                    setFilteredRows(extra?.currentDataSource?.length);
                    if(filters.text == null && filters.locality == null && filters.reviewReplyComment == null && filters.starRating == null){
                        setFilteredRows(reviewsData.length)
                    }
                }}*/
            />
        </div>
    )
}

export default Reviews;