import { Badge, Button, Card, Switch, Tag, Tooltip, message } from "antd";
import { useState } from "react";
import { useFetch } from "../../../../hooks/useFetch/useFetch";
import TableLZM from "../../../components/design/table/TableLZM";
import moment from "moment";
import { EyeOutlined, ShareAltOutlined, UsergroupAddOutlined } from "@ant-design/icons";
import { authFetch } from "../../../../common/request/authFetch";
import { ReportPreviewDrawer } from "../report/ReportPreviewDrawer";
import { authRequest } from "../../../../common/request/authRequest";
import Paragraph from "antd/lib/typography/Paragraph";

export const GeneratedReports = (props: {templateId: string, title: string}) => {
    
    const [reportSelected, setReportSelected] = useState(null);   
    const [updateData, setUpdateData] = useState(false);     

    async function generateReport(templateId: string) {
        await authFetch(`${process.env.REACT_APP_API}/templates/${templateId}/reports`, { method: 'POST' }).then(()=>{
            setUpdateData(!updateData)
        });
    }

    const changeStatus = (reportId: string, status : boolean) => {
        
        authRequest(`${process.env.REACT_APP_API}/reports/${reportId}/status?status=${status}`, { method: 'PATCH' })
        .then((response) => {                        
            message.success('Estado cambiado con éxito');
            setUpdateData(!updateData);
        }).catch(() => {
            message.error('Error inesperado');
        });
    }

    const handlePreview = (status : boolean, record: any) => {
        
     /*   authRequest(`${process.env.REACT_APP_API}/reports/${record.id}?status=${status}`, { method: 'PATCH' })
        .then((response) => {            
            setUpdateData(!updateData);
            message.success('Estado cambiado con éxito');            
        }).catch(() => {
            message.error('Error inesperado');
        });*/
    }

    const { data: reports } = useFetch(
        `${process.env.REACT_APP_API}/templates/${props.templateId}/reports`,
        {},
        [updateData, props.templateId]
    );

    const columns = [
        {            
            align:'center',
            width:'30px',
            render: (text: any, record: any) => (                        
                <>
                {
                record.id ?                
                    record.published ?
                        <><span className="material-symbols-outlined">group</span></>
                        :
                        <><span className="material-symbols-outlined">lock_person</span></>
                        :
                    <><span className="material-symbols-outlined">pending</span></>
                }
                </>
            )
        },
        {
            //title: 'Estado', 
            align:'left',           
            width:'30px',
            render: (text: any, record: any) => (                        
                record.id &&
                <Switch style={{marginRight:'5px'}} checkedChildren="Público" unCheckedChildren="Privado" checked={record.published} onChange={(e) => {changeStatus(record.id, e)}}/>                                
            )
        },
        {
            title: 'Mes',
            align: 'center',
            render: (text: any, record: any) => (              
                <>
                    {record.id ? 
                        <Tag color="blue" style={{borderRadius:'10px', minWidth:'130px'}}><b>{moment([record.year, record.month - 1]).format('YYYY MMMM')}</b></Tag>                  
                        :
                        <Tag color="lightgrey" style={{borderRadius:'10px', minWidth:'130px'}}><b>{moment([record.year, record.month - 1]).format('YYYY MMMM')}</b></Tag>                  
                        }
                </>
            )
        },        
		{
			title: '',
			key: 'id',		            	
			render: (text: string, record: any) => (
                <>{record.id && <Paragraph style={{margin:'0px', color:'grey'}} copyable={{ text: `${process.env.REACT_APP_MULTI}/public/reports/${record.id}`}}>Copiar Enlace</Paragraph>
                }</>
			)
		},        
		{
			title: '',
			key: 'locationId',		
            width: '80px',	
			render: (text: string, record: any) => (
				<div style={{display:'flex', justifyContent:'space-between'}}>	
                    {   
                        record.id ?
                        <>
                            {/*<Tooltip title='Compartir'>
                                <Button onClick={()=>{}} size='small' style={{ marginRight:'5px', borderRadius: '20px', background:'var(--primary)',  border:`solid 1.3px RoyalBlue`, color: "white" }}>    
                                    <ShareAltOutlined style={{ color: 'white' }}/> 								
                                </Button>			
                    </Tooltip>					                         		*/}
                            <Tooltip title='Previsualizar'>
                                <Button onClick={()=>{setReportSelected(record.id)}} size='small' style={{ marginRight:'5px', borderRadius: '20px', background:'var(--primary)',  border:`solid 1.3px RoyalBlue`, color: "white" }}>    
                                    <EyeOutlined style={{ color: 'white' }}/> 								
                                </Button>					
                            </Tooltip>					
                        </>
                        :
                        <Button onClick={()=>{generateReport(record.templateId)}} size='small' style={{ minWidth:'120px', marginRight:'5px', borderRadius: '20px', background:'var(--accent)',  border:`solid .3px RoyalBlue`, color: "black" }}>    
                            Crear Informe
                        </Button>			                        
                    }
                    {reportSelected && <ReportPreviewDrawer reportId={reportSelected} onFinish={() => {setReportSelected(null)}}/>}
				</div>
			)
		}
    ]

    return (
        <div>
            <Card bodyStyle={{border:'0px transparent'}}>                
                <h2>{props.title}</h2>
                {reports ? 
                    <TableLZM
                        size='small'
                        dataSource={reports}
                        columns={columns}            
                        showHeader={false}   
                        style={{ color: 'grey', background:'white' }}/>                
                    :
                    <div>dfd</div>
                }
            </Card>
        </div>
    )
}