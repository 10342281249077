import { MinusCircleOutlined, SettingOutlined } from '@ant-design/icons';
import { Button, Layout } from 'antd';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { useAuth } from '../../../../store/AuthContext/AuthContext';
import GoogleLogin from '../googleLoginButton/GoogleLogin';
import { useIsAuthorized } from '../../../pages/common/roles/Roles';


const { Header } = Layout;

const ProfileProjects = (props: {profile: any, updateHeader : any, setUpdateHeader: any}) => {
	
	const { googleLogOut} = useAuth();	
	const isAuthorized = useIsAuthorized();
	const { t } = useTranslation();

	return (
		<div style={{ marginTop: '10px' }}>
			{					
				props.profile.projects && props.profile.projects.map((project: any, i:any) => {
					return (
						<div key={i} style={{ background: '#F4F7FA', border:'5px', borderColor:'black', fontSize:'12px', borderRadius: '4px', display: 'flex', flexDirection: 'column', padding:'8px', margin: '12px', columnGap: '2px'}}>
							
							<p key={project.projectName} style={{ marginBottom: '0px', fontWeight: 'bolder' }}>
								{/*project.platforms && project.platforms[0].platformAccount &&
									<Button shape='round' size='small'>
										<SettingOutlined/>
									</Button>	*/												
								}
								<span style={{marginLeft:'5px'}}>  {project.projectName}</span>	
							</p>									
							{
								project.platforms && project.platforms.map((platform: any, i:any) => {
									return (
										platform.platformAccount ?													
											<p key={i} style={{ margin: '4px 10px' }}>
												
												<i style={{ color: 'darkblue', marginLeft: '8px' }}>  {platform.platformAccount}</i>
												{
												isAuthorized(['ROLE_ADMIN', 'ROLE_AGENT', 'ROLE_CLIENT']) && <Button shape='round' size='small' type='link' style={{ color: 'grey' }} onClick={() => { googleLogOut(project.projectName); props.setUpdateHeader(!props.updateHeader); } }>
													<MinusCircleOutlined style={{ fontSize: '12px' }} />
													<small> {t('dictionary.signOut')}</small>
												</Button>
												}

											</p>														
										:										
										<div style={{margin:'10px'}}>			
											<GoogleLogin 
												project={project.projectName} 
												onFinish={() => {
														props.setUpdateHeader(!props.updateHeader);		
														window.document.location.reload();
													}} 
											/> 
										</div>
									)
								})	
							}									
						</div>
					)
				})
			}
		</div>	
	);		
};

export default ProfileProjects;