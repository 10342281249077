import { Modal, Form, Input, Alert , message, Spin} from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getProjectStorage } from '../../../../../../store/storageContext';
import { ButtonLZ } from '../../../../../components/design/Buttons/ButtonLZ';
import { authFetch } from '../../../../../../common/request/authFetch';
import {LoadingOutlined} from '@ant-design/icons'

const AskAQuestion = ({
    visible,
    onFinish,
    locationID
}: any) => {

    const [askAQuestionForm] = Form.useForm();

    const [errorMessage, setErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const { t } = useTranslation();

    const submitQuestion = async (values: any) => {

        if (!values.text || values.text.trim() === '') {
            setErrorMessage('El texto de la pregunta no puede estar vacío.');
            return;
        }
        setIsLoading(true)

        await authFetch(
            `${process.env.REACT_APP_API}/projects/${getProjectStorage()}/${locationID}/question?text=${values.text}`,
            { method: 'POST' }
        )
            .then((res) => {
                if (res) {
                    setIsLoading(false)
                    message.success(t('view.forms.modal.question.success'));
                    setErrorMessage('')
                    onFinish();
                } else {
                    message.error(t('view.forms.modal.question.error'));
                }
            })

    }

    return (
        <Modal visible={visible} footer={null} onCancel={onFinish} width={'50%'}>
            <div style={{ padding: '5px 15px 10px 0px' }}>
                <h2 style={{ margin: 0 }}>Haz una pregunta</h2>
                <small>{getProjectStorage()}</small>
            </div>
            <div style={{ padding: '10px' }}>
                <Form layout='vertical'
                    form={askAQuestionForm}
                    onFinish={submitQuestion}
                >
                    <Form.Item name='text'>
                        <Input.TextArea
                            style={{ width: '100%' }}
                            className='input-field'
                        />
                    </Form.Item>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '15px' }}>
                        <ButtonLZ
                            children={t('dictionary.cancel')}
                        />
                        <ButtonLZ
                            children={isLoading ? <Spin indicator={<LoadingOutlined spin /> } /> : "Publicar" }
                            onClick={() => askAQuestionForm.submit()}
                        />
                    </div>
                </Form></div>

            {errorMessage && <Alert style={{ marginTop: '12px' }} type='error' message={errorMessage} />}
        </Modal>
    )
}

export default AskAQuestion;