import { Drawer, Form, Card, Rate, Input, Spin } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../../../../../store/AuthContext/AuthContext';
import { authRequest } from '../../../../../../common/request/authRequest';
import { getProjectStorage } from '../../../../../../store/storageContext';
import { ButtonLZ } from '../../../../../components/design/Buttons/ButtonLZ';
import {LoadingOutlined} from '@ant-design/icons';

const PruebasIADrawer = (props: { openDrawer: boolean, setOpenDrawer: any }) => {


    const [isLoading, setIsLoading] = useState(false);
    const [name, setName] = useState('');
    const [score, setScore] = useState(0);
    const [review, setReview] = useState('');
    const { t } = useTranslation();

    const [openTextArea, setOpenTextArea] = useState(false);

    const [replyForm] = Form.useForm();

    const { groupAccount } = useAuth();
    
    const getResponseIa = async () => {
        setIsLoading(true)
        await authRequest(`${process.env.REACT_APP_API}/${groupAccount}/chat?reviewerName=${encodeURIComponent(name)}&starRating=${score}&content=${encodeURIComponent(review)}`, {}).then((data) => {
            handleOpenTextArea(data)
            setIsLoading(false)
        }).catch((error) => {
            setIsLoading(false)
        });
    }

    const handleOpenTextArea = (responseText: any) => {
        setOpenTextArea(true)
        replyForm.setFieldsValue({ comment: responseText });
    };

    const isButtonEnabled = score > 0 && name.trim() !== '';


    return (
        <Drawer placement="right"
            width={'40%'}
            onClose={() => props.setOpenDrawer(false)} visible={true}
            bodyStyle={{ background: '#F4F7FA' }}
        >
            <>
                <h1>Haz tus pruebas</h1>
                <Card
                    className='card-layout'
                    size='small'
                    style={{ width: '90%', marginLeft: '5%', borderRadius: '10px', border: 'solid .5px lightgrey' }}
                    extra={
                        <Rate
                            style={{ fontSize: '18px' }}

                            onChange={setScore}
                        />
                    }
                    title={
                        <p style={{ margin: '0px', fontSize: '14px', fontWeight: 600, width: '200px' }}>
                            <Input placeholder="Nombre usuario" onChange={(e) => { setName(e.target.value) }} />
                        </p>
                    }
                >

                    <Input.TextArea
                        style={{ width: '95%' }}
                        className='input-field'
                        rows={4}
                        placeholder="Reseña"
                        onChange={(e) => { setReview(e.target.value) }}
                    />

                    <div>
                        <div style={{ display: 'flex', gap: '15px', alignItems: "baseline", marginTop: '10px' }}>
                            <p style={{ color: 'grey' }}>{t('dictionary.ownerReply')}</p>
                            <ButtonLZ
                                onClick={() => {
                                    getResponseIa();
                                }}
                                disabled={!isButtonEnabled}
                                style={{ width: '40px' }}
                                size='small'
                                color='dark-white'

                            >{isLoading ? <span style={{display:'flex', justifyContent:'center'}}><Spin indicator={<LoadingOutlined spin />} /></span> : t('reviews.page.reply.review.btn.ia')}</ButtonLZ>
                        </div>

                        <div style={{display: openTextArea ? 'block' : 'none'}}>
                        <Form layout='vertical' form={replyForm} >
                            <Form.Item name='comment'>
                                <Input.TextArea
                                    style={{ width: '95%' }}
                                    className='input-field'
                                    rows={4}

                                />
                            </Form.Item>
                        </Form>
                    </div>

                    </div>
                </Card>
            </>

        </Drawer>
    )
}

export default PruebasIADrawer;