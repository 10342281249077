import React from 'react';
import { Route, Switch } from 'react-router';
import { googleClientsRoutes } from './constants';


const GoogleClientsRoutes = () => {

  return (
		<Switch>
			{
				googleClientsRoutes.map((route, index) => {
					return (
						<Route
							key={index}
							path={route.path}
							exact={route.exact}
							render={() => <route.component />} />						
					)
				})
			}
		</Switch>
	);
}

export default GoogleClientsRoutes;