import { Image } from 'antd';

const Post = (props: {postGroup: any}) => {
	
  	return (	
		<>
			<div style={{fontSize:'16px', display:'block'}}>
				{
					!!props.postGroup && !!props.postGroup.mediaItemsURL && props.postGroup.mediaItemsURL.map((googleUrl : string) => {
						return (<Image width={'100%'} src={googleUrl} preview={false} style={{borderRadius:'20px 20px 0px 0px', display:'inline'}}/>)
					})
				}    
			</div>
			<div style={{padding:'15px', textAlign:'justify', background:'white', color:'rgba(0, 0, 0, 0.5)', border:'1px solid rgba(0, 0, 0, 0.1)'}}>
				{props.postGroup.summary}
			</div>
		</>	  			          				
	)
}
export default Post;