import { CaretDownOutlined, CaretUpOutlined, StarFilled } from "@ant-design/icons";

export const Increment = (props: {value: any, numberOfDecimals: number}) => {
    return (
        <b>        
            {props.value >= 0 ?  
                <span  style={{ color: 'green', marginRight: '8px' }}>
                    <CaretUpOutlined />{`${props.value.toFixed(props.numberOfDecimals)}%`}
                </span>   
                :
                <span  style={{ color: 'red', marginRight: '8px' }}>
                    <CaretDownOutlined />{`${props.value.toFixed(props.numberOfDecimals)}%`}
                </span> 
            }
        </b> 
    )
};


