import { Result } from "antd";
import { useTranslation } from 'react-i18next';

const Error500 = () => {

  const { t } = useTranslation();

  return (
    <Result
    status="500"
    title="500"
    subTitle={t('view.errors.500')}
  />)
}

export default Error500;