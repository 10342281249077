import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { CloseOutlined, EditOutlined } from '@ant-design/icons';
import moment from 'moment';
import { FestiveScheduleView } from './FestiveScheduleView';
import { FestiveScheduleEdit } from './FestiveScheduleEdit';
import { useIsAuthorized } from '../../../../../pages/common/roles/Roles';

export const FestiveSchedule = (props: { locationName: string, date: any, periods: any }) => {
    
    const { t } = useTranslation();
    const isAuthorized = useIsAuthorized();
    const [edit, setEdit] = useState(false);

    return (
        <div >
            <div style={{ display: "flex", justifyContent: 'space-between' }}>
                <p style={{ color: 'black' }}>
                    {moment(props.date).format('ll')} 
                </p>
                {edit ?
                    <CloseOutlined style={{color:'grey'}} onClick={() => setEdit(!edit)}/>
                    :
                    isAuthorized(['ROLE_ADMIN', 'ROLE_AGENT', 'ROLE_CLIENT', 'ROLE_GUEST_INFO_EDIT']) && <EditOutlined style={{color:'grey'}} onClick={() => setEdit(!edit)}/>
                }
            </div>
            
            <div style={{marginLeft:'15px'}}>
                {!edit ?                     
                    <FestiveScheduleView data={props.periods}/>                    
                    :
                    <FestiveScheduleEdit 
                        locationName={props.locationName}
                        festiveDate={props.date} 
                        ranges={props.periods} 
                        onCancel={() => {
                            setEdit(false);
                        }}
                        onFinish={() => {
                            setEdit(false);
                        }}/>                        
                }
            </div>
        </div>
    )
}