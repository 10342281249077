import { StarFilled } from "@ant-design/icons";

const colorRanges = [
    { range: [0, 0.5], color: 'rgba(248, 132, 132)' },
    { range: [0.5, 1], color: 'rgba(248, 143, 132)' },
    { range: [1, 1.5], color: 'rgba(248, 162, 132)' },
    { range: [1.5, 2], color: 'rgba(248, 177, 132)' },
    { range: [2, 2.5], color: 'rgba(248, 199, 132, 0.7)' },
    { range: [2.5, 3], color: 'rgba(248, 210, 132)' },
    { range: [3, 3.5], color: 'rgba(248, 231, 132)' },
    { range: [3.5, 4], color: 'rgba(244, 246, 133)' },
    { range: [4, 4.5], color: 'rgba(214, 246, 133)' },
    { range: [4.5, Infinity], color: 'rgba(20, 255, 0, 0.35)' },
];


export const getColor = (value: any) => {
    const matchedRange = colorRanges.find((range) => value >= range.range[0] && value < range.range[1]);
    return matchedRange ? matchedRange.color : '';
};


const ColorRate = ({ value }: any) => {

    return <div style={{
        backgroundColor: getColor(value), borderRadius: '22.5px', width: '80px', height: '25px', display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }}>
        <span>{Math.round(value * 100) / 100} <StarFilled style={{ color: '#FFC64E' }} /></span>
    </div>
};

export default ColorRate;