import { useEffect, useState } from "react";
import { AutoComplete } from "antd";
import { getProjectStorage } from "../../../../../../store/storageContext";
import { useAuth } from "../../../../../../store/AuthContext/AuthContext";
import { useFetch } from "../../../../../../hooks/useFetch/useFetch";

const InputCategories = ({ fieldId, onFocus, placeholder, onChange, defaultValue, disabled }: any) => {

  const { groupAccount } = useAuth();
  const [categories, setCategories] = useState([]);
  const [selectedValue, setSelectedValue] = useState<any>(defaultValue);

  useFetch(`${process.env.REACT_APP_API}` + '/projects/' + getProjectStorage() + `/${groupAccount}` + '/locations/categories', {}, [], (data) => {
    setCategories(data.categories);

  });

  const [opciones, setOpciones] = useState([]);

  const handleBuscarCategorias = (value: any) => {
    const categoriasFiltradas: any = categories.filter((categoria: any) =>
      categoria.displayName.toLowerCase().startsWith(value.toLowerCase())
    );
    setOpciones(categoriasFiltradas.map((categoria: any) => categoria.displayName));
  };


  const handleChange = (value: string) => {

    const selectedCategory: any = categories.find(
      (categoria: any) => categoria.displayName === value
    );

    onChange(selectedCategory || undefined);

    setSelectedValue(value);
  };

  useEffect(() => {
    if (defaultValue) {
      setSelectedValue(defaultValue.displayName);
    }
  }, [defaultValue]);

  return (
    <AutoComplete
      placeholder={placeholder}
      disabled = {disabled}
      id={fieldId}
      onFocus={onFocus}
      options={opciones.map((opcion) => ({ value: opcion }))}
      onSearch={handleBuscarCategorias}
      value={selectedValue}
      onSelect={(value) => handleChange(value)}
      onChange={(value) => setSelectedValue(value)}
    />
  );
};

export default InputCategories;
