import { Tag } from 'antd'
import { useTranslation } from 'react-i18next';

export const CategoriesView = (props: { categories: any }) => {
    
    const { t } = useTranslation();
    
    return (
        <div className="title-box">
            <a>
                <div style={{ margin: "1em 0", display: "flex", justifyContent: "flex-start" }}>
                    <b style={{ color: "#666" }}>
                        {props.categories.primaryCategory.displayName}
                    </b>
                </div>
                {
                    props.categories.additionalCategories && props.categories.additionalCategories.map((additionalCategory: any, i: number) => (
                        <p style={{ color: "#666" }} key={i}>
                            {additionalCategory.displayName}
                        </p>
                    ))
                }
            </a>
        </div>
    )
}
