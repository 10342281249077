import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { useAuth } from '../store/AuthContext/AuthContext';
import { routes } from './constants';
import MainHeader from '../view/pages/common/header/MainHeader';



const AppRoutes = () => {

	const { isAuth } = useAuth();

	return (
		<Switch>
			{
				routes.map((route, index) => {
					return (
						<Route
							key={index}
							path={route.path}
							exact={route.exact}
							render={(props) => !route.isAuthenticated || isAuth ?
								<>
									{/*route.viewHeader && <MainHeader />*/}
									<route.component />
								</>
								:
								<Redirect to={{ pathname: '/login', state: { from: props.location } }} />} >

							{
								route.path === "/" ?
									<Redirect push to="/home" />
									: null
							}


						</Route>
					)
				})
			}
		</Switch>
	);
}

export default AppRoutes;