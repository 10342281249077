import { Tag } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next';

export const PhoneView = (props:{primaryPhone : string, additionalPhones : string[], setEdit?: any}) => {
  const { t } = useTranslation();

  return (
    <div className="phone-box" onClick={()=>props.setEdit(true)}>
            <div style={{ marginTop: "1em", display: "flex", justifyContent: "flex-start" }}>
                <p style={{ color: "#666" }}>
                    {props.primaryPhone} <Tag>{t('dictionary.principal').toUpperCase()}</Tag>
                </p>
              </div>
            {
              props.additionalPhones && props.additionalPhones.map((additionalPhone: string, i:number) => (
                <p style={{ color: "#666" }} key={i}>
                    {additionalPhone}
                </p>
              ))
            }
    </div>
  )
}
