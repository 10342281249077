import { MailOutlined, NotificationOutlined, PlusCircleOutlined, SearchOutlined, ShopOutlined, StarOutlined } from '@ant-design/icons';
import { Card, Input, message, Switch, Tag } from 'antd';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import { DrawerContext } from '../../../store/DrawerContext/DrawerContext';
import { useFetch } from '../../../hooks/useFetch/useFetch';
import { authFetch } from '../../../common/request/authFetch';
import { ButtonLZ } from '../../components/design/Buttons/ButtonLZ';
import TableLZM from '../../components/design/table/TableLZM';
import '../pages.scss'
import { GuestAddModal } from './guests/GuestAddModal';
import { authRequest } from '../../../common/request/authRequest';
import { useAuth } from '../../../store/AuthContext/AuthContext';

export const Guests = () => {

  const [guestAddModalVisible, setGuestAddModalVisible] = useState(false);
  const [updateData, setUpdateData] = useState(false);    
  const { t } = useTranslation();
  
  const { profile} = useAuth();
  
    const STATUSES: any = 
		{
			CREATED: "Creado",
			ACTIVE: 'Activo',
      TO_EMAIL: 'Por Invitar'
		}

    const changeStatus = (field: string, email: string, status : boolean) => {
        
      const data = {
        guestEmail: email,
        field: field,
        active: status
      };
  
      authRequest(`${process.env.REACT_APP_API}/config/permissions/${field}`, { method: 'PATCH', data })
      .then((response) => {                        
          message.success('Estado cambiado con éxito');
          setUpdateData(!updateData);
      }).catch(() => {
          message.error('Error inesperado');
      });
    }
  
  const { data: usersData, isLoading } = useFetch(`${process.env.REACT_APP_API}/config`, {}, [updateData]);

  const sendEmail = (email: string) => {
    authFetch(`${process.env.REACT_APP_API}/users/`+ email + '/invitation', { method: 'POST' });
  }
  
  const setEnabled = (email: string, enabled: boolean) => {

    const uri = enabled 
    ? 
    `${process.env.REACT_APP_API}/users/`+ email + '/actions/unlock' 
    : 
    `${process.env.REACT_APP_API}/users/`+ email + '/actions/lock'

    authFetch(uri, { method: 'PATCH' })
    .then((isOk) => {
      if(isOk) {
        message.info(t('view.forms.modal.user.success'))
      } else {					
        message.error(t('view.forms.modal.user.error'))
      }
    }
  );
  }

  const columns = [{
		title: 'Email',
		key: 'email',
		dataIndex: 'email',
    render: (value:any, record:any) => {
      return <div>{record.email}</div>
    },
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
    }: FilterDropdownProps) => {
      return (
        <Input
          autoFocus
          placeholder="Email"
          value={selectedKeys[0]}
          onChange={(e: any) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
            confirm({ closeDropdown: false });
          }}
          onPressEnter={() => {
            confirm();
          }}
          onBlur={() => {
            confirm();
          }}
        ></Input>
      );
    },
    filterIcon: () => {
      return <SearchOutlined />;
    },
    onFilter: (value: any, record: any) => {
      return (record?.email.toLowerCase().includes(value.toLowerCase()));
    }
	},
  {
		title: t('dictionary.name'),
		key: 'name',
		dataIndex: 'name'
	},{
		title: t('dictionary.rolePosition'),
		key: 'summary',
		dataIndex: 'summary'
	},{
		title: 'Permisos',
		key: 'roles',
    render: (record: any) => {
      return (
        <div> 
          <Switch size='small' style={{marginRight:'5px'}} checkedChildren={<ShopOutlined/>} unCheckedChildren={<ShopOutlined/>} checked={record.editInfo} onChange={(e) => {changeStatus('info', record.email, e)}}/>                   
          <Switch size='small' style={{marginRight:'5px'}} checkedChildren={<StarOutlined/>} unCheckedChildren={<StarOutlined/>} checked={record.editReviews} onChange={(e) => {changeStatus('reviews', record.email, e)}}/>                   
          <Switch size='small' style={{marginRight:'5px'}} checkedChildren={<NotificationOutlined/>} unCheckedChildren={<NotificationOutlined/>} checked={record.editContent} onChange={(e) => {changeStatus('content', record.email, e)}}/>                                               
        </div>    
      )
    }
	},{
		title: t('dictionary.state'),
		key: 'status',		
    render: (record: any) => {
      return (
      <>                
        { STATUSES[record.status]
        }
      </>
      )
    }
	},
  {
		title: t('dictionary.actions'),
		key: 'enabled',
    align: 'center',
    render: (record: any) => {      
      
      return record.status === 'CREATED' || record.status === 'TO_EMAIL' 
      ?
        <ButtonLZ color='black' size='small' disabled={record.status === 'CREATED'} onClick={() => {sendEmail(record.email); setUpdateData(!updateData)}}>
          <MailOutlined/>          
        </ButtonLZ>               
      :
      <Switch defaultChecked={record.enabled} size='small' onChange={() => {setEnabled(record.email, !record.enabled); setUpdateData(!updateData)}}/>          
    } ,
    filters: [
      { text: 'Activos', value: true },
      { text: 'Bloqueados', value: false },
    ],
    onFilter: (value: any, record: any) => record.enabled === value,
	}]

  return (
    <>
      <Card className='main-card-content'>

        <h1  className='title-card'>Usuarios  <Tag color='purple'>MÓDULO EXTRA: USUARIOS</Tag></h1>
        <h3 style={{color:'steelblue'}}>Invitados de {profile.name}</h3>
                
        <div style={{display:'flex', justifyContent:'end', marginTop:'15px'}} >
          <ButtonLZ disabled={!profile.roles.includes('ROLE_MODULE_USERS')} shape='round' onClick={() => setGuestAddModalVisible(true)} type='primary' >
            <PlusCircleOutlined/> Usuario 
          </ButtonLZ>
        </div>
        <TableLZM  columns={columns} dataSource={usersData} rowKey={'email'} loading={isLoading} />
        {guestAddModalVisible && <GuestAddModal
          
          onFinish={() => {
            setGuestAddModalVisible(false);
            setUpdateData(!updateData);
          }}
        />}
      </Card>
    </>
	)
}
