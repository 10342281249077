import { Card, Divider, Drawer, message, Modal, Table, Tag } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TableLZM from '../../../components/design/table/TableLZM';
import { AddressGroup } from '../../../components/model/business/FieldsGroup';
import Post from '../../../components/model/posts/Post';
import { authRequest } from '../../../../common/request/authRequest';
import { ButtonLZ } from '../../../components/design/Buttons/ButtonLZ';
import { authFetch } from '../../../../common/request/authFetch';
import { getProjectStorage } from '../../../../store/storageContext';
import { useAuth } from '../../../../store/AuthContext/AuthContext';
import { useIsAuthorized } from '../../common/roles/Roles';

const PostLocationsList = (props: { setRefreshPosts:any, refreshPosts:boolean, postLocationsVisible: boolean, setPostLocationsVisible: any, postGroup: any }) => {

  const [postLocationsData, setPostLocationsData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const isAuthorized = useIsAuthorized();
  const { groupAccount } = useAuth();


  useEffect(() => {
    setIsLoading(true);
    const data = {
      ids: props.postGroup.ids,
      status: 'CREATED'
    };

    authRequest(`${process.env.REACT_APP_API}/projects/${props.postGroup.projectId}/accounts/${props.postGroup.accountId}/postGroup`, { method: 'POST', data: data })
      .then((data) => {
        setPostLocationsData(data);
        setIsLoading(false);
      })
      .catch(() => {
        message.error(t('view.errors.loadStores'))
        setIsLoading(false);
      });
  }, []);



  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const deletePost = async() => {
    await authFetch(`${process.env.REACT_APP_API}/projects/${getProjectStorage()}/${groupAccount}/locations?locationsIds=${selectedRowKeys}` , { method: 'DELETE'}).then(()=>{
      props.setRefreshPosts(!props.refreshPosts)
      props.setPostLocationsVisible(false);

    })
  }

  const columns = [{
    title: 'Ubicación',
    key: 'addressLine',
    render: (text: string, record: any) => (<AddressGroup record={record} />),
    dataIndex: 'addressLine'
  },
  {
    title: '',
    key: 'local',
    render: (text: string, record: any) => (<div style={{ fontWeight: 'bold' }}><a target={'_blank'} href={record.searchUrl}>{t('dictionary.viewOnGoogle')} {'>>>'}</a></div>),
    dataIndex: 'searchUrl'
  }]

  return (
    <Drawer
      bodyStyle={{ background: '#F4F7FA' }}
      visible={props.postLocationsVisible}
      title={<div>
        <strong>{props.postGroup.createdDate}</strong><Tag style={{ borderRadius: '20px', marginLeft: '20px' }} color={'blue'}>{props.postGroup.postType}</Tag>
        <br /><div style={{ fontSize: '14px', color: 'rgba(0, 0, 0, 0.5)', fontWeight: 300 }}>{t('dictionary.publishedOnStores', { length: postLocationsData.length })}</div>
      </div>}
      placement="right"
      width={'45%'}
      onClose={() => props.setPostLocationsVisible(false)}>

      <div style={{ width: '80%', margin: '0px 10% 0px 10%' }}>
        <Post postGroup={props.postGroup} />
      </div>
      <Divider orientation="left"><small>{t('dictionary.storesList')}</small></Divider>
      <div style={{ display: 'flex', justifyContent:'flex-end'}}>

       {
          <ButtonLZ
            disabled={!(selectedRowKeys.length > 0) || !isAuthorized(['ROLE_ADMIN', 'ROLE_AGENT', 'ROLE_CLIENT', 'ROLE_GUEST_CONTENT_EDIT'])}
            onClick={
              () => {
                Modal.confirm({
                  title: '¿Confirma el borrado de estas publicaciones?',
                  okText: 'Confirmar',
                  cancelText: 'Cancelar',
                  onOk() {
                    deletePost()
                  }
                });
              }
            }>
              Borrar
          </ButtonLZ>
        }
      </div>
      <TableLZM rowSelection={rowSelection} rowKey={'name'} columns={columns} dataSource={postLocationsData} loading={isLoading} />
    </Drawer>
  )
}

export default PostLocationsList;