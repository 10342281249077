import { SearchOutlined,CheckCircleFilled, ExclamationCircleFilled, GoogleOutlined, GoogleCircleFilled, ShopFilled, RightCircleFilled, RightCircleOutlined, StarFilled, FileFilled, NotificationFilled, ShopOutlined  } from "@ant-design/icons";
import { Button, Tag,  Card, Row, Col, Switch, Badge, Input, Alert  } from "antd";
import { useState } from 'react';
import { Link, useHistory, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import '../../pages/pages.scss'
import './Home.css'
import { useFetch } from "../../../hooks/useFetch/useFetch";
import TableLZM from "../../components/design/table/TableLZM";
import { getProjectStorage } from "../../../store/storageContext";
import donutYellow from "../../../assets/background/medio-donut-yellow.svg"
import circleGreen from "../../../assets/background/ellipse-green.svg"
import circleYellow from "../../../assets/background/circle-yellow.svg"
import circlePink from "../../../assets/background/circle.pink.svg"
import { useAuth } from "../../../store/AuthContext/AuthContext";
import { ActionAlert } from "../common/reports/ActionAlert";
import { ActionButton } from "../common/reports/ActionButton";
import { useIsAuthorized } from "../common/roles/Roles";

export const Home = () => {

  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const isAuthorized = useIsAuthorized();

  const { setProject, setGroupAccount } = useAuth();

  const changeGroup = (projectId: string, groupId: string, path: string) => {
    
    setProject(projectId);
    setGroupAccount(groupId);
    
    history.push(path, { from: location });
  };  

  const { data, isLoading } = useFetch(`${process.env.REACT_APP_API}/home`, {}, [], (data) => {});

  const TYPES : any = {
      "PERSONAL": "Personal",
      "LOCATION_GROUP": "Grupo de Locales",  
      "ORGANIZATION": "Agencia",  
  };

  const columns: any = [  
    {
      title: "Cuenta",
      key: 'Proyectos',     
      align: 'center',       
      width: '17%',
      render: (record: any) => {
        return (
          <div>                        
            <div style={{ display: 'flex', flexDirection: 'column' }}>                
              <div><h2><ShopFilled/> {record.projectName}</h2></div>
              <span>{record.googleAccount}</span></div>
          </div>
        )
      },
    },
    {
      title: "Grupo",
      key: 'accounts',      
      render: (record: any) => {
        return (                               
          <div style={{border:'dotted 1px lightgrey', borderRadius:'10px'}}>
            {record.groups.map((field: any, index: any) => (
                <Card style={{margin:'20px'}} bodyStyle={{background:'transparent'}}>
                   <Row>
                    <Col span={6}>
                      <b>{field.name}</b> 
                      <div style={{color:'grey'}}><small>{TYPES[field.type] || field.type}</small></div> 
                      <Badge status="processing"/>
                      <b style={{color:'grey'}}>{field.audit.totalLocations} locales</b>
                    </Col>                    
                    <Col span={6}>                         
                      <ActionButton 
                        enabled={isAuthorized(['ROLE_ADMIN', 'ROLE_AGENT', 'ROLE_CLIENT', 'ROLE_GUEST_INFO_EDIT'])}
                        onChange={() => changeGroup(record.projectName, field.id, '/negocios/locales')}                         
                        title='EDITAR'
                        icon={<ShopFilled/>}/>                                            
                      <ActionAlert                         
                        severity={field.audit.infoAlert.severity}
                        description={field.audit.infoAlert.description}/>                      
                    </Col>
                    <Col span={6}>
                      <ActionButton 
                        enabled={isAuthorized(['ROLE_ADMIN', 'ROLE_AGENT', 'ROLE_CLIENT', 'ROLE_GUEST_REVIEWS_EDIT'])}
                          onChange={() => changeGroup(record.projectName, field.id, '/clientes/locationsreviews')} 
                          title='RESPONDER'
                          icon={<StarFilled/>}/>                                            
                      <ActionAlert                         
                        severity={field.audit.reviewsAlert.severity}
                        description={field.audit.reviewsAlert.description} />                      
                    </Col>
                    <Col span={6}>                
                      <ActionButton 
                          enabled={isAuthorized(['ROLE_ADMIN', 'ROLE_AGENT', 'ROLE_CLIENT', 'ROLE_GUEST_CONTENT_EDIT'])}
                          onChange={() => changeGroup(record.projectName, field.id, '/contenidos/locales')} 
                          title='PUBLICAR'
                          icon={<NotificationFilled/>}/>          
                      <ActionAlert 
                        severity={field.audit.contentAlert.severity}
                        description={field.audit.contentAlert.description} />                      
                    </Col>
                  </Row>                     
                </Card>                
              ))
            }
          </div>
        )
      },
    }
  ]

  return (
      <div>
        <Card className='main-card-content' style={{marginLeft:'30px', background:'var(--dark-white)'}}>
          <img src={circleYellow} style={{ position: 'absolute', width: '80px', top: '1%', right: '14%' }} />
          <img src={circleGreen} style={{ position: 'absolute', width: '50px', top: '5%', right: '25%' }} />
          <img src={circlePink} style={{ position: 'absolute', width: '200px', bottom: '0%', left: '0%', transform: 'rotate(0deg)' }} />
          <img src={donutYellow} style={{ position: 'absolute', width: '250px', bottom: '-0%', right: '-22px' }} />
          <div style={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between', marginBottom: '10px' }}>
            <h1 className='title-card'>Tus Proyectos</h1>                  
          </div>
          <div style={{ padding: "20px", borderRadius:'20px', background:'ghostwhite' }}>          
            <TableLZM size='small'
              showHeader={false}              
              columns={columns}
              dataSource={data}
              loading={isLoading}
            />
          </div>
        </Card>
      </div>      
  )
};