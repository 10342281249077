import { Button, Form, Input, message, Modal } from 'antd'
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../../../../../store/AuthContext/AuthContext';
import { getProjectStorage } from '../../../../../../store/storageContext';
import { authFetch } from '../../../../../../common/request/authFetch';
import {PhoneEdit} from './PhoneEdit';
import { useContext, useState } from 'react';
import { GlobalVariableContext } from '../../../../../../store/GlobalVariableContext/GlobalVariableContext';
import { DrawerContext } from '../../../../../../store/DrawerContext/DrawerContext';

export const PhoneGroupEdit = (props: {field:string, setIsEdit?: any, isEdit?: boolean, primaryPhone: string, locationsIds: string[], isMassive: boolean, additionalPhones: string[], handleCancel?: () => any, handleSuccess?: (data: {}) => any }) => {

  const phones={ mainPhone: props.primaryPhone.replace(/\s+/g, ""), additionalPhones: props.additionalPhones };
  const additionalPhones = [0, 0];
  const { groupAccount } = useAuth();
  const { t } = useTranslation();

  const [phoneForm] = Form.useForm();

  const {updateGlobalData, setUpdateGlobalData } = useContext(GlobalVariableContext)
  const { setLogsDrawer } = useContext(DrawerContext)

	const [enabled, setEnabled] = useState('');

	const fieldId = props.field + 'ID';
  
  const submitPhone = async (values: any) => {
    const data = {
      field: 'PHONES',
      phones: {
        mainPhone: values.mainPhone,
        additionalPhones: values.additionalPhones,
      },
      locationsNames: props.locationsIds
    };

    if (props.isMassive) {
      await authFetch(`${process.env.REACT_APP_API}/projects/` + getProjectStorage() + '/' + groupAccount + '/locations', { method: 'PATCH', data: data })
        .then((res) => {
          if (res) {
            message.loading(t('view.forms.modal.bulk.triggered'), 5);
            setUpdateGlobalData(!updateGlobalData)
            props.setIsEdit(false);
            setTimeout(() => {
              setLogsDrawer(true);
            }, 500)
          } else {
            message.error(t('view.forms.modal.bulk.error'), 5);
          }
        });
    } else {
      await authFetch(`${process.env.REACT_APP_API}/projects/` + getProjectStorage() + '/' + groupAccount + '/' + props.locationsIds[0], { method: 'PATCH', data: data })
        .then((res) => {

          if (res) {
            message.success(t('view.forms.modal.single.content', { field: t('dictionary.phone').toLowerCase() }), 3);
            props.handleSuccess!(data.phones)
            setUpdateGlobalData(!updateGlobalData)

          } else {
            message.error(t('view.forms.modal.single.error'), 3)
          }
        });
    }

  }
  if (phones === undefined) return (<span>{t('dictionary.loading')}</span>)
  return (

    <Form layout='vertical' form={phoneForm} onFinish={(values) => submitPhone(values)} initialValues={phones} >
      {
        !props.isMassive ?
          <PhoneEdit phoneForm={phoneForm} handleCancel={props.handleCancel} enabled={enabled} setEnabled={setEnabled} fieldId={fieldId}/>
          :
          <div onClick={() => props.setIsEdit(true)}>
            <Form.Item name='mainPhone'
              style={{ width: '80%' }}
              rules={[{ pattern: new RegExp(/^[0-9]+$/), message: t('view.forms.fields.phone.rule') as string }]}>
              <Input disabled={!props.isEdit ? true : false} 
              id={fieldId}
              onChange={(e) => { setEnabled(e.target.value !== '' ? e.target.id : '') }}
              onFocus={(e) => { setEnabled(e.target.value !== '' ? e.target.id : '') }}
              />
            </Form.Item>
            <Form.List name="additionalPhones">
              {
                () => {
                  return (
                    additionalPhones.map((phones, i) => (
                      <Form.Item name={[i]}
                        style={{ width: '80%' }}
                        rules={[{ pattern: new RegExp(/^[0-9]+$/), message: t('view.forms.fields.phone.rule') as string }]}>
                        <Input placeholder={t('view.forms.fields.phone.without') as string} disabled={!props.isEdit ? true : false} />
                      </Form.Item>
                    ))
                  )
                }

              }
            </Form.List>
          </div>}
      {
        <Form.Item shouldUpdate >
          {() => {
            return (
              props.isMassive && props.isEdit
                && <><Button
                  disabled={!!phoneForm.getFieldsError().filter(({ errors }) => errors.length).length || enabled !== fieldId}
                  onClick={() => {
                    Modal.confirm({
                      title: t('view.forms.modal.single.title'),
                      content: t('view.forms.modal.single.content', { field: t('dictionary.phone').toLowerCase() }),
                      okText: t('dictionary.confirm'),
                      cancelText: t('dictionary.cancel'),
                      onOk: phoneForm.submit
                    })
                  }}
                  type="primary">
                  {t('dictionary.save')}
                </Button>
                  <Button
                    style={{ background: 'white', color: 'dodgerblue', marginLeft: '20px' }}
                    onClick={() => { 
                      phoneForm.resetFields();
                      props.setIsEdit!(false); }}
                    type="primary">
                    {t('dictionary.cancel')}
                  </Button>
                </>
            )
          }}
        </Form.Item>}
    </Form>
  )
}