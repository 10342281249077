import { StarFilled, StarTwoTone } from "@ant-design/icons";

const colorRanges = [
    { range: [0, 0.5], color: 'rgba(255, 105, 87, 0.8)' },
    { range: [0.5, 1], color: 'rgba(255, 105, 87, 1)' },
    { range: [1, 1.5], color: 'rgba(255, 105, 87, 1)' },
    { range: [1.5, 2], color: 'rgba(250, 191, 66, 1)' },
    { range: [2, 2.5], color: 'rgba(250, 191, 66, 1)' },
    { range: [2.5, 3], color: 'rgba(220, 223, 26, 1)' },
    { range: [3, 3.5], color: 'rgba(220, 223, 26, 1)' },
    { range: [3.5, 4], color: 'rgba(180, 255, 0, 0.8)' },
    { range: [4, 4.5], color: 'rgba(120, 255, 0, 0.8)' },
    { range: [4.5, Infinity], color: '#52c41a' },
  ];


export const getColor = (value: any) => {
    const matchedRange = colorRanges.find((range) => value >= range.range[0] && value < range.range[1]);
    return matchedRange ? matchedRange.color : '';
};

export const ColorStar = ({ value }: any) => {

    return (
        <div>
            <span><StarFilled style={{color:`${getColor(value)}`, fontSize:'17px'}}/><b> {value?.toFixed(1)}</b></span>
        </div>
    )
};

export const ColorStarExtended = ({ value }: any) => {

    return (
        <div>
            <span><StarFilled style={{color:`darkorange`, fontSize:'17px'}}/><> {value?.toFixed(4)}</></span>
        </div>
    )
};
