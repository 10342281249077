import {
  Badge,
  Drawer,
  message,
  Rate,
  Tag,
  Tooltip,
} from 'antd';
import {
  StoreCodeGroup,
} from '../business/FieldsGroup';
import { useContext, useEffect, useRef, useState } from 'react';
import { getProjectStorage } from '../../../../store/storageContext';
import { IPic } from '../../../../types/locations';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import WarningTwoTone from '@ant-design/icons/lib/icons/WarningTwoTone';
import UpdtPicsModal from '../../../pages/common/modal/UpdtPicsModal';
import { authRequest } from '../../../../common/request/authRequest';
import { useTranslation } from 'react-i18next';
import PostImages from '../../../pages/menuInfo/pageLocations/Drawer/PostImages';
import { loadImageColor } from '../../../../common/utils/GetColorImage';
import Cover from '../coverDrawer/Cover';
import LogoAndTitle from '../logoDrawer/LogoAndTitle';
import {LocationDrawerTabs} from '../tabsDrawer/LocationDrawerTabs';
import { DrawerContext } from '../../../../store/DrawerContext/DrawerContext';
import "../../../pages/menuInfo/pageLocations/LocationDetail.css"
import { useFetch } from '../../../../hooks/useFetch/useFetch';
import { GlobalVariableContext } from '../../../../store/GlobalVariableContext/GlobalVariableContext';
import { STATUS } from '../business/OpenForBusiness';
import { VERIFY_STATUS, VERIFY_STATUS_COLOR } from '../business/VerifyStatus';

interface IData {
  list: Array<IPic> | null,
  logo: IPic | null,
  portada: IPic | null
}
interface IDataRevs {
  count: number | null,
  rateAvg: number | null
}

const LocationDrawer = () => {

  const [location, setLocation] = useState<any>();

  const [rateAvg, setRateAvg] = useState<IDataRevs["rateAvg"]>(0);
  const [reviewsCount, setReviewsCount] = useState<IDataRevs["count"]>(0);
  const [postImagesVisible, setPostImagesVisible] = useState<boolean>(false);
  const [uploadPic, setUploadPic] = useState<boolean>(false)
  const [categoryPic, setCategoryPic] = useState<string>('')
  const [textPic, setTextPic] = useState<string>('')

  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();

  const { locationDrawer, setLocationDrawer, setReviewsDrawer , setQuestionsDrawer} = useContext(DrawerContext)

  const { updateGlobalImage, updateGlobalData } = useContext(GlobalVariableContext)

  const [updateMediaItems, setUpdateMediaItems] = useState<boolean>(false);

  useEffect(() => {

    //setIsLoading(true)

    authRequest(
      `${process.env.REACT_APP_API}/projects/` + getProjectStorage() + `/${locationDrawer}`,
      { method: 'GET' }
    )
      .then((location) => {
        location && setLocation(location);
      })
      .catch(() => {
        message.error(t('view.errors.loadLocation'))
      });

    authRequest(
      `${process.env.REACT_APP_API}/projects/` + getProjectStorage() + `/${locationDrawer}/reviews`,
      { method: 'GET' }
    )
      .then(({ count, rateAvg }: IDataRevs) => {
        count && setRateAvg(rateAvg)
        rateAvg && setReviewsCount(count)
      })
      .catch(() => {
        message.error(t('view.errors.loadRatings'))
      });
  }, [updateGlobalData]);

  const { data: media, isLoading: mediaLoading }: any = useFetch(
    `${process.env.REACT_APP_API}/projects/` + getProjectStorage() + `/${locationDrawer}/mediaItems`,
    {},
    [updateMediaItems],
  );

  let updateGlobalImageRef = useRef(updateGlobalImage);

  useEffect(() => {

    if (updateGlobalImageRef.current !== updateGlobalImage) {
      setIsLoading(true);

      const timeoutId = setTimeout(() => {
        setUpdateMediaItems(!updateMediaItems)
        setIsLoading(false);
      }, 3000);

      updateGlobalImageRef.current = updateGlobalImage;

      return () => {
        clearTimeout(timeoutId);
      };
    }

  }, [updateGlobalImage]);


  const { data: questions } = useFetch(
    `${process.env.REACT_APP_API}/projects/` + getProjectStorage() + `/${locationDrawer}/questions/count`,
    {},
    [],
  );

  const handleModal = (text: string, category: string): void => {
    setTextPic(text)
    setCategoryPic(category)
    setUploadPic(true)
  }

  useEffect(() => {
    loadImageColor();
  }, []);

  return (
    <>
      {location && <UpdtPicsModal
        visible={uploadPic}
        handleVisible={setUploadPic}
        locationId={location.name}
        titleText={textPic}
        category={categoryPic}
        textBtn={`${t('dictionary.publish')} ${t('dictionary.picture')}`}
      />
      }
      {
        location && location.title ? (
          <Drawer
            title={
              <div className='details-header' style={{ height: '200px', position: 'relative', width: '100%' }}>
                <Cover portada={media?.portada?.googleUrl} handleModal={handleModal} isLoading={isLoading || mediaLoading} />
                <LogoAndTitle handleModal={handleModal} logo={media?.logo?.googleUrl} location={location} isLoading={isLoading || mediaLoading} />
              </div>
            }
            placement="right"
            width={'55%'}
            visible={location != null}
            onClose={() => setLocationDrawer(null)}
            bodyStyle={{ background: '#F4F7FA' }}>

            <div >

              <div style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: '30px' }}>
                <small className='address-group-layout'>
                  {
                    location.locality && location.administrativeArea && location.addressLine ?
                      `${location.locality}, ${location.administrativeArea}, ${location.addressLine} (CP:${location.postalCode})` :
                      "Sin ubicación"
                  }
                </small>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between', padding: '0px 30px', alignItems: 'center' }}>
                <div style={{ display: 'flex', flexDirection: 'column', cursor: 'pointer' }} >
                  <small >
                    <a onClick={() => { setReviewsDrawer(location?.composeId) }}>{reviewsCount} {t('dictionary.reviews').toLowerCase()}</a> | <a onClick={() => { setQuestionsDrawer(location?.composeId) }}>{questions} {t('dictionary.questions')}</a>
                  </small>
                  <Rate allowHalf value={rateAvg!} disabled={true} />
                </div>

                { location.openForBusiness?.status?.includes('CLOSED') && <Tag color='red'>{STATUS[location.openForBusiness?.status]}</Tag>}					                              
                <div>
                  <Badge status={VERIFY_STATUS_COLOR[location.status]}/>{VERIFY_STATUS[location.status]}
                </div>                                                  
              </div>
              {location && <LocationDrawerTabs location={location} mediaItems={media?.list} setPostImagesVisible={setPostImagesVisible} />}
            </div >
          </Drawer >
        ) : null
      }

      {
        postImagesVisible && <PostImages
          location={location}
          visible={postImagesVisible}
          onFinish={() => {
            setPostImagesVisible(false);
          }}
          onCancel={() => {
            setPostImagesVisible(false);
          }} />
      }

    </>
  )
}

export default LocationDrawer;
