import { Divider } from 'antd';
import { Name } from '../location/info/name/Name';
import { Description } from '../location/info/description/Description';
import { StoreCode } from '../location/info/storeCode/StoreCode';

const InfoTab = ({ location, locationsIds}: any) => {
    return (
        < >
            <Name titleStatus={location.title!} locationsIds={locationsIds} />
            <Divider orientation="left" style={{ margin: "15px" }} plain></Divider>
            <StoreCode storeCode={location.storeCode!} locationsIds={locationsIds} />
            <Divider orientation="left" style={{ margin: "15px" }} plain></Divider>
            <Description descriptionStatus={location.description!} locationsIds={locationsIds} />
        </>
    );
};

export default InfoTab;