import { Modal } from 'antd';
import React from 'react';
import UploadInput from '../../../components/model/business/forms/UploadInput';

interface Props {
  visible: boolean,
  handleVisible: React.Dispatch<React.SetStateAction<boolean>>,
  locationId: string,
  titleText: string,
  category: string,
  textBtn: string,
}

const UpdtPicsModal = ({
  visible,
  handleVisible,
  locationId,
  titleText,
  category,
  textBtn
}: Props): JSX.Element => {

  return (
    <>
      <Modal
        visible={visible}
        footer={null}
        width={450}
        onCancel={() => handleVisible(false)}
      >


        <h2 style={{ paddingTop: '5px', paddingBottom: '10px', paddingRight: '15px' }}>{titleText}</h2>

        <UploadInput
          selectedKeys={[locationId]}
          onFinish={() => handleVisible(false)}
          category={category}
          textBtn={textBtn}
        />
      </Modal>
    </>
  );
};

export default UpdtPicsModal