import { ApiOutlined, CloudSyncOutlined, FormOutlined, UserAddOutlined, UserOutlined } from '@ant-design/icons';
import { Alert, Button, Card, Form, Input, message, Steps } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import { authRequest } from '../../../../common/request/authRequest';
import { useAuth } from '../../../../store/AuthContext/AuthContext';
import { ButtonLZ } from '../../../components/design/Buttons/ButtonLZ';
import MainHeader from '../../common/header/MainHeader';

export const GuestRegister = () => {

  const [form] = Form.useForm();
  const location = useLocation();
  const history = useHistory();
  const pathName = location.pathname.split('/');
  const { logIn } = useAuth();
  const email = pathName[pathName.length - 1];
  const verifyToken = new URLSearchParams(location.search).get('verificationToken');

  const [formError, setFormError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [userData, setUserData] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    authRequest(`${process.env.REACT_APP_API}/users/${email}?verificationToken=${verifyToken}`, {})
      .then((response) => {

        setUserData(response)
      }).catch((error) => {
        setFormError(true);
        !!error.data.message ?
          setErrorMessage(error.data.message) : setErrorMessage(t('view.errors.loadUser') as string);
      }
      );
  }, [])

  const onFinishRegister = async (values: any) => {
    const data = {
      ...values,
      email,
      status: 'ACTIVE',
      verificationToken: verifyToken
    };

    const loginData = {
      username: values.email,
      password: values.password
    };

    authRequest(`${process.env.REACT_APP_API}/users/register/guest?verificationToken=${verifyToken}`, { method: 'PUT', data })
      .then(() => {
        logIn(loginData);
        message.success(t('view.forms.modal.register.success'), 3);
        history.push('/');
        setFormError(false);
      }).catch((error) => {
        setFormError(true);
        !!error.data.message ?
          setErrorMessage(error.data.message) : setErrorMessage(t('view.forms.modal.register.error') as string);
      }
      );
  };

  useEffect(() => {
    form.setFieldsValue({ email: (userData as any).email });
  }, [userData])

  return (
    <>
    <MainHeader />
    <div style={{ width: '100%', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', background: 'white' }}>

      <Card bordered={true} style={{ padding: '24px', width: '70%', minHeight: '60%', border: '.1px solid lightgrey', borderRadius: '10px', background: '#f5f9fb' }}>
      <h1  className='title-card'>{'Invitación al Registro'}</h1>
      <br />        
        <br />
        <Form
          form={form}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 16 }}
          onFinish={onFinishRegister}
          autoComplete="off"
        >
          <Form.Item name='email' label={t('dictionary.user')}>
            <Input disabled={true} />
          </Form.Item>
          <Form.Item name='name' label={t('dictionary.name')} rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name='summary' label={t('dictionary.rolePosition')} rules={[{ required: true }]}>
            <Input/>
          </Form.Item>
          <Form.Item name='password' label={t('dictionary.password')}
          rules={[
            {
              required: true,
              message: t('view.forms.fields.password.required') as string,
            },
            {
              min: 8,
              message: t('view.forms.fields.password.characters') as string,
            },
            {
              pattern: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d!@#$%^&*()_+={}\[\]:;"'<>,.?/~`|\\-]*$/,
              message:  t('view.forms.fields.password.letters') as string,
            },
            {
              pattern: /^(?=.*[a-z])(?=.*[A-Z])/,
              message:  t('view.forms.fields.password.upperCase') as string,
            },
          ]}
          // rules={[{ required: true }]}
          >
            <Input.Password autoComplete='new-password'/>
          </Form.Item>
          <br />
          
          <ButtonLZ shape='round' type='primary' style={{float:'right'}} onClick={() => form.submit()} color={'yellow'}>
            {t('dictionary.register')}
          </ButtonLZ>
        </Form>
        <br />
        {
          formError && <Alert style={{ marginTop: '12px' }} type='error' message={errorMessage} />
        }
      </Card>

    </div>
    </>)
}
