import { Form, Select } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

const FormItemPublishPhotoType = () => {		

  const { t } = useTranslation();	

  return (		
	<Form.Item initialValue={''} label={t('view.forms.fields.type.label')} name='mediaType' rules={[{ required: true}]}>
		<Select>															
			<Select.Option value='EXTERIOR'>
				{t('dictionary.outside')}
			</Select.Option>
			<Select.Option value='INTERIOR'>
				{t('dictionary.inside')}
			</Select.Option>
			<Select.Option value='PRODUCT'>
				{t('dictionary.product')}
			</Select.Option>
			<Select.Option value='AT_WORK'>
				{t('dictionary.atWork')}
			</Select.Option>
			<Select.Option value='FOOD_AND_DRINK'>
				{t('dictionary.foodAndDrink')}
			</Select.Option>
			<Select.Option value='MENU'>
				{t('dictionary.menu')}
			</Select.Option>
			<Select.Option value='COMMON_AREA'>
				{t('dictionary.commonArea')}
			</Select.Option>
			<Select.Option value='ROOMS'>
				{t('dictionary.rooms')}
			</Select.Option>
			<Select.Option value='TEAMS'>
				{t('dictionary.teams')}
			</Select.Option>
			<Select.Option value='ADDITIONAL'>
				{t('dictionary.additional')}
			</Select.Option>
		</Select>
	</Form.Item>				
	)
}

export default FormItemPublishPhotoType;