import { EditOutlined } from '@ant-design/icons'
import { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { NameView } from './NameView';
import { NameEdit } from './NameEdit';
import { useIsAuthorized } from '../../../../../pages/common/roles/Roles';

export const Name = (props: { titleStatus: string, locationsIds:string[]}) => {
    const [edit, setEdit] = useState(false);
    const [editVisible, setEditVisible] = useState(false);
    const [currentName, setCurrentName] = useState(props.titleStatus);
    const { t } = useTranslation();
    const isAuthorized = useIsAuthorized();
    
    const handleMouseEnter = () => {
        setEditVisible(true);
    };

    const handleMouseLeave = () => {
        setEditVisible(false);
    };
      
    return (
        <div  
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={{backgroundColor:editVisible ? 'var(--light-grey)' : ''}}>
        
            <span style={{ textDecoration: "none", fontSize: "16px", color: "#3C4043", marginLeft:"1em", cursor:'pointer' }} onClick={() => setEdit(true)}>
                {t('dictionary.name')}
                {editVisible && isAuthorized(['ROLE_ADMIN', 'ROLE_AGENT', 'ROLE_CLIENT', 'ROLE_GUEST_INFO_EDIT']) && <EditOutlined />}
            </span>
            {
                !edit
                ?<NameView titleStatus={props.titleStatus!} />
                : <NameEdit 
                    isMassive={false} 
                    name={currentName} 
                    locationsIds={props.locationsIds} 
                    setEdit={setEdit} 
                    edit={edit} 
                    setCurrentName={setCurrentName}
                    
                />
            }
            
        </div>
    )
}
