import { useState } from 'react'
import { CloseOutlined, EditOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next';
import { EditableBlockForm } from './EditableBlockForm';
import { Button } from 'antd';



export enum CustomField {
    info,
    conversion,
    reviews,
    content
}

export const EditableBlock = (props: { field: string, placeholder: string, reportId: string, onFinish: any }) => {
    
    const [isFormVisible, setIsFormVisible] = useState(false);	
    const [editVisible, setEditVisible] = useState(false);
    const { t } = useTranslation();

    const handleMouseEnter = () => {
        setEditVisible(true);
      };
    
    const handleMouseLeave = () => {
        setEditVisible(false);
    };

    const handleOnClick = () => {
        
        editVisible && !isFormVisible && setIsFormVisible(true);
        
    };

    return (
        <div onClick={handleOnClick}  onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} style={{display:'flex', justifyContent:'space-between', fontSize: "17px", border:'5px dashed Thistle', minHeight:'200px', margin:'15px', padding:'15px', color:'grey', backgroundColor: editVisible ? 'LavenderBlush' : ''}}>                    
            <div style={{width:'80%'}}>
                {isFormVisible ?                     
                    <EditableBlockForm reportId={props.reportId} field={props.field.toString()} placeholder={props.placeholder} onFinish={() => {setIsFormVisible(false); props.onFinish()}}/>
                    : 
                    <div style={{color:'Thistle'}}><EditOutlined style={{ fontSize: '24px' }}/> {props.placeholder ? <span style={{color:'black'}}>{props.placeholder}</span> : 'Bloque de texto personalizable'}</div>
                }
            </div>            
        </div>
    )
}
