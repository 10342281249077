import { ClockCircleOutlined, GoogleCircleFilled, InfoCircleOutlined, PhoneOutlined } from '@ant-design/icons';
import { Tag, Button, Divider, Form, message, Modal, Tabs, Alert, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

import { PhoneGroupMassive } from '../../../components/model/location/contact/phone/Massive/PhoneGroupMassive';
import { WebGroupMassive } from '../../../components/model/location/contact/web/Massive/WebGroupMassive';
import { NameGroupMassive } from '../../../components/model/location/info/name/Massive/NameGroupMassive';
import { DescriptionGroupMassive } from '../../../components/model/location/info/description/Massive/DescriptionGroupMassive';
import { CategoriesMassive } from '../../../components/model/location/info/category/massive/CategoriesMassive';
import { NormalScheduleMassive } from '../../../components/model/location/hours/NormalScheduleMassive';

export const GoogleEditPanel = ({
	selectedRowKeys,
	visible,
	onFinish,
	onCancel,
}: any) => {

	const { t } = useTranslation();
	const onFormCancel = async (values: any) => {
		onCancel();
	}

	/* Edición masiva de locales */
	
	return (
		<Modal visible={visible} footer={null} onCancel={onFormCancel} width={'60%'} bodyStyle={{minHeight:'90vh'}} centered>

			<div style={{ position: 'relative' }}>
				<h2 style={{ paddingTop: '5px', paddingRight: '15px' }}>{t('locals.page.management.modals.edit.title')}</h2>				
				<div style={{color:'dodgerblue'}}><b>{selectedRowKeys.length} locales seleccionados</b></div>
			</div>			

			<br/>
			<Alert message={<><div>La edición de campos principales, como por ejemplo son la categoría o la dirección del local pueden implicar una re-verificación del negocio por parte de Google. 
				<a href='https://support.google.com/business/answer/7107242?hl=es' target={'_blank'}> Verifica tu negocio en Google</a></div></>} type="warning" showIcon />								
			<br/>

			<Tabs defaultActiveKey="1" type="line" style={{ width: '100%' }}>
				
				<Tabs.TabPane tab={<span className='title-tabPane'>{t('dictionary.businessInformation')}</span>} key="1">
					<NameGroupMassive locationsIds={selectedRowKeys} />					
					<DescriptionGroupMassive locationsIds={selectedRowKeys} />
				</Tabs.TabPane>

				<Tabs.TabPane tab={<span className='title-tabPane'>{t('dictionary.contactInformation')}</span>} key="2">
					<PhoneGroupMassive primaryPhone={''} additionalPhones={[]} locationsIds={selectedRowKeys} />
					<WebGroupMassive locationsIds={selectedRowKeys} />
				</Tabs.TabPane>

				<Tabs.TabPane tab={<span className='title-tabPane'>{t('dictionary.category')}</span>} key="3">
					<CategoriesMassive primaryCategory={''} additionalCategories={[]} locationsIds={selectedRowKeys} />
				</Tabs.TabPane>

				<Tabs.TabPane tab={<span className='title-tabPane'>{t('dictionary.schedule')}</span>} key="4">
					<NormalScheduleMassive locationsIds={selectedRowKeys} onFinish={onFinish} onCancel={onCancel}/>
				</Tabs.TabPane>
			</Tabs>

		</Modal>
	)
}
