import { EditOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next';
import { useState } from 'react'
import { WebEdit } from '../WebEdit';

export const WebGroupMassive = (props: { locationsIds: string[] }) => {

    const [edit, setEdit] = useState(false);
    const { t } = useTranslation();

    return (
        <div>
            <span onClick={() => setEdit(true)} style={{ display: "block", width: "100%", textDecoration: "none", fontSize: "16px", color: "#3C4043", cursor: "pointer" }}>{t('dictionary.web')}
                <EditOutlined/>
            </span>
            <WebEdit isMassive={true} setCurrentURL={() => { }} setEdit={setEdit} edit={edit} locationsIds={props.locationsIds} />
        </div>
    )
}