import { Form, message } from 'antd'
import  { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../../../../../store/AuthContext/AuthContext';
import { getProjectStorage } from '../../../../../../store/storageContext';
import { authFetch } from '../../../../../../common/request/authFetch';
import EditableInputItem from '../../../business/forms/EditableInputItem';
import { GlobalVariableContext } from '../../../../../../store/GlobalVariableContext/GlobalVariableContext';
import { DrawerContext } from '../../../../../../store/DrawerContext/DrawerContext';

export const NameEdit = (props: {name ?: string, locationsIds : string[], setEdit : (isEdit: boolean) => void, edit:boolean, setCurrentName : (name : string) => void, isMassive : boolean}) => {

    const { groupAccount } = useAuth();
    const [nameForm] = Form.useForm();
    const { t } = useTranslation();

    const {updateGlobalData, setUpdateGlobalData } = useContext(GlobalVariableContext)
    const { setLogsDrawer } = useContext(DrawerContext)


    const submitName = async(values:any) => {
        const data = {
			field: 'NAME',
			name: values.name,
      locationsNames: props.locationsIds
		};

    if (props.isMassive) {
        await authFetch(`${process.env.REACT_APP_API}/projects/` + getProjectStorage() + '/' + groupAccount + '/locations', { method: 'PATCH', data: data })
        .then((res) => {
          if (res) {
            message.loading(t('view.forms.modal.bulk.triggered'), 5);
            setUpdateGlobalData(!updateGlobalData)
            props.setEdit(false);
            setTimeout(() => {
              setLogsDrawer(true);
            }, 500)
          } else {
            message.error(t('view.forms.modal.bulk.error'), 5);
          }
        });
    } else {
      await authFetch(`${process.env.REACT_APP_API}/projects/` + getProjectStorage() + '/' + groupAccount + '/' + props.locationsIds[0], { method: 'PATCH', data: data })
      .then((res) => {

        if (res) {
          message.success(t('view.forms.modal.single.success', { field: t('dictionary.name').toLowerCase() }), 3);
          props.setEdit(false);
          props.setCurrentName(values.name);
          setUpdateGlobalData(!updateGlobalData)
        } else {
          message.error(t('view.forms.modal.single.error'), 3)
        }
      });
    }
    }
    return (
        <div>
            <Form layout='vertical' form={nameForm} onFinish={(values) => submitName(values)} initialValues={{name:props.name}}>
            <EditableInputItem
              form={nameForm}
              value={props.name}
              field='name'
              label={props.isMassive ? (t('dictionary.name') as string) : undefined}

              onSubmitCallback={nameForm.submit}
              edit={props.edit}
              setEdit={props.setEdit}
              isMassive={props.isMassive}
              />
          </Form>
        </div>
    )
}







