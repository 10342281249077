import { Card, Col, Row } from "antd";
import { useState } from 'react'
import { useAuth } from '../../../../store/AuthContext/AuthContext';
import { useFetch } from "../../../../hooks/useFetch/useFetch";
import { getProjectStorage } from "../../../../store/storageContext";
import { CalendarOutlined, InfoCircleOutlined, NotificationOutlined, StarFilled } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import './ContentsSummary.css'
import '../../../../styles/Summaries.css';
import { Column, DualAxes } from "@ant-design/charts";
import MathUtils from "../../../../common/utils/MathUtils";
import { SpinLZ } from "../../../components/design/Spinner/SpinLZ";
import PDFGenerator from "../../../components/model/pdf/GeneratePDF";
import { SCHEDULEDJOB } from "../../../../common/constants/services";
import { Synchronized } from "../../../components/design/Synchronized/Synchronized";
import Tooltip from "antd/es/tooltip";
import { BlockContentKpis } from "../blocks/BlockContentKpis";
import { BlockContentGlobal } from "../blocks/BlockContentGlobal";

export const ContentsSummary = () => {

    const { groupAccount } = useAuth();

    const { t } = useTranslation();
    
    const [kpis, setKpis] = useState<any>(null);
    const [content, setContent] = useState<any>(null);
    const [isLoading, setIsLoading] = useState(true);

    useFetch(`${process.env.REACT_APP_API}/projects/` + getProjectStorage() + '/' + groupAccount + '/summary/contents', {}, [],
        (data: any) => {
            setKpis(data.postKPIs);
            setContent(data);
            setIsLoading(false);
        });        

    return (
        <div>
            <Card className='main-card-content' id='element-to-capture'>

                <Synchronized service={[SCHEDULEDJOB.GBP_POSTS]}/>	
                
                <h1 className='title-card'>{t('reviews.page.global.title')}</h1>

                {isLoading ? <SpinLZ/> : <div className="card-container">

                    {kpis && <BlockContentKpis kpis={kpis}/>}
                    {content && <BlockContentGlobal data={content}/>}
                    
                </div>
                }
            </Card>
        </div>
    )
}