import React from 'react'

export const DescriptionView = (props:{value : string}) => {
  return (
    <div className="title-box">
      <div style={{ margin: "1em 0", display: "flex", justifyContent: "flex-start" }}>
          <span style={{ color: "#666" }}>
              {props.value}
          </span>
      </div>
    </div>
  )
}