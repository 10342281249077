import { notification } from 'antd';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../store/AuthContext/AuthContext';
import { authRequest } from '../../common/request/authRequest';


const allowedPaths: any = {
  '/negocios': true,
  '/clientes': true,
  '/contenidos': true,
  '/admin': true,
  '/tech': true,
};

export const useFetch = (url: string, config: any, deps: Array<any> = [], callback?: (data: any) => void) => {

  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const { logOut } = useAuth();


  useEffect(() => {
    const pathName = location.pathname
    let basePath = pathName.substring(0, pathName.lastIndexOf('/'))

    if (!allowedPaths[basePath]) {
      basePath = '';
    }

    setIsLoading(true);

    authRequest(url, config)
      .then((data) => {

        setData(data)
        !!callback ? callback(data) : setData(data);
        setIsLoading(false);
      })
      .catch((error) => {

        setIsLoading(false);

        if (error && error.status === 401) {
          if (error.data.message === 'Google') {
            //history.push('/vincular', { from: location });
          } else {
            logOut();
          }
        } else {

          if (error) {
            if (error.status === 403) {
              history.push(`${basePath}/error403`, { from: location });
            } else if (error.status === 404) {
              history.push(`${basePath}/error404`, { from: location });
            } else if (error.status === 500) {
              history.push(`${basePath}/error500`, { from: location });
            } else {
              notification.error({
                message: t('hooks.useFetch.errors.unexpected.message'),
                description: t('hooks.useFetch.errors.unexpected.description')
              });
            }
          } else {

            notification.error({
              message: t('hooks.useFetch.errors.connection.message'),
              description: t('hooks.useFetch.errors.connection.description')
            });
          }
        }
      }
      );
  }, [url, ...deps]);

  return {
    data,
    isLoading,
  };
}