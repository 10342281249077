import { Divider } from 'antd';
import NormalSchedule from '../location/hours/NormalSchedule';
import { SpecialSchedule } from '../location/hours/SpecialSchedule';

const ScheduleTab = ({ location}: any) => {
    return (
        <>
            <NormalSchedule location={location} />
            <Divider orientation="left" style={{ margin: "15px" }} plain></Divider>
            <SpecialSchedule location={location} />
        </>
    );
};

export default ScheduleTab;