import { EditOutlined } from '@ant-design/icons';
import "./hoverIcon.css"
import { useState } from 'react';

export default function HoverIcon({ onClick, border }: any) {
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

   
    return (
        <div className={`toplayer ${border ? 'with-border' : ''}`} onClick={onClick}
            style={{ position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                opacity: isHovered ? 1 : 0,
                transition: 'opacity 0.3s ease',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
                borderRadius: border? '50%' : 'none'
            }}

            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <EditOutlined className="edit-icon" />
        </div>
    )
}