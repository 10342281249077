import { CheckOutlined, CloseOutlined, DeleteOutlined, EditOutlined, LikeOutlined, UserOutlined } from "@ant-design/icons";
import { Avatar, Card, Comment, Form, Input, Tag, message } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { STATUS } from "../../../../../common/constants/statusFAQAndReviews";
import { authFetch } from "../../../../../common/request/authFetch";
import { getProjectStorage } from "../../../../../store/storageContext";
import { EmojiPickerCustom } from "../../../design/EmojiPicker/EmojiPickerCustom";
import { useIsAuthorized } from "../../../../pages/common/roles/Roles";

const QuestionAnswer = ({ questionId, answer, onChange }: any) => {

    const { t } = useTranslation();
    const [replyForm] = Form.useForm();
    const isAuthorized = useIsAuthorized();
    const [status, setStatus] = useState('');

    const daysAgo = (date: string): number => {
        const oneDay: number = 24 * 60 * 60 * 1000
        return Math.round(Math.abs((Date.now() - new Date(date).getTime()) / oneDay));
    };

    const submitReply = async ({ comment }: { comment: string }): Promise<void> => {

        const data = {
            comment,
            questionId: questionId,
            answerId: answer.name
        };
        await authFetch(
            `${process.env.REACT_APP_API}/projects/` + getProjectStorage() + '/questions/reply',
            { method: 'PUT', data: data }
        )
            .then((res) => {
                if (res) {
                    message.success(t('view.forms.modal.reviews.reply.success'), 3);
                    setStatus('');
                    onChange();
                } else {
                    message.error(t('view.forms.modal.reviews.reply.error'), 3);
                }
            });
    };

    const deleteReply = async (): Promise<void> => {
        const data = {
            questionId: questionId,
            answerId: answer.name,
        };
        await authFetch(
            `${process.env.REACT_APP_API}/projects/` + getProjectStorage() + '/questions/reply',
            { method: 'DELETE', data }
        )
            .then((res) => {
                if (res) {
                    message.success(t('view.forms.modal.reviews.delete.success', { name: answer.reviewerDisplayName }), 3);
                    onChange();
                } else {
                    message.error(t('view.forms.modal.reviews.delete.error', { name: answer.reviewerDisplayName }), 3);
                }
            })
    }

    const [showPicker, setShowPicker] = useState(false);

    const [cursorIndex, setCursorIndex] = useState(0);

    const handleFocus = (event: any) => {
        setShowPicker(false);
    }

    const handleBlur = (event: any) => {
        setCursorIndex(event.target.selectionStart);
    };

    const handleEmojiClick = (emoji: any) => {

        const textArea = replyForm.getFieldValue('comment');
        const textAreaWithEmoji =
            textArea.slice(0, cursorIndex) +
            emoji.native +
            textArea.slice(cursorIndex);
        replyForm.setFieldsValue({ comment: textAreaWithEmoji });
    };

    return (
        <Card
            className='card-layout'
            size='small'
            style={{ width: '90%', marginLeft: '5%', border: 'solid .5px lightgrey' }}
            bodyStyle={{ backgroundColor: 'rgb(236, 245, 243)', borderRadius: '10px' }}
        >
            <Comment
                actions={
                    isAuthorized(['ROLE_ADMIN', 'ROLE_AGENT', 'ROLE_CLIENT', 'ROLE_GUEST_REVIEWS_EDIT']) && answer.author.type === 'MERCHANT' && status !== STATUS.EDITING ? ([
                        <a className='review-btn-grey'
                            onClick={() => setStatus('Editing')}>
                            <EditOutlined style={{ marginRight: '2x' }} />
                            {t('dictionary.edit')}
                        </a>,
                        <a className='review-btn-grey'
                            onClick={() => deleteReply()}
                        >
                            <DeleteOutlined style={{ marginRight: '2px' }} />
                            {t('dictionary.delete')}
                        </a>
                    ]) : undefined
                }
                author={answer.author.type === 'MERCHANT' ?
                    <div>Propietario <div style={{ color: 'black', fontSize: '14px' }}>{answer.author.displayName}</div></div>
                    :
                    <div style={{ color: 'black', fontSize: '14px' }}>{answer.author.displayName}</div>}
                avatar={answer.author.type === 'MERCHANT' ? <Avatar style={{ backgroundColor: '#87d068', marginRight: '10px' }} icon={<UserOutlined />} /> : answer.author.profilePhotoUri}
                content={
                    status === STATUS.EDITING ? (
                        <Form layout='vertical'
                            form={replyForm}
                            onFinish={submitReply}
                            initialValues={{comment:answer.text}}
                        >
                            <Form.Item name='comment'>
                                <Input.TextArea
                                    className='comment-answer'
                                    style={{ width: '93%' }}
                                    onFocus={handleFocus}
                                    onBlur={handleBlur}
                                />
                            </Form.Item>
                            <div>
                                <a className='review-btn'
                                    onClick={() => { replyForm.resetFields(); setStatus(''); }}
                                >
                                    <CloseOutlined /> {t('dictionary.cancel')}
                                </a>
                                <a className='review-btn'
                                    onClick={() => { replyForm.submit(); setStatus(''); }}
                                >
                                    <CheckOutlined /> {t('dictionary.accept')}
                                </a>
                            </div>
                            <EmojiPickerCustom handleEmojiClick={handleEmojiClick} showPicker={showPicker} setShowPicker={setShowPicker} />

                        </Form>
                    ) : (
                        !!answer.text && (
                            <div className='comment-answer'>
                                {answer.text}
                            </div>
                        )
                    )
                }
                datetime={
                    <small style={{ color: 'gray' }}>
                        {
                            daysAgo(answer.updateTime) > 1 ?
                                t('dictionary.daysAgo', { days: daysAgo(answer.updateTime) }).toLowerCase() :
                                <Tag color={'green'} style={{ borderRadius: '10px' }}>{t('dictionary.new.female').toUpperCase()}</Tag>
                        }
                    </small>
                }
            />
            <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: '2px' }}>
                {answer.upvoteCount !== null ? answer.upvoteCount : 0}
                <LikeOutlined />
            </div>
        </Card>
    )
}

export default QuestionAnswer