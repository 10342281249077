import { CheckCircleFilled, ExclamationCircleFilled } from "@ant-design/icons";
import { Button } from "antd";

export const ActionButton = (props : {onChange: any, title: string, icon: any, enabled: boolean}) => {  

  return (
    <>
      <Button disabled={!props.enabled} onClick={() => props.onChange()} size='small' style={{ borderRadius: '20px', background:'var(--primary)',  border:`solid 1.3px RoyalBlue`, color: "white", margin:'5px 0px', minWidth:'150px' }}>    
        <div style={{color:'white'}}>
          {props.icon}<small style={{color:'white'}}> {props.title}</small>
        </div>    
      </Button>   
    </>    
  )
};