import { EditOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react'
import { PhoneGroupEdit } from './PhoneGroupEdit';
import { PhoneView } from './PhoneGroupView';
import { useIsAuthorized } from '../../../../../pages/common/roles/Roles';

export const PhoneGroup = (props: { primaryPhone: string, additionalPhones: string[], locationsIds: string[] }) => {
    
    const [edit, setEdit] = useState(false);
    const [primaryPhone, setPrimaryPhone] = useState(props.primaryPhone);
    const [additionalPhones, setAdditionalPhones] = useState(props.additionalPhones);
    const [editVisible, setEditVisible] = useState(false);
    const isAuthorized = useIsAuthorized();

    const { t } = useTranslation();

    const handleCancel = () => {
        setPrimaryPhone(props.primaryPhone);
        setAdditionalPhones(props.additionalPhones);
        setEdit(false);
    }

    const handleSuccess = (data:any) => {
        setPrimaryPhone(data.mainPhone);
        setAdditionalPhones(data.additionalPhones);
        setEdit(false);
    }

    const handleMouseEnter = () => {
        setEditVisible(true);
      };
    
      const handleMouseLeave = () => {
        setEditVisible(false);
      };

return (
    <div         
    onMouseEnter={handleMouseEnter}
    onMouseLeave={handleMouseLeave}
    style={{backgroundColor: editVisible ? 'var(--light-grey)' : ''}}>
        {editVisible && isAuthorized(['ROLE_ADMIN', 'ROLE_AGENT', 'ROLE_CLIENT', 'ROLE_GUEST_INFO_EDIT']) && 
        <span onClick={() => setEdit(true)} style={{ textDecoration: "none", fontSize: "16px", color: "#3C4043", marginLeft:"1em", cursor:'pointer' }}>
            { t('dictionary.phone') } 
            <EditOutlined />
        </span>
        }
        {
            !edit ?
                <PhoneView
                    setEdit={setEdit}
                    primaryPhone={primaryPhone}
                    additionalPhones={additionalPhones} />
                :
                <PhoneGroupEdit
                    field='decription'
                    isMassive={false}
                    primaryPhone={primaryPhone}
                    additionalPhones={additionalPhones}
                    locationsIds={props.locationsIds}
                    handleCancel={handleCancel}
                    handleSuccess={handleSuccess} />
        }
    </div>
)
}