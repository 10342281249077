import { useContext, useState } from "react";
import { DrawerContext } from "../../../../store/DrawerContext/DrawerContext";
import { GlobalVariableContext } from "../../../../store/GlobalVariableContext/GlobalVariableContext";
import { useFetch } from "../../../../hooks/useFetch/useFetch";
import { Card, Col, Drawer, Row } from "antd";
import { UserEditRoles } from "../../menuAdmin/4_1_users/ModalUsers/UserEditRoles";
import DrawerLZ from "../../../components/design/Drawer/DrawerLZ";
import { useLocation } from "react-router-dom";
import { EditOutlined } from "@ant-design/icons";
import "./Drawer.css"
import { UserModule } from "./UserModule";

export const UserDrawer = () => {

    const [editRoles, setEditRoles] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);

    const { userDrawer, setUserDrawer } = useContext(DrawerContext)

    const { updateUserData, setUpdateUserData } = useContext(GlobalVariableContext)
    const location = useLocation();

    const verifyToken = new URLSearchParams(location.search).get('verificationToken');

    // const { data: rolesData } = useFetch(`${process.env.REACT_APP_API}/users/roles`, {}, []);
    const { data: userData, isLoading }: any = useFetch(`${process.env.REACT_APP_API}/users/user/${userDrawer}`, {}, []);

    return (
        <>
            {userData && (
                <DrawerLZ
                    title={<div className="text-ellipsis" style={{ width: '500px' }}>{`Usuario: ${userData?.email}`}</div>}
                    onClose={() => {
                        setUserDrawer(null);
                    }}
                    placement='right'
                    visible={userDrawer}
                    className="text-ellipsis"
                >
                    <div style={{ padding: '10px 30px' }}>
                        <Card style={{ borderRadius: '25px' }} size='small'>
                            <Row gutter={[16, 16]}>
                                <Col span={12}>
                                    <div>
                                        <span className="label-item-view">Nombre</span>
                                        <div className="item-view-product">{`${userData?.name}` || 'No disponible'}</div>
                                    </div>
                                </Col>
                                <Col span={12}>
                                    <span className="label-item-view">Cargo</span>
                                    <div className="item-view-product">{userData?.summary}</div>
                                </Col>
                            </Row>
                            <Row gutter={[16, 16]}>
                                <Col span={12}>
                                    <div>
                                        <span className="label-item-view">Email</span>
                                        <div className="item-view-product text-ellipsis">{userData?.email || 'No disponible'}</div>
                                    </div>
                                </Col>
                                <Col span={12}>
                                    <div>
                                        <span className="label-item-view">Teléfono</span>
                                        <div className="item-view-product text-ellipsis">{userData?.phone || 'No disponible'}</div>
                                    </div>
                                </Col>                                
                            </Row>
                            <Row gutter={[16, 16]}>
                                <Col span={12}>

                                    <span className="label-item-view">Roles</span>

                                    <span onClick={() => {
                                        setEditRoles(true)
                                        setSelectedUser(userData)
                                    }} className='modify-icon'><EditOutlined />
                                    </span>

                                    {userData.roles && userData.roles.map((role: any) => <div className="item-view-product">{role}</div>)}

                                </Col>

                                <Col span={12}>
                                    <span className="label-item-view">Estado</span>
                                    <div className="item-view-product">{userData?.summary}</div>
                                </Col>

                            </Row>
                           
                        </Card>
                        {
                            userData?.modules && (
                                <>
                                    <h2>Módulos</h2>
                                    <UserModule userId={userDrawer} moduleId={'ai'} moduleInfo={userData.modules.ai} onChange={setUpdateUserData}/>
                                    <UserModule userId={userDrawer} moduleId={'web'} moduleInfo={userData.modules.web} onChange={setUpdateUserData}/>                                    
                                </>
                            )
                        }
                    </div>
                </DrawerLZ >
            )}            
            {
                editRoles && (
                    <UserEditRoles
                        visible={editRoles}
                        rolesData={[]}
                        onClose={() => { setEditRoles(false) }}
                        roleSelected={selectedUser}
                        setUpdateUserData={setUpdateUserData}
                    />
                )
            }
        </>
    )
}
