import { CheckOutlined, CloseOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Card, Comment, Divider, Form, Input, Tag, message } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "../ReviewsAndFaQ.css"
import questions from '../../../../../assets/faq-empty-component.png'
import { STATUS } from '../../../../../common/constants/statusFAQAndReviews';
import { authFetch } from '../../../../../common/request/authFetch';
import { getProjectStorage } from '../../../../../store/storageContext';
import QuestionAnswer from '../../../../components/model/reviews/questions/QuestionAnswer';
import { authRequest } from '../../../../../common/request/authRequest';
import { useAuth } from '../../../../../store/AuthContext/AuthContext';
import ReviewComment from '../Reviews/ReviewComment';
import { EmojiPickerCustom } from '../../../../components/design/EmojiPicker/EmojiPickerCustom';
import { useIsAuthorized } from '../../../common/roles/Roles';

export default function QuestionDetails({ questionId, drawer = false, onChange }: any) {

    const { t } = useTranslation();
    const { groupAccount } = useAuth();
    const [status, setStatus] = useState("");
    const [replyForm] = Form.useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [updateData, setUpadateData] = useState(false);
    const [question, setQuestion] = useState<any>(null);
    const isAuthorized = useIsAuthorized();

    useEffect(() => {

        if (questionId) {

            setIsLoading(true);
            setStatus('')
            replyForm.resetFields();

            authRequest(
                `${process.env.REACT_APP_API}/projects/` + getProjectStorage() + '/' + groupAccount + `/${questionId}`,
                { method: 'GET' }
            )
                .then((question) => {
                    question && setQuestion(question);
                })
                .catch(() => {
                    message.error(t('view.errors.loadLocation'))
                });

            setIsLoading(false);
        }
    }, [questionId, updateData]);

    const submitReply = async ({ comment }: { comment: string }): Promise<void> => {

        const data = {
            comment,
            questionId: question.name
        };
        await authFetch(
            `${process.env.REACT_APP_API}/projects/` + getProjectStorage() + '/questions/reply',
            { method: 'PUT', data: data }
        )
            .then((res) => {
                if (res) {
                    message.success(t('view.forms.modal.reviews.reply.success', { name: question.author.displayName }), 3);
                    setStatus('');
                    setUpadateData(!updateData);
                    onChange();
                } else {
                    message.error(t('view.forms.modal.reviews.reply.error', { name: question.author.displayName }), 3);
                }
            });
    };


    const [showPicker, setShowPicker] = useState(false);

    const [cursorIndex, setCursorIndex] = useState(0);

    const handleFocus = (event:any) => {
        setShowPicker(false);        
    }

    const handleBlur = (event:any) => {
        setCursorIndex(event.target.selectionStart);
    };

    const handleEmojiClick = (emoji:any) => {    

        const textArea = replyForm.getFieldValue('comment');
        const textAreaWithEmoji = 
                textArea.slice(0, cursorIndex) +
                emoji.native +
                textArea.slice(cursorIndex);
        replyForm.setFieldsValue({ comment: textAreaWithEmoji });
    };    


    return (
        <div className={`${!drawer && 'review-faq-container'}`}>
            {
                question ? (
                    <Card
                        className='card-layout'
                        size='small'
                        title={
                            <div>
                                <span>{question.title}</span>
                                {question.addressLine ? (
                                    <p style={{ margin: '0px', fontSize: '14px', fontWeight: 600 }}>
                                        {question.addressLine}, {question.locality}
                                    </p>
                                ) : (
                                    <p style={{ margin: '0px', color: 'grey', fontStyle: 'italic', fontSize: '14px' }}>
                                        Sin ubicación
                                    </p>
                                )}
                            </div>}
                    >
                        <div style={{ padding: '0px 20px' }}>
                            <Comment
                                author={<div style={{ color: 'black', fontSize: '14px' }}>{question.author.type === 'MERCHANT' ? 'Propietario' : question.author.displayName}</div>}
                                avatar={question.author.type === 'MERCHANT' ? <Avatar style={{ backgroundColor: '#87d068', marginRight: '10px' }} icon={<UserOutlined />} /> : question.author.profilePhotoUri}
                                content={
                                    !!question.text && <ReviewComment comment={question.text} />
                                }
                            ></Comment>
                            { isAuthorized(['ROLE_ADMIN', 'ROLE_AGENT', 'ROLE_CLIENT', 'ROLE_GUEST_REVIEWS_EDIT']) && <a className='review-btn' onClick={() => setStatus('Replying')}>
                                <span className='material-symbols-outlined review-btn-icon'>reply</span>
                                {t('dictionary.reply')}
                            </a>
                            }
                        </div>
                        <Divider orientation="left"><small>Respuestas Destacadas</small></Divider>
                        {status === STATUS.REPLYING &&
                            <div style={{ padding: '5px 25px 25px 25px' }}>
                                <small style={{ color: 'grey' }}>{t('dictionary.ownerReply')}</small>
                                <Form layout='vertical'
                                    form={replyForm}
                                    onFinish={submitReply}
                                >
                                    <Form.Item name='comment'>
                                        <Input.TextArea
                                            style={{ width: '100%' }}
                                            className='input-field'
                                            onFocus={handleFocus}
                                            onBlur={handleBlur}
                                        />
                                    </Form.Item>
                                    <a className='review-btn'
                                        onClick={() => { replyForm.resetFields(); setStatus(''); }}
                                    >
                                        <CloseOutlined /> {t('dictionary.cancel')}
                                    </a>
                                    <a className='review-btn'
                                        onClick={() => { replyForm.submit(); setStatus(''); }}
                                    >
                                        <CheckOutlined /> {t('dictionary.accept')}
                                    </a>
                                    <EmojiPickerCustom  handleEmojiClick={handleEmojiClick} showPicker={showPicker} setShowPicker={setShowPicker}/>

                                </Form>
                            </div>
                        }
                        {
                            question.topAnswers?.length === 0 && status !== STATUS.REPLYING ?
                            
                                <Card   
                                    className='card-layout'
                                    bodyStyle={{ backgroundColor: 'rgba(255, 249, 237, 1)', borderRadius: '10px' }}
                                    size='small'
                                    style={{ width: '90%', marginLeft: '5%', border: 'solid .5px lightgrey' }}
                                >No hay respuestas
                                </Card>
                            :<>
                                {question.topAnswers?.map((answer: any) => (
                                    <QuestionAnswer 
                                        questionId={question.name}
                                        answer={answer}                                     
                                        onChange={() => {
                                            onChange();                   
                                            setUpadateData(!updateData);
                                        }}/>
                                ))}
                            </> 
                        }
                    </Card>
                ) : (
                    <div className="no-row-select">
                        <img src={questions} />
                        <h1 >No tienes ninguna pregunta seleccionada</h1>
                        <span>Selecciona alguna para leer sus respuestas</span>
                    </div>
                )
            }
        </div>
    );
}