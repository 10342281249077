import { Children } from 'react';
import './tableLZM.sass';
import { Table } from "ant-table-extensions";

const TableLZM = ({size, scroll, components, rowClassName,exportable = false, title, showHeader, columns, dataSource, rowKey, loading, pagination, locale, onChange, expandable, rowSelection, onRow}: any) => {

  return (
    <Table 
      className='tableLZM'
      components={components}
      rowClassName = {rowClassName}
      columns={columns}
      dataSource={dataSource}
      rowSelection={rowSelection}
      rowKey={rowKey}
      loading={loading}
      pagination={pagination}
      locale={locale}
      onChange={onChange}
      expandable={expandable}
      title={title}
      showHeader={showHeader}
      size={size}
      onRow = {onRow}
      exportable = {exportable}
      scroll={scroll}

      >
      
      {Children}
    </Table>
  )
}


export default TableLZM