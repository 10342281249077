import { Col, Row, Tag } from "antd";

export const ServiceItemsView = (props: { serviceItems: any }) => {

    return (
        <div>
           {
                props.serviceItems && props.serviceItems.serviceItems.map(
                    (serviceItem: any, i: number) => (
                        <Row style={{ margin: "1em 0", color: "#666"}} key={i}>
                            <Col style={{width:'50%'}}>
                                {serviceItem.name}                                
                            </Col>
                            <Col style={{width:'10%'}}>
                                {serviceItem.free && <Tag color='blue'>Free</Tag>
                                }
                            </Col>
                            <Col style={{width:'40%'}}>
                                {
                                    serviceItem.categories && serviceItem.categories.map(
                                        (cat: any, i: number) => (
                                            <Tag style={{color:"grey"}}>{cat}</Tag>
                                    ))
                                }
                            </Col>
                        </Row>
                    ))
                }            
        </div>
    );
}