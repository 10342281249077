import { Divider, Form, Input, message, Modal, Radio, Select, Space, Switch } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { authRequest } from '../../../../common/request/authRequest';
import { authFetch } from '../../../../common/request/authFetch';
import { useAuth } from '../../../../store/AuthContext/AuthContext';

export const AddModalTemplatesModal = ({
	onFinish,
}: any) => {

	const { groupAccount } = useAuth();
	const { t } = useTranslation();
	const [frequency, setFrequency] = useState();
	const [type, setType] = useState();
	const [form] = Form.useForm();
		
	const onFormFinish = async (values: any) => {
		const data = {
			...values,			
		};
		await authFetch(`${process.env.REACT_APP_API}/${groupAccount}/reports/config`, { method: 'POST', data: data });
		onFinish();
	};

  return (
		<Modal visible={true} onOk={() => form.submit()} onCancel={onFinish} afterClose={form.resetFields}>
			<h2>Nuevo Informe periódico</h2>			
			
			<Form 
				layout = 'horizontal' 
				form={form} 
				onFinish={onFormFinish}
				labelCol={{ span: 8 }}
				wrapperCol={{ span: 12 }}
				initialValues={{
					conversion:true,
					info:true,					
					reviews:true,
					content:true,
					frequency:'MONTHLY',
					type:'BASIC'
				}}>				
				
				<br/>				
				<Form.Item rules={[{ required: true }]} name="name" label={'Nombre Informe'}>
					<Input size='small'/>
				</Form.Item>
				
				<Divider orientation='right' style={{color:'steelblue'}}><small>FREQUENCIA</small></Divider>	
				
				<Form.Item rules={[{ required: true }]} label={'Frequencia'} name='frequency'>
					<Radio.Group value={frequency} onChange={(obj: any) => setFrequency(obj.target.value)}>
						<Space direction="vertical">							
							<Radio value='MONTHLY'>Mensual</Radio>
						</Space>
					</Radio.Group>
				</Form.Item>
				<Divider orientation='right' style={{color:'steelblue'}}><small>TIPO</small></Divider>	
				
				<Form.Item rules={[{ required: true }]} label={'Tipo'} name='type'>
					<Radio.Group value={type} onChange={(obj: any) => setType(obj.target.value)}>
						<Space direction="vertical">
							<Radio value='BASIC'>Básico</Radio>
							<Radio value='COMPLETE'>Completo <small style={{color:'steelblue'}}>+ Info de locales</small></Radio>
						</Space>
					</Radio.Group>
				</Form.Item>
				<Divider orientation='right' style={{color:'steelblue'}}><small>BLOQUES VISIBLES</small></Divider>				
				<Form.Item label={'Conversión'} name='conversion'>
					<Switch size='small' defaultChecked={true}/>
				</Form.Item>				
				<Form.Item label={'Información'} name='info'>
					<Switch size='small' defaultChecked={true}/>
				</Form.Item>				
				<Form.Item label={'Reseñas'} name='reviews'>
					<Switch size='small' defaultChecked={true}/>
				</Form.Item>				
				<Form.Item label={'Publicaciones'} name='content'>
					<Switch size='small' defaultChecked={true}/>
				</Form.Item>	
			</Form>
		</Modal>
	)
}
