import { Swiper, SwiperSlide } from "swiper/react";
import { Image, Modal } from 'antd';

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./swiper.css";

import { Pagination, Navigation } from "swiper";
import { Key, useContext, useState } from "react";
import { ButtonLZ } from "../Buttons/ButtonLZ";
import { DeleteOutlined, LeftOutlined, RightOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { authFetch } from "../../../../common/request/authFetch";
import { getProjectStorage } from "../../../../store/storageContext";
import { GlobalVariableContext } from "../../../../store/GlobalVariableContext/GlobalVariableContext";
import { Link } from "react-router-dom";
import { useIsAuthorized } from "../../../pages/common/roles/Roles";


export default function SwiperCarousel({ onClick, mediaItems }: any) {

  const { t } = useTranslation();
  const isAuthorized = useIsAuthorized();
  const {updateGlobalData, setUpdateGlobalData } = useContext(GlobalVariableContext)

  const [image, setImage] = useState<any[]>(mediaItems);

  const [prevEl, setPrevEl] = useState<HTMLElement | null>(null)
  const [nextEl, setNextEl] = useState<HTMLElement | null>(null)

  const deleteMedia = async (name:any) => {
    await authFetch(
      `${process.env.REACT_APP_API}/projects/` + getProjectStorage() + '/' + name,
      { method: 'DELETE' }
    ).then(()=>{
      const updatedMediaItems = mediaItems.filter((item: { name: any; }) => item.name !== name);
      setImage(updatedMediaItems);
      
      // setUpdateGlobalData(!updateGlobalData)
    })
  }

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        {isAuthorized(['ROLE_ADMIN', 'ROLE_AGENT', 'ROLE_CLIENT', 'ROLE_GUEST_INFO_EDIT']) && <ButtonLZ size='small' onClick={onClick}>{t('locals.drawer.tabs.photo.add')}</ButtonLZ>}
      </div>
      <div style={{ height: '350px', position: 'relative' }}>
        <Swiper
          pagination={{
            type: "fraction",
          }}
          // navigation={true}
          navigation={{ prevEl, nextEl }}
          modules={[Pagination, Navigation]}
        >
          {
            image.map((mediaItem: any, index: Key | null | undefined) => {
              return (
                <SwiperSlide style={{display:'flex', flexDirection:'column'}}>
                 
                  <Image
                    key={index}
                    src={mediaItem.googleUrl}
                    className="swiper-slide-img"
                  />
                   <div style={{display:'flex', justifyContent:'flex-end', width:'450px', marginBottom:'5px'}}>
                   { isAuthorized(['ROLE_ADMIN', 'ROLE_AGENT', 'ROLE_CLIENT', 'ROLE_GUEST_INFO_EDIT']) &&
                   <a
                    onClick={
                      () => {
                        Modal.confirm({
                          title: t('locals.drawer.tabs.photo.delete.title'),
                          okText: t('dictionary.confirm'),
                          cancelText: t('dictionary.cancel'),
                          onOk: () => deleteMedia(mediaItem.name),
                        });
                      }
                    }>
                    <small>{t('dictionary.delete')}</small>
                  </a>}
                  </div>
                </SwiperSlide>
              )
            })
          }
        </Swiper>
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '0',
            fontSize: '50px',
            cursor: 'pointer',
            transform: 'translateY(-50%)',
            zIndex: 999
          }}
          ref={(node) => setPrevEl(node)}
        >
          <LeftOutlined />
        </div>
        <div
          style={{
            position: 'absolute',
            top: '50%',
            right: '0',
            fontSize: '50px',
            cursor: 'pointer',
            transform: 'translateY(-50%)',
            zIndex: 999

          }}
          ref={(node) => setNextEl(node)}
        >
          <RightOutlined />
        </div>
      </div>
    </>
  );
}
