import { InfoCircleOutlined, LoadingOutlined } from "@ant-design/icons"
import { useTranslation } from "react-i18next";
import { useFetch } from "../../../../hooks/useFetch/useFetch";
import { getProjectStorage } from "../../../../store/storageContext";
import { Alert, Tooltip } from "antd";

export const Synchronized = ({ service }: any) => {

	const { t } = useTranslation();


	const { data, isLoading }: any = useFetch(`${process.env.REACT_APP_API}/projects/${getProjectStorage()}/synchronized?services=${service}`, {}, []);


	return (
		<div style={{ width: '100%' }}>
			<small>
				<i style={{ position: "absolute", top: '10px', right: 0, marginRight: "3em", color: 'grey' }}>
					{data !== null ?
						(data?.isSync ?
							(
								<>
									<LoadingOutlined />
									{t('dictionary.syncing')}
								</>
							)
							:
							<Tooltip 
							placement="leftTop"
							title={data?.minutesSinceSync >= 0 && `${t('dictionary.synchronized')} ${t('dictionary.minutesAgo', { minutes: data?.minutesSinceSync }).toLowerCase()}`
							}>
								<span style={{ fontSize: '18px', cursor:'default' }} className="material-symbols-outlined">
									timer
								</span>
							</Tooltip>

						) : <Alert
							style={{ marginTop: '12px', borderRadius: '10px' }}
							type='error'
							message={<span><Tooltip title='Los datos que se muestran en esta pantalla aún no se han sincronizado completamente, este proceso no debería tardar más de una hora'><InfoCircleOutlined /></Tooltip> Pendiente sincronización</span>}
						/>
					}
				</i>
			</small>
		</div>
	)
}