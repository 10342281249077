import { BankOutlined, CalendarOutlined, CameraOutlined, EditOutlined, LoadingOutlined, PercentageOutlined } from '@ant-design/icons';
import { Drawer, Spin, Timeline, Tooltip } from 'antd';
import { useState,  useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useFetch } from '../../../../../hooks/useFetch/useFetch';
import { getProjectStorage } from '../../../../../store/storageContext';
import Operations from './Operations';
import { DrawerContext } from '../../../../../store/DrawerContext/DrawerContext';

// const iconMap: any = {
//   'PHOTO_LOGO': <CameraOutlined style={{color:'blue'}}/>,
//   'PHOTO_COVER': <CameraOutlined style={{color:'blue'}} />,
//   'STANDARD': <BankOutlined style={{color:'grey'}} />,
//   'OFFER': <PercentageOutlined style={{color:'green'}} />,
//   'EVENT': <CalendarOutlined style={{color:'yellow'}} />,
//   'EDIT_LOGO': <EditOutlined style={{color:'red'}} />,
//   'EDIT_COVER': <EditOutlined style={{color:'red'}} />,
// };

const iconMap: any = {
  'PHOTO_LOGO': <CameraOutlined />,
  'PHOTO_COVER': <CameraOutlined  />,
  'STANDARD': <BankOutlined  />,
  'OFFER': <PercentageOutlined  />,
  'EVENT': <CalendarOutlined  />,
  'EDIT_LOGO': <EditOutlined  />,
  'EDIT_COVER': <EditOutlined  />,
  'EDIT_WEB':<EditOutlined  />,
  'EDIT_CATEGORIES':<EditOutlined  />
};


const Logs = () => {

  const { t } = useTranslation();
  const [updateData, setUpdateData] = useState(false);
  const [operationsVisible, setOperationsVisible] = useState(false);
  const [job, setJob] = useState({});
  const [jobsData, setJobsData] = useState([]);

  const { logsDrawer, setLogsDrawer } = useContext(DrawerContext);


  const {isLoading } = useFetch(`${process.env.REACT_APP_API}/projects/` + getProjectStorage() + '/jobs', {}, [updateData], (data) => {
    setJobsData(data.jobs);    
    // setTimeout(() => {
    //   setUpdateData(!updateData);
    // }, 5000);		
  });    

  return (
    <Drawer title={<div>{t('dictionary.bulkLogs')}<br/><small style={{color:'grey'}}>{getProjectStorage()}</small></div>}  placement="right" width={'40%'} onClose={()=>setLogsDrawer(false)} visible={logsDrawer}>
     { 
      isLoading ? <div style={{display:'flex', justifyContent:'center', alignItems:'center', height: '100%'}}><Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} /></div> : 
      <div style={{padding:'20px'}}>
      <Timeline mode={'left'}>
        {
          jobsData.map((row : any) => {
            const icon = iconMap[row.statusAction];
            return (
              <Timeline.Item 
              color={row.status==='FINISHED_KO' || row.status==='NOT_FINISHED'?'red':'green'} 
              dot={icon}
               >
              <div>
                <p style={{color:'grey', margin:0}}>{new Date(row.created+'Z').toLocaleString()}</p>
                <p><Tooltip title={row.operator}><b>{row.operatorName}</b></Tooltip> {row.action} en <a onClick={() => {setOperationsVisible(true); setJob(row)}}>{row.operations} {t('dictionary.shops').toLowerCase()}</a> </p></div>
              </Timeline.Item>
              )
            })
          }                    
    </Timeline>
    </div>
    }
    
     {
      operationsVisible && (
        <Operations job={job} setVisible={setOperationsVisible}/>
      )}
  </Drawer>
	)
}

export default Logs;