import { LikeOutlined, SearchOutlined } from '@ant-design/icons';
import { Badge, Input } from 'antd';
import { useTranslation } from 'react-i18next';

import { FilterDropdownProps } from 'antd/lib/table/interface';
import { useEffect, useState } from 'react';
import "../../../../pages/menuReviews/3_1_Reviews/ReviewsAndFaQ.css"
import TableLZM from '../../../design/table/TableLZM';
import moment from 'moment';

const Questions = ({ questionsData, handleQuestionClick, width = '56%' }: any) => {

    const { t } = useTranslation();
    const [filteredRows, setFilteredRows] = useState(questionsData?.length);

    useEffect(()=>{ 
        setFilteredRows(questionsData?.length)

    }, [questionsData])

    const columns: any = [

        {
            title: '',
            key: 'totalAnswerCount',
            dataIndex: 'totalAnswerCount',
            align: 'center',
            width:'10%',
            sorter: (a: any, b: any) => b.totalAnswerCount - a.totalAnswerCount,
            render: (text: string, record: any) => (
                <>
                    <Badge count={record.totalAnswerCount} color={record.totalAnswerCount === 0 ? 'grey' : 'green'} showZero overflowCount={999}>
                        <span className="material-symbols-outlined">
                            chat
                        </span>
                    </Badge>
                </>
            )
        },
        {
            title: 'Preguntas',
            key: 'text',
            dataIndex: 'text',
            width: '45%',
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
            }: FilterDropdownProps) => {
                return (
                    <Input
                        autoFocus
                        placeholder="Nombre o Texto"
                        value={selectedKeys[0]}
                        onChange={(e: any) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    ></Input>
                );
            },
            filterIcon: () => {
                return <SearchOutlined />;
            },
            onFilter: (value: string, record: any) => {
                const searchWords = value.toLowerCase().split(' ');
                const searchText = record.text.originalText ? record.text.originalText : record.text.text
                return searchWords.every((word) => searchText?.toLowerCase().includes(word)) || record?.author?.displayName?.toLowerCase().includes(value.toLowerCase());
            },  
            sorter: (a: any, b: any) => moment(b.createdTime) > moment(a.createdTime),
            render: (text: string, record: any) => {
                const maxLength = 100;
                const textToShow = record.text.originalText ? record.text.originalText : record.text.text;
                const truncatedText = textToShow.length > maxLength ? textToShow.slice(0, maxLength) + '...' : textToShow;

                return (
                    <>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '10px', marginBottom: '10px' }}>
                            <img src={record.author.profilePhotoUri} style={{ width: '30px' }} />
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                                <div>{record.author.displayName}</div>
                                <div style={{ fontSize: '10px', color: 'gray' }}>
                                    {moment(record.createdTime).format('DD MMM YYYY')}
                                </div>
                            </div>
                        </div>
                        <p style={{ color: 'grey' }}>{truncatedText}</p>
                    </>
                );
            }
        },
        {
            title: 'Dirección',
            key: 'locality',
            dataIndex: 'locality',
            align: 'center',
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
            }: FilterDropdownProps) => {
                return (
                    <Input
                        autoFocus
                        placeholder="Provincia, Ciudad, CP o Dirección"
                        value={selectedKeys[0]}
                        onChange={(e: any) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    ></Input>
                );
            },
            filterIcon: () => {
                return <SearchOutlined />;
            },

            onFilter: (value: any, record: any) => {
                const locality = record.locality ? record.locality.toLowerCase() : '';
                const addressLine = record.addressLine ? record.addressLine.toLowerCase() : '';
                const postalCode = record.postalCode ? record.postalCode.toLowerCase() : '';

                return locality.includes(value.toLowerCase()) ||
                    addressLine.includes(value.toLowerCase()) ||
                    postalCode.includes(value.toLowerCase());
            },
            render: (text: string, record: any) => (
                <div>
                    {record.title}
                    {record.addressLine ? <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {<span>{record.locality}</span>}
                        {<small>{record.addressLine}</small>}</div>
                        : <p style={{ color: 'grey', fontStyle: 'italic' }}>Sin ubicación</p>
                    }

                </div>
            )
        },
        {
            title: 'Likes',
            key: 'upvoteCount',
            dataIndex: 'upvoteCount',
            align: 'center',
            sorter: (a: any, b: any) => b.upvoteCount - a.upvoteCount,
            render: (text: string, record: any) => (
                <div style={{ display: 'flex', gap: '5px', justifyContent: 'center', alignItems: 'center' }} >
                    <span>{record.upvoteCount}</span>
                    <LikeOutlined />
                </div>
            )
        }
    ]

    const [selectedRowKey, setSelectedRowKey] = useState(null);

    const onSelectRow = (record: { name: any; }) => {
        handleQuestionClick(record)
        const rowKey = record.name;
        setSelectedRowKey(rowKey);
    };

    const rowClassName = (record: { name: null; }) => {
        return record.name === selectedRowKey ? 'selected-row' : '';
    };

    return (
        <div style={{ width: width }}>
            
            <div style={{ color: 'grey', font: '26px' }}>{`${filteredRows} de ${questionsData?.length}`}</div>

            <TableLZM size='small'
                rowClassName={rowClassName}
                onRow={(record: { name: any; }) => ({
                    onClick: () => onSelectRow(record),
                    style: { cursor: 'pointer' },
                })} columns={columns} dataSource={questionsData}
                rowKey="name"
                scroll={{ y: 400 }}
                onChange={(pagination: any, filters: any, sorter: any, extra: any) => {
                    setFilteredRows(extra?.currentDataSource?.length);
                    if(filters.text == null && filters.locality == null && filters.reviewReplyComment == null && filters.starRating == null){
                        setFilteredRows(questionsData.length)
                    }
                }}
            />
        </div>
    )
}

export default Questions;