import { authRequest } from './authRequest';

export const authFetch = (url: string, config: any, callback?: (data: any) => void) => {
  
  return authRequest(url, config)
  .then(() => {
      return Promise.resolve(true);      
  }).catch(() => {        
      return Promise.resolve(false);
    }
  );
}