import { Button, Form, Modal, message } from "antd";
import InputCategories from "../InputCategories";
import { useTranslation } from "react-i18next";
import { authFetch } from "../../../../../../../common/request/authFetch";
import { getProjectStorage } from "../../../../../../../store/storageContext";
import { useAuth } from "../../../../../../../store/AuthContext/AuthContext";
import { useContext } from "react";
import { DrawerContext } from "../../../../../../../store/DrawerContext/DrawerContext";

export const CategoriesMassiveEdit = (props : { locationsIds: string[], handleCancel?: () => any, handleSuccess?: (data: {}) => any }) => {

    const { t } = useTranslation();
    const { groupAccount } = useAuth();
    const [categoryForm] = Form.useForm();
    const categoriesField = 'CATEGORIES';
    const { setLogsDrawer } = useContext(DrawerContext)

    const submitCategory = async (values: any) => {
 
        const data = {
          field: 'CATEGORIES',
          categories: {
            primaryCategory: values.primaryCategory,
            
            additionalCategories:  values.additionalCategories,
          },
          locationsNames: props.locationsIds
        };
            
        await authFetch(`${process.env.REACT_APP_API}/projects/` + getProjectStorage() + '/' + groupAccount + '/locations', { method: 'PATCH', data: data })
        .then((res) => {
            if (res) {
                message.loading(t('view.forms.modal.bulk.triggered'), 5);   

                setTimeout(() => {
                    setLogsDrawer(true);
                  }, 500)         
            } else {
                message.error(t('view.forms.modal.bulk.error'), 5);
            }
        });    
    }

    return (
        <Form layout='vertical' form={categoryForm} onFinish={(values) => submitCategory(values)} >
           <Form.Item name='primaryCategory'
                style={{ width: '80%' }} >
                <InputCategories id={categoriesField}/>
            </Form.Item>
            <Form.List name='additionalCategories'>

                {(fields, { add, remove }) => {
                    return (
                        <>
                            {fields.map((field, index) => (
                                <div key={field.key}>
                                    {<div style={{ display: 'flex' }}>
                                        <Form.Item name={[index]}
                                            style={{ width: '80%' }}>
                                            <InputCategories id={'CATEGORIES'}
                                                onChangeSelected={(selectedValue: any) => {
                                                    categoryForm.setFieldsValue({
                                                        [`additionalCategories[${index}]`]: selectedValue,
                                                    });
                                                }} />
                                        </Form.Item>
                                        {fields.length > 0 && <button
                                            style={{ width: '20%', height: '32px', backgroundColor: "transparent", border: "none", fontWeight: 600, padding: "0 1em", cursor: "pointer" }}
                                            onClick={() => remove(field.name)}>
                                            X
                                        </button>}
                                    </div>}
                                </div>
                            ))}
                            {(fields.length < 9) &&
                                <button type='button' style={{ paddingBottom: "1em", border: "none", backgroundColor: "transparent", color: "#2781DD", fontWeight: 600, cursor: "pointer" }} onClick={() => add()}>
                                    {t('view.forms.fields.category.add')}
                                </button>}
                        </>);
                }}
            </Form.List>

            <Form.Item shouldUpdate style={{ marginTop: '15px' }}>
                {() => {
                    return (
                        <><Button
                            // disabled={!!categoryForm.getFieldsError().filter(({ errors }: any) => errors.length).length
                            //   || enabled !== fieldId}
                            onClick={() => {
                                Modal.confirm({
                                    title:  t('view.forms.modal.bulk.title'),
                                    content: t('view.forms.modal.bulk.content', { field: 'categoría' }),
                                    okText: t('dictionary.confirm'),
                                    cancelText: t('dictionary.cancel'),
                                    onOk: categoryForm.submit

                                });
                            }}
                            type="primary">
                            {t('dictionary.save')}
                        </Button>
                            <Button
                                style={{ background: 'white', color: 'dodgerblue', marginLeft: '20px' }}
                                onClick={() => {
                                    categoryForm.resetFields();
                                    props.handleCancel!();
                                }}
                                type="primary">
                                {t('dictionary.cancel')}
                            </Button>
                        </>
                    );
                }}
            </Form.Item>
               
        </Form>
    )
}