import { PlusOutlined } from '@ant-design/icons';
import { Badge, Button, Card, Table, Tag } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFetch } from '../../../../hooks/useFetch/useFetch';
import ProjectsAddModal from './ModalAddProject/ProjectsAddModal';
import './Users.scss';
import '../../pages.scss'
import TableLZM from '../../../components/design/table/TableLZM';
import { ButtonLZ } from '../../../components/design/Buttons/ButtonLZ';


const Projects = () => {

  const { t } = useTranslation();
  const [addUserVisible, setAddUserVisible] = useState(false);
  const [updateData, setUpdateData] = useState(false);
	const [projects, setProjects] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useFetch(`${process.env.REACT_APP_API}/projects`, {}, [updateData], (data) => {

		setProjects(data);
    setIsLoading(isLoading);
	});

  const columns = [
    {
		title: t('dictionary.name'),
		key: 'name',
		dataIndex: 'name'	
    },{
      title: t('dictionary.company'),
      key: 'company',
      dataIndex: 'company'	
    },{
      title: t('dictionary.creator'),
      key: 'creator',
      dataIndex: 'creator'	
    }
	]

  return (
    <>
      <Card className='main-card-content' >
        <h1 className='title-card'>{t('dictionary.businessManagement')}</h1>
        <div className="buttonWrapper" >
          <ButtonLZ color='yellow' onClick={() => setAddUserVisible(true)} type='primary'>
            <PlusOutlined/> {t('dictionary.newBusiness')}
          </ButtonLZ>
        </div>
        <TableLZM columns={columns} dataSource={projects} rowKey={'name'} loading={isLoading} />
        <ProjectsAddModal
          visible={addUserVisible}
          onFinish={() => {
            setAddUserVisible(false);
            setUpdateData(!updateData);
          }}
        />
      </Card>
    </>
	)
}

export default Projects;