import { Button, Divider, Form, message, Modal } from "antd";
import { useContext } from "react";

import { useTranslation } from 'react-i18next';
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { GlobalVariableContext } from "../../../../../../store/GlobalVariableContext/GlobalVariableContext";
import { useAuth } from "../../../../../../store/AuthContext/AuthContext";
import { getProjectStorage } from "../../../../../../store/storageContext";
import { authFetch } from "../../../../../../common/request/authFetch";
import { TimeRangeItem } from "../../../business/forms/TimeRangeItem";

export const RegularWeekScheduleEditLJ = (props: {location: any, edit: any, setEdit: any}) => {

	const { t } = useTranslation();
	const [shiftForm] = Form.useForm()
	const {updateGlobalData, setUpdateGlobalData } = useContext(GlobalVariableContext)
	const { groupAccount } = useAuth();
	dayjs.extend(customParseFormat);

	var ranges = props.location.regularHours;
	var isOpen = props.location.regularHours.map((rh: string | any[]) => rh.length > 0);	

	const format = 'HH:mm';

	const rangesInit = ranges.map(
		(periods: any[]) => (periods.map((p) => ([ dayjs(`${p.openTimeHour}:${p.openTimeMinute}`, format), dayjs(`${p.closeTimeHour}:${p.closeTimeMinute}`, format)])))
		);		
		
	const submitEdit = async (values: any) => {

		function getPeriods(values: any, day: string, field: string) {

			var ranges = values[field + '_Ranges'] || [];
			
			var periods = ranges && ranges.map((r: any) => ({ 
				openDayName: day,
				openTimeHour: r[0].hour(),
				openTimeMinute: r[0].minute(),
				closeDayName: day,
				closeTimeHour: r[1].hour(),
				closeTimeMinute: r[1].minute()
			}));
			
			return periods;
		}

		const periods: any[] = [];
		
		periods.push(getPeriods(values, 'MONDAY', 'MONDAY_TO_THURSDAY'));
		periods.push(getPeriods(values, 'TUESDAY', 'MONDAY_TO_THURSDAY'));
		periods.push(getPeriods(values, 'WEDNESDAY', 'MONDAY_TO_THURSDAY'));
		periods.push(getPeriods(values, 'THURSDAY', 'MONDAY_TO_THURSDAY'));
		periods.push(getPeriods(values, 'FRIDAY', 'FRIDAY'));
		periods.push(getPeriods(values, 'SATURDAY', 'SATURDAY'));
		periods.push(getPeriods(values, 'SUNDAY', 'SUNDAY'));		
		  
		const data = {
			field: 'REGULAR_HOURS',
			regularHours: periods
		};

		try {
			await authFetch(`${process.env.REACT_APP_API}/projects/${getProjectStorage()}/${groupAccount}/${props.location?.name}`, { method: 'PATCH', data: data })
				.then((res) => {
					if (res) {
						message.success('El horario se ha editado correctamente', 3);
						props.setEdit(false);
						setUpdateGlobalData(!updateGlobalData)

					} else {
						message.error(t('Error inesperado al editar el horario'), 3)
					}
				});
		} catch (error: any) {
			return message.error(error.message)
		}
	};

	return (
		<>
			<Form 
				layout='vertical' 
				form={shiftForm} 
				onFinish={(values) => submitEdit(values)}
				initialValues={{
					MONDAY_TO_THURSDAY_Open: isOpen[0], 					
					FRIDAY_Open: isOpen[4],
					SATURDAY_Open: isOpen[5],
					SUNDAY_Open: isOpen[6],
					MONDAY_TO_THURSDAY_Ranges: rangesInit[0],					
					FRIDAY_Ranges: rangesInit[4],
					SATURDAY_Ranges: rangesInit[5],
					SUNDAY_Ranges: rangesInit[6]					
				}}>						

				<TimeRangeItem ranges={ranges[0]} field='MONDAY_TO_THURSDAY' label={'Lunes a Jueves'}/>				
				<TimeRangeItem ranges={ranges[4]} field='FRIDAY' label={t('dictionary.friday')}/>
				<TimeRangeItem ranges={ranges[5]} field='SATURDAY' label={t('dictionary.saturday')}/>
				<TimeRangeItem ranges={ranges[6]} field='SUNDAY' label={t('dictionary.sunday')}/>

				{props.edit &&
					<Form.Item shouldUpdate style={{ marginTop: '15px' }}>
						{() => {
							return (
								<>
									<Button
										style={{ background: 'var(--primary)', color: 'white', marginLeft: '20px' }}
										onClick={
											() => {
												Modal.confirm({
													title: t('view.forms.modal.single.title'),
													//content: t('view.forms.modal.single.content', { field: t('dictionary.schedule').toLowerCase() }),
													okText: t('dictionary.confirm'),
													cancelText: t('dictionary.cancel'),
													onOk: shiftForm.submit
												});
											}
										}>
									{t('dictionary.save')}
								</Button>
									<Button
										style={{ background: 'white', color: 'dodgerblue', marginLeft: '20px', borderColor: 'dodgerblue' }}
										onClick={() => {
											shiftForm.resetFields();
											props.setEdit(false);
										}}
										type="primary">
										{t('dictionary.cancel')}
									</Button>
								</>
							)
						}}
					</Form.Item>}
			</Form>

		</>
	)
}