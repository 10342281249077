import React, { useState } from 'react';
import CardDetailsReview from '../../../../pages/menuReviews/3_1_Reviews/Reviews/CardDetailsReview';
import Reviews from './Reviews';
import { useFetch } from '../../../../../hooks/useFetch/useFetch';
import { getProjectStorage } from '../../../../../store/storageContext';
import { useAuth } from '../../../../../store/AuthContext/AuthContext';
import moment from 'moment';
import { ForwardOutlined, LeftCircleFilled, RightCircleFilled } from '@ant-design/icons';
import { Button, Spin } from 'antd';


export default function ReviewsContainer() {
  
  const { groupAccount } = useAuth();

  const [selectedReview, setSelectedReview] = useState<any>(null);
  const [refreshReviews, setRefreshReviews] = useState(false);
  const [reviewsData, setReviewsData] = useState();
  const [requestParams, setRequestParams] = useState('');
  const [start, setStart] = useState();
  const [end, setEnd] = useState();
  const [startDisabled, setStartDisabled] = useState();
  const [endDisabled, setEndDisabled] = useState(true);

  const handleReviewClick = (review: React.SetStateAction<null>) => {
    setSelectedReview(review);
  };

  const handlePaginationClick = (isBack: boolean) => {
  
    if (isBack) {
      setRequestParams(`?reference=${start}&back=${isBack}`)
    } else {
      setRequestParams(`?reference=${end}&back=${isBack}`)
    }    
  };

  useFetch(`${process.env.REACT_APP_API}/projects/` + getProjectStorage() + '/' + groupAccount + `/reviews${requestParams}`
		, {}, [refreshReviews],
		(data) => {
			setReviewsData(data.reviews);
      setStart(data.start);
      setEnd(data.end);
      setStartDisabled(data.startDisabled );
      setEndDisabled(data.endDisabled);
		});

  return (
    <>      
      {reviewsData && <div>        
        <Button type='link' onClick={()=>{handlePaginationClick(true)}} disabled={startDisabled}>    
          <LeftCircleFilled/> 								
        </Button>				
        {moment(start).format('l')} - {moment(end).format('l')} 
				<Button type='link' onClick={()=>{handlePaginationClick(false)}} disabled={endDisabled}>    
          <RightCircleFilled/>					
        </Button>				
      </div>}
      <div style={{ position: 'relative', marginTop: '20px' }} >
        <div>
        {reviewsData ? 
          <Reviews
            reviewsData={reviewsData}
            handleReviewClick={handleReviewClick} />
            :
            <Spin/>
        }
        </div>
        <div>
          <CardDetailsReview reviewId={selectedReview?.name ? selectedReview?.name : null} refreshReviews={refreshReviews} setRefreshReviews={setRefreshReviews} />
        </div>
      </div>
    </>
  );
}
