import { Drawer } from "antd";

const DrawerLZ = ({title, visible, onClose, placement, width = 650, height, extra, open, closable, closeIcon, children}: any) => {
    return (
        <Drawer className="drawerSG"
            title={title}
            visible={visible}
            onClose={onClose}
            placement={placement}
            width={width}
            height={height}
            closable={closable}
            closeIcon={closeIcon}
        >
            {children}
        </Drawer>
    )
}

export default DrawerLZ