import React, { createContext, useContext } from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { useAuthProvider } from '../../hooks/useAuthProvider/useAuthProvider';

const authContext = createContext({
	token: null as any,
	project: '' as string,
	setProject: null as any,
	profile: null as any,
	setProfile: null as any,
	isAuth: false,
	logIn: (data: any) => Promise.resolve(),
	logOut: () => {},
	getHeader: () => {},
	groupAccount: null as any,
	setGroupAccount: null as any,
	googleLogIn: (data: any, project: string) => Promise.resolve(false),
	googleLogOut: (project: string) => Promise.resolve(false),
	collapsed: true,
	setCollapsed: null as any
});

const AuthProvider = ({ children }: { children: React.ReactNode }) => {

	const auth: any = useAuthProvider();
	return <GoogleOAuthProvider clientId="786462449753-0096oo7k6vhoa59ba7226td1n8pdhqap.apps.googleusercontent.com">
			<authContext.Provider value={auth}>{children}</authContext.Provider>
		</GoogleOAuthProvider>;
};

const useAuth = () => {
	return useContext(authContext);
}

export {
	AuthProvider,
	useAuth
};