import {  CheckCircleFilled, ExclamationCircleFilled, LineChartOutlined, QuestionCircleOutlined, QuestionOutlined, SearchOutlined, StarFilled, StarOutlined } from '@ant-design/icons';
import { Badge, Button, Card, Input, Progress } from 'antd';
import { useTranslation } from 'react-i18next';
import { useContext, useState } from 'react';
import { useFetch } from '../../../../hooks/useFetch/useFetch';
import { useAuth } from '../../../../store/AuthContext/AuthContext';
import { getProjectStorage } from '../../../../store/storageContext';
import TableLZM from '../../../components/design/table/TableLZM';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import { AddressGroup, Location, NameGroup } from '../../../components/model/business/FieldsGroup';
import ColorRate from '../../../../common/utils/ColorRate';
import { DrawerContext } from '../../../../store/DrawerContext/DrawerContext';
import { SCHEDULEDJOB } from '../../../../common/constants/services';
import { Synchronized } from '../../../components/design/Synchronized/Synchronized';
import { Link, useHistory } from 'react-router-dom';
import { ActionAlert } from '../../common/reports/ActionAlert';
import { BlockReviewsKpis } from '../../menuReports/blocks/BlockReviewsKpis';
import { SpinLZ } from '../../../components/design/Spinner/SpinLZ';
import { ColorStar } from '../../../../common/utils/ColorStar';

export const LocationsReviews = () => {

	const { setReviewsDrawer, setQuestionsDrawer } = useContext(DrawerContext);
	const { setLocationDrawer } = useContext(DrawerContext)
	const [isLoading, setIsLoading] = useState(true);

	const history = useHistory();
	const { groupAccount } = useAuth();
	const [reviewsData, setReviewsData] = useState([]);
	const [reviewsKpis, setReviewsKpis] = useState();
	const [refreshReviews, setRefreshReviews] = useState(false);	

	const { t } = useTranslation();
	
	useFetch(`${process.env.REACT_APP_API}/projects/` + getProjectStorage() + '/' + groupAccount + '/locations/reviews', {}, [refreshReviews], 	
	(data) => { 
		setIsLoading(true);
		setReviewsKpis(data.reviewsKPIs);	
		setReviewsData(data.locationsWithReviewsList);	
		setIsLoading(false);
	});	

	const columns: any = [	
		{
			title: 'Establecimiento',
			key: 'location',									
			sorter: (a: any, b: any) => a.locality?.localeCompare(b.locality),
			filterDropdown: ({
			  setSelectedKeys,
			  selectedKeys,
			  confirm,			  
			}: FilterDropdownProps) => {
			  return (
				<Input
				  autoFocus
				  //placeholder="Filtro"
				  value={selectedKeys[0]}
				  onChange={(e: any) => {
					setSelectedKeys(e.target.value ? [e.target.value] : []);
					confirm({ closeDropdown: false });
				  }}
				  onPressEnter={() => {
					confirm();
				  }}
				  onBlur={() => {
					confirm();
				  }}
				></Input>
			  );
			},
			filterIcon: () => {
			  return <SearchOutlined />;
			},
			onFilter: (value: any, record: any) => {
				return record.title?.toLowerCase().includes(value?.toLowerCase()) 
				|| record.administrativeArea?.toLowerCase().includes(value?.toLowerCase())
				|| record.locality?.toLowerCase().includes(value?.toLowerCase());
			},
			render: (text: string, record: any) => (
				<Location record={record} drawer={false} onChange={() => {setLocationDrawer(record.composeId);}}/>									
			),
		},	
		{
			title: 'Puntuación',
			key: 'rateAvg',
			dataIndex: 'rateAvg',
			align: 'center',
			sorter: (a: any, b: any) => b.reviewsKPIs?.rateAvg - a.reviewsKPIs?.rateAvg,
			render: (text: string, record: any) => (record.reviewsKPIs?.rateAvg &&
                <><div style={{ display: 'flex', justifyContent: 'center' }}>
                    <ColorStar
                        style={{ fontSize: '18px' }}
                        value={Math.round(10 * record.reviewsKPIs?.rateAvg) / 10}/>
					
                </div>
				<small>{record.reviewsKPIs?.numberOfReviews} reseñas</small>
				</>)			
		},		
		{
			title: '%Respuesta',
			key: 'repliedRatio',
			dataIndex: 'repliedRatio',
			align: 'center',
			render: (text: string, record: any) => (
				<Progress width={50} type='circle' strokeColor='green' percent={Math.round(100 * record.reviewsKPIs?.repliedRatio)} />	
			)
		},
		{
			title: 'Alertas',
			key: 'composeId',				
			filters: [
				{ text: 'Urgente', value: 'critical' },
				{ text: 'Warning', value: 'warning' },
				{ text: 'Info', value: 'info'},
				{ text: 'Sin alertas', value:'no_alerts'}
			  ],
			  onFilter: (value: any, record: any ) => {
				if (value === 'no_alerts') {
				  return record.reviewsKPIs?.alerts && record.reviewsKPIs?.alerts.length === 0;
				} else {
				  return record.reviewsKPIs?.alerts && record.reviewsKPIs?.alerts.some((element: any) => element.severity === value);
				}
			  },     
			render: (text: string, record: any) => (
				<>
					{
						record.reviewsKPIs?.alerts.map(
							(alert: any) => 
							(								
								<ActionAlert 
								severity={alert.severity}
								description={alert.description} />                      													
							)
						)
					}
				</>
			)
		},
		{
			title: '',
			key: 'buttons',			
			align: 'center',
			render: (text: string, record: any) => (
				<div style={{display:'flex', justifyContent:'space-between'}}>	
					<Badge count={record.reviewsKPIs?.numberOfPendingReviews} dot color={record.reviewsKPIs?.numberOfRecentPendingReviews ? 'red' : 'orange'} showZero={false}>
						<Button onClick={()=>{setReviewsDrawer(record.composeId)}} size='small' style={{ borderRadius: '20px', background:'var(--primary)',  border:`solid 1.3px RoyalBlue`, color: "white" }}>    
							<StarOutlined style={{ color: 'white' }}/> 	Responder							
						</Button>				
					</Badge>
					{/*<Button onClick={() => { setQuestionsDrawer(record.composeId); } } size='small' style={{ borderRadius: '20px', background:'var(--primary)',  border:`solid 1.3px RoyalBlue`, color: "white" }}>    
						<QuestionOutlined style={{ color: 'white' }}/> 								
					</Button>				
					<Button onClick={()=>{history.push('/clientes/global?locationId=' + record.locationId)}} size='small' style={{ borderRadius: '20px', background:'var(--primary)',  border:`solid 1.3px RoyalBlue`, color: "white" }}>    
						<LineChartOutlined style={{ color: 'white' }}/> 								
					</Button>*/}
				</div>
			)
		}
	]

  	return (
		<>			
			<Card className='main-card-content'>

				<h1 className='title-card'>{t('dictionary.reviewsManagement')}</h1>
				
                <Synchronized service={[SCHEDULEDJOB.GBP_REVIEWS]}/>	
				
				<div className="card-container">
					{reviewsKpis && <BlockReviewsKpis reviewsKPIs={reviewsKpis} />}
				</div>

				{isLoading ? <SpinLZ/> : 
					<Card>				
						{reviewsData &&						
							<TableLZM 
								rowKey='name'
								scroll={{ x: true }}						
								columns={columns}
								dataSource={reviewsData}
								loading={false}/>
						}				
					</Card>
				}
				
			</Card>
		</>
	)
}
