import { Form, Input, message, Modal, Select } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { authFetch } from '../../../../../common/request/authFetch';

const ProjectsAddModal = ({
	visible,
	onFinish,
}: any) => {

	const { t } = useTranslation();
	const [form] = Form.useForm();
	const onFormFinish = async (values: any) => {
		const data = {
			...values,
			name: values.name,
			platforms: values.platforms
		};

		await authFetch(`${process.env.REACT_APP_API}/projects`, { method: 'POST', data: data })
			.then((isOk) => {
				if(isOk) {
					message.info(t('view.forms.modal.projects.success'))
					onFinish();
				} else {					
					message.error(t('view.forms.modal.projects.error'))
				}
			}
		);
	};

  return (
		<Modal visible={visible} onOk={() => form.submit()} onCancel={onFinish} afterClose={form.resetFields}>
			<h2>{t('dictionary.newBusiness')}</h2>
			<Form layout='vertical' form={form} onFinish={onFormFinish} initialValues={{platforms:['Google']}}>
				<Form.Item label={t('dictionary.name')} name='name' rules={[{ required: true, message: t('view.forms.modal.projects.name') as string }]}>
					<Input />
				</Form.Item>
				<Form.Item label={t('dictionary.platforms')} name='platforms' rules={[{ required: true, message: t('view.forms.modal.projects.platforms') as string }]}>
					<Select mode="multiple">
						<Select.Option key='Google' value='Google'>
							Google
						</Select.Option>	
					</Select>
				</Form.Item>
			</Form>
		</Modal>
	)
}

export default ProjectsAddModal;