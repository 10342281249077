import { Alert, Form, Input, message, Modal, Select, Switch } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getProjectStorage } from '../../../../store/storageContext';
import { customRequest } from '../../../../common/request/customRequest';
import { STATUS_CODE } from '../../../../common/constants/statusCode';

export const GuestAssignModal = (
	props : {
		group: any,
		onFinish: any,
		}
	) => {

	const { t } = useTranslation();
	const [form] = Form.useForm();
	const [errorMessage, setErrorMessage] = useState<any>(null);

	const projectId = getProjectStorage()	
	
	const onFormFinish = async (values: any) => {
		const data = {
			...values,
			roles: values.roles,
			status: 'CREATED'			
		};		

		const response = await customRequest(`${process.env.REACT_APP_API}/config/guest/assign`, { method: 'PATCH', data: data })
		if (response?.statusCode === STATUS_CODE.BAD_REQUEST) {
			setErrorMessage(response.message)
		} else {
			props.onFinish();
		}
	};		

  return (
		<Modal visible={true} onOk={() => form.submit()} onCancel={props.onFinish} afterClose={form.resetFields}>
			<h2>Nuevo Usuario</h2>
			<Form layout='vertical' form={form} onFinish={onFormFinish}>
				<Form.Item label={t('dictionary.email')} name='guestEmail' rules={[{ required: true, type: 'email' }]}>
					<Input />
				</Form.Item>				
				<Form.Item label={'Proyecto'} name='project' initialValue={projectId} rules={[{ required: true }]}>
					<Input disabled/>				
				</Form.Item>				
				<Form.Item label={'Grupo'} name='groupName' initialValue={props.group.name} rules={[{ required: true }]}>
					<Input disabled/>			
				</Form.Item>		
				<Form.Item name='group' initialValue={props.group.id} rules={[{ required: true }]}>
					<Input hidden/>				
				</Form.Item>		
			</Form>
			{errorMessage && <Alert
				type='error'
				message={errorMessage}
				banner
			/>
			}
		</Modal>
	)
}
