import { useAuth } from "../../../../store/AuthContext/AuthContext";

export const ROLES: any = {
    ROLE_ADMIN: "Administrador",		
    ROLE_CLIENT: "Cliente",		
    ROLE_AGENT: "Agente",		
    ROLE_MODULE_USERS: "Módulo Usuarios",		
    ROLE_MODULE_MASSIVE: "Módulo Masivo",
    ROLE_AUTOMATED_REVIEWS: "Automatizar Reseñas",
    ROLE_GUEST_VIEW: "Invitado",
    ROLE_GUEST_INFO_EDIT: "Editar Ficha",
    ROLE_GUEST_REVIEWS_EDIT: 'Contestar Reseñas',
    ROLE_GUEST_CONTENT_EDIT: 'Publicar Contenido',
    ROLE_APP_BASE: 'Unilocal Base',
    ROLE_APP_AI: 'Unilocal IA',
    ROLE_APP_WEB: 'Unilocal Web'
};

export const useIsAuthorized = () : (roles: string[]) => boolean => {
    
    const { profile } = useAuth();

    return (roles: string[]) => {
        
        let isAuthorized = false;
        roles.forEach(role => {
            
            if (profile.roles && profile.roles.includes(role)) { 
                isAuthorized = true;
            }
        })
        return isAuthorized;
    }
}