import { PictureOutlined } from '@ant-design/icons';
import { Tag, Button, Form, message, Modal, Tabs, Alert } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../../../store/AuthContext/AuthContext';
import { getProjectStorage } from '../../../../store/storageContext';
import { authFetch } from '../../../../common/request/authFetch';
import FormItemPublishPhotoType from '../../../components/model/business/forms/FormItemPublishPhotoType';
import FormItemUploadPhoto from '../../../components/model/business/forms/FormItemUploadPhoto';


const ImagePublishPanel = ({
	selectedRowKeys,
	visible,
	onCancel,
	onFinish,
}: any) => {

	const { groupAccount } = useAuth();
	const [form4] = Form.useForm();
	const { t } = useTranslation();

	const submitMedia = async (values: any) => {
		
		const url = values.photo.fileList[0].url;

		const data = {
			category: values.mediaType,
			photoURL: url,
			locationsNames: selectedRowKeys
		};
		massivePublish(data, 'localPostMedia');					
	};

	const massivePublish = async (data : any, type: string) => {
		await authFetch(`${process.env.REACT_APP_API}/projects/` + getProjectStorage() + '/' + groupAccount + '/locations/' + type, { method: 'POST', data: data })
		.then((res) => {
			if (res) {	
				message.loading(t('view.forms.modal.bulk.triggered'), 5);											
			} else {
				message.error(t('view.forms.modal.bulk.error'), 5);							
			}
		});
		onFinish();	
	}

  return (
		<Modal visible={visible} onCancel={onCancel} footer={null} width={'45%'}> 

			<h2 style={{paddingTop:'5px', paddingBottom:'5px', paddingRight:'15px'}}>{t('contents.page.management.modals.photo.title')}</h2>
			<Alert message={<div>{selectedRowKeys.length} locales seleccionados</div>} type="info" showIcon />

			<div style={{paddingBottom:'30px'}}>
					<Form layout='vertical' form={form4} onFinish={submitMedia}>
						<FormItemPublishPhotoType/>
						<FormItemUploadPhoto maxPhotos={5}/>
						<Button style={{float:'right'}} shape='round' type='primary' onClick={form4.submit}>
							{t('dictionary.publish')} {t('dictionary.pictures')}
						</Button>
					</Form>
			</div>			
		</Modal>
	)
}

export default ImagePublishPanel;