import React, { useContext, useState } from "react";
import { DrawerContext } from "../../../../store/DrawerContext/DrawerContext";
import { Drawer, Tabs } from "antd";
import { useFetch } from "../../../../hooks/useFetch/useFetch";
import Reviews from "../reviews/reviews/Reviews";
import { getProjectStorage } from "../../../../store/storageContext";
import CardDetailsReview from "../../../pages/menuReviews/3_1_Reviews/Reviews/CardDetailsReview";
import { DoubleLeftOutlined, EnvironmentOutlined, } from "@ant-design/icons";

export const ReviewsDrawer = () => {

    const { reviewsDrawer, setReviewsDrawer } = useContext(DrawerContext);
    const [selectedReview, setSelectedReview] = useState<any>(null);

    const handleReviewClick = (review: React.SetStateAction<null>) => {
        setSelectedReview(review);
    };

    const [updateData, setUpdateData] = useState(false)

    const { data, isLoading }: any = useFetch(
        `${process.env.REACT_APP_API}/projects/` + getProjectStorage() + `/${reviewsDrawer}/reviewsDrawer`,
        {},
        [updateData],
    );

    return (
        <>
            {reviewsDrawer && (
                <Drawer
                    placement="right"
                    width={'45%'}
                    visible={reviewsDrawer != null}
                    onClose={() => setReviewsDrawer(null)}
                    bodyStyle={{ background: '#F4F7FA' }}
                    title={!isLoading && <div style={{ display: 'flex', gap: '15px' }}><EnvironmentOutlined /> <h4>
                        {data.adressLine ? `${data?.addressLine}, ${data?.locality}, ${data?.administrativeArea}` : <p>Sin ubicación</p>}</h4></div>}
                >
                    <h1 style={{ marginBottom: '0px' }}>Reseñas</h1>

                    <div style={{ marginRight: '10px', display: 'flex', justifyContent: 'flex-end', height: '20px' }}>
                        {selectedReview ?
                            <p style={{ cursor: 'pointer' }} onClick={() => { setSelectedReview(null) }}>
                                <DoubleLeftOutlined /> Atrás
                            </p>
                            : null}
                    </div>
                    {
                        !selectedReview ?
                            <Reviews reviewsData={data?.reviews} handleReviewClick={handleReviewClick} width='100%' />
                            :
                            <CardDetailsReview reviewId={selectedReview?.name} drawer={true} />
                    }
                </Drawer>
            )}
        </>
    );
};
