import { Affix, ConfigProvider, Layout as AntdLayout } from 'antd';
import { useTranslation } from 'react-i18next';
import { Footer } from 'antd/lib/layout/layout';
import es_ES from "antd/lib/locale/es_ES";
import en_GB from "antd/lib/locale/en_GB";
import 'moment/locale/es';
import React from 'react';
import { useAuth } from '../../../store/AuthContext/AuthContext';
import Bot from '../../components/model/bot/Bot';
import WhiteLogo from '../../components/model/logos/LogoLocalizameWhite';
import MainHeader from '../../pages/common/header/MainHeader';
import MainContent from './MainContent';
import AllDrawers from '../../components/model/Drawers/AllDrawers';
import AppHeaderRoutes from '../../../routing/AppHeaderRoutes';


const MainLayout = () => {

  const { isAuth } = useAuth();
  const { i18n } = useTranslation();

  const antdLocales: any = {
    es: es_ES,
    en: en_GB
  }

  return (
    <AntdLayout style={{ minHeight: '100vh', background: 'white' }}>
      {/* {
        isAuth && <MainHeader />        
      } */}
      {/*AppHeaderRoutes*/}
      <ConfigProvider locale={antdLocales[i18n.language]}>
        <MainContent/>
      </ConfigProvider>
      {/* <Footer style={{display:"flex", justifyContent:"space-between", alignItems:"center" ,backgroundColor:'#212F3D', margin:'0px', padding:'10px'}}>        
        <WhiteLogo style={{ display: 'inline', width:'200px', height:'75px', marginLeft:'32px' }} />
        {
          isAuth && <Affix >            
          <Bot style={{ display: 'inline', width: '60px' }}/>
        </Affix>
        }	
        
      </Footer> */}
      <AllDrawers/>
    </AntdLayout>
	)
};

export default MainLayout;