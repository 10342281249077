
export const NameView = (props:{titleStatus:string}) => {
  return (
    <div className="title-box">
      <div style={{ margin: "1em 0 0 0", display: "flex", justifyContent: "flex-start" }}>
          <span style={{ color: "#666" }}>
              {props.titleStatus}
          </span>
      </div>                
    </div>
  )
}
