import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';
import './i18n';

ReactDOM.render(  
  <React.Suspense fallback="Loading">
    <App />
  </React.Suspense>,
  document.getElementById('root')
);
