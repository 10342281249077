import { Card, Rate, Switch, message } from 'antd';
import { useEffect, useState } from 'react';
import { authRequest } from '../../../../../../common/request/authRequest';
import { getProjectStorage } from '../../../../../../store/storageContext';
import { useFetch } from '../../../../../../hooks/useFetch/useFetch';
import { useAuth } from '../../../../../../store/AuthContext/AuthContext';

export const AutomateReviewsTab = (props: { openDrawer: boolean, setOpenDrawer: any }) => {

    const [updateData, setUpdateData] = useState(false);
    const [rules, setRules] = useState<any>(null);
    const { groupAccount } = useAuth();    

    useEffect(() => {
        
        authRequest(`${process.env.REACT_APP_API}/automated/reviews/${groupAccount}`, {})
          .then((response) => {
            setRules(response);
                  
          }).catch(() => {message.error('Error al cargar reglas')});
          }
    ,[updateData]); 

    const changeStatus = async (score: number, hasText: boolean, automated: boolean) => {
		    
        const data = {
            project: getProjectStorage(),	
            hasText: hasText,
            score: score,
            automated: automated
        };		
    
        await authRequest(`${process.env.REACT_APP_API}/automated/reviews/${groupAccount}`, { method: 'PATCH', data: data })
          .then((data) => {
          setUpdateData(!updateData);
          message.success('Cambio correcto');
        }).catch((error) => {
          message.error('Error imprevisto');
        });
    };	
    
    return (        
        rules &&<>                    
            <br/>    
            <p>Selecciona las reseñas que quieres automatizar en función de la puntuación y si tienen texto o no:</p>
            <Card
                className='card-layout'                
                style={{ background:'white', margin: '10px', borderRadius: '10px' }}>
                                 
                <div style={{display:'flex', justifyContent:'space-around', alignItems:'center'}}>
                    <Rate value={1}/>
                    <div>
                    <p style={{color:'steelblue', margin:'0px'}}>Sin Texto</p>
                        <Switch style={{minWidth:'130px'}} checkedChildren='Automatizado' unCheckedChildren='Manual' defaultChecked={rules["1_false"]?.automated} onClick={(e)=>{changeStatus(1, false, e)}}/>
                    </div>
                    <div>
                        <p style={{color:'steelblue', margin:'0px'}}>Con Texto</p>
                        <Switch style={{minWidth:'130px'}} checkedChildren='Automatizado' unCheckedChildren='Manual' defaultChecked={rules["1_true"]?.automated} onClick={(e)=>{changeStatus(1, true, e)}}/>
                    </div>
                </div>     
            </Card>
            <Card
                className='card-layout'                
                style={{ background:'white', margin: '10px', borderRadius: '10px' }}>
                                 
                <div style={{display:'flex', justifyContent:'space-around', alignItems:'center'}}>
                    <Rate value={2}/>
                    <div>
                    <p style={{color:'steelblue', margin:'0px'}}>Sin Texto</p>
                        <Switch style={{minWidth:'130px'}} checkedChildren='Automatizado' unCheckedChildren='Manual' defaultChecked={rules["2_false"]?.automated} onClick={(e)=>{changeStatus(2, false, e)}}/>
                    </div>
                    <div>
                        <p style={{color:'steelblue', margin:'0px'}}>Con Texto</p>
                        <Switch style={{minWidth:'130px'}} checkedChildren='Automatizado' unCheckedChildren='Manual' defaultChecked={rules["2_true"]?.automated} onClick={(e)=>{changeStatus(2, true, e)}}/>
                    </div>
                </div>     
            </Card>
            <Card
                className='card-layout'                
                style={{ background:'white', margin: '10px', borderRadius: '10px' }}>
                                 
                <div style={{display:'flex', justifyContent:'space-around', alignItems:'center'}}>
                    <Rate value={3}/>
                    <div>
                    <p style={{color:'steelblue', margin:'0px'}}>Sin Texto</p>
                        <Switch style={{minWidth:'130px'}} checkedChildren='Automatizado' unCheckedChildren='Manual' defaultChecked={rules["3_false"]?.automated} onClick={(e)=>{changeStatus(3, false, e)}}/> 
                    </div>
                    <div>
                        <p style={{color:'steelblue', margin:'0px'}}>Con Texto</p>
                        <Switch style={{minWidth:'130px'}} checkedChildren='Automatizado' unCheckedChildren='Manual' defaultChecked={rules["3_true"]?.automated} onClick={(e)=>{changeStatus(3, true, e)}}/>
                    </div>
                </div>     
            </Card>
            <Card
                className='card-layout'                
                style={{ background:'white', margin: '10px', borderRadius: '10px' }}>
                                 
                <div style={{display:'flex', justifyContent:'space-around', alignItems:'center'}}>
                    <Rate value={4}/>
                    <div>
                    <p style={{color:'steelblue', margin:'0px'}}>Sin Texto</p>
                        <Switch style={{minWidth:'130px'}} checkedChildren='Automatizado' unCheckedChildren='Manual' defaultChecked={rules["4_false"]?.automated} onClick={(e)=>{changeStatus(4, false, e)}}/>
                    </div>
                    <div>
                        <p style={{color:'steelblue', margin:'0px'}}>Con Texto</p>
                        <Switch style={{minWidth:'130px'}} checkedChildren='Automatizado' unCheckedChildren='Manual' defaultChecked={rules["4_true"]?.automated} onClick={(e)=>{changeStatus(4, true, e)}}/>
                    </div>
                </div>     
            </Card>
            <Card
                className='card-layout'                
                style={{ background:'white', margin: '10px', borderRadius: '10px' }}>
                                 
                <div style={{display:'flex', justifyContent:'space-around', alignItems:'center'}}>
                    <Rate value={5}/>
                    <div>
                    <p style={{color:'steelblue', margin:'0px'}}>Sin Texto</p>
                        <Switch style={{minWidth:'130px'}} checkedChildren='Automatizado' unCheckedChildren='Manual' defaultChecked={rules["5_false"]?.automated} onClick={(e)=>{changeStatus(5, false, e)}}/>
                    </div>
                    <div>
                        <p style={{color:'steelblue', margin:'0px'}}>Con Texto</p>
                        <Switch style={{minWidth:'130px'}} checkedChildren='Automatizado' unCheckedChildren='Manual' defaultChecked={rules["5_true"]?.automated} onClick={(e)=>{changeStatus(5, true, e)}}/>
                    </div>
                </div>     
            </Card>                      
        </>
    )
}
