import { ApiOutlined, CheckOutlined, CloudSyncOutlined, FormOutlined, UserAddOutlined, UserOutlined } from '@ant-design/icons';
import { Badge, Card, Steps, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { Trans, useTranslation } from 'react-i18next';
import { useFetch } from '../../../hooks/useFetch/useFetch';
import ProfileProjects from '../../components/model/projects/ProfileProjects';
import { ButtonLZ } from '../../components/design/Buttons/ButtonLZ';
import GroupAccount from './GroupAccount';
import { authRequest } from '../../../common/request/authRequest';



const UserStep2Vincular = () => {

  const [profile, setProfile] = useState({name:'', email:'', role:'', projects:[]});
  const [updateHeader, setUpdateHeader] = useState(false);	
  const history = useHistory();
	const location = useLocation();
  const { t } = useTranslation();

  useFetch(`${process.env.REACT_APP_API}/users/principal/profile`, {}, [], (data) => {				
    setProfile(data)    
  });

  const [enableGroups, setEnableGroups] = useState(0);  
  const [project, setProject] = useState('');  
  const [googleAccounts, setGoogleAccounts] = useState<any[]>([]);  
  const [mybusinessEmail, setMybusinessEmail] = useState(''); 


    useFetch(`${process.env.REACT_APP_API}/users/principal/projectNames`, {}, [], (data) => {
      setProject(data.defaultValue);
      
    });

    useEffect(() => {
      
      if (project) {
        authRequest(`${process.env.REACT_APP_API}/users/principal/projects/${project}/platform/google/accountsList`, {})
          .then((response) => {
            setGoogleAccounts(response.groupAccounts)            
            setMybusinessEmail(response.mybusinessEmail)
          })
          .catch(() => {message.error(t('view.errors.loadGroups')); history.replace("/vincular")});
          }
        }
    ,[project]);

    const finish = () => {
  
      authRequest(`${process.env.REACT_APP_API}/users/principal?status=ACTIVE`, { method: 'PATCH' })
      .then(() => {        
        message.success(t('view.forms.register.sync.success'));
       // profile.status = "ACTIVE";
       // setProfile(profile);
        history.push('/welcome', { from: location });
        window.location.href = '/welcome';
        }
      )
      .catch(() => {
        message.error(t('view.forms.register.sync.error'));
      })
      history.push('/welcome', { from: location });
      window.location.href = '/welcome';
    }


  return (
    
    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', background: 'white' }}>

      <Card bordered={true} style={{ padding: '24px', marginBottom:'20px', width: '70%', border: '.2px solid lightgrey', borderRadius: '10px', background: '#f5f9fb' }}>
      <h1  className='title-card'>Registro</h1>

      <div style={{padding:'25px 0'}}>
          <Steps current={1} status={"process"} >
            <Steps.Step title={<><UserAddOutlined/> {t('dictionary.addUserData')}</>}/>
            <Steps.Step title={<><ApiOutlined/> {t('dictionary.connectMyBusiness')}</>}/>
          </Steps>  
      </div>

      <div>
           <h3>
              <Trans values={{ name: profile.name }}>
                <b>Vincular cuenta de Google asociada a tus negocios</b>
              </Trans>
            </h3> 
           {
            <ProfileProjects profile={profile} updateHeader={updateHeader} setUpdateHeader={setUpdateHeader}/>				
           }

           <small>
            <i>
              <Trans >
                <b>Nota:</b> En el momento que desees podrás desvincular tu cuenta desde tu perfil y siempre tendrás el control sobre tu información
              </Trans></i>
           </small>
      </div>


        {
          googleAccounts && googleAccounts.length >0 && <div style={{marginTop:'30px'}}>
          <h3>
            <Trans values={{ email: mybusinessEmail, project }}>
            <b>Sincronizar grupos</b>
            </Trans>
          </h3>         
          <Badge.Ribbon placement='start' text={project} color="black">                     
            <Card size='small' bordered={true} bodyStyle={{height: "30vh", overflowY:'scroll'}} style={{ margin:'0% 0%', padding: '12px', borderRadius: '10px', boxShadow: '4px 7px #f5f9fb', background: '#fcfcfc' }} title=
              {                    
                <ButtonLZ shape='round' type='primary' style={{float:'right'}} color={'yellow'}                    
                  onClick={() => finish()} 
                  icon={<CheckOutlined/>} 
                  disabled={enableGroups == 0}
                >
                {t('dictionary.finish')}
              </ButtonLZ> 
              }
              >
              {            
                googleAccounts.map(account => {
                  return (     
                      <GroupAccount account={account} project={project} enableGroups={enableGroups} setEnableGroups={setEnableGroups}/>                            
                  )
                })
              }				                                                             
            </Card > 
          </Badge.Ribbon>    
            </div>
          }
        </Card>                     
      </div>
	)
}

export default UserStep2Vincular;