import { EditOutlined } from '@ant-design/icons'
import { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useIsAuthorized } from '../../../../../pages/common/roles/Roles';
import { StoreCodeView } from './StoreCodeView';
import { StoreCodeEditEdit } from './StoreCodeEdit';

export const StoreCode = (props: { storeCode: string, locationsIds: string[]}) => {

    const [edit, setEdit] = useState(false);
    const [editVisible, setEditVisible] = useState(false);
    const [currentStoreCode, setCurrentStoreCode] = useState(props.storeCode);
    const { t } = useTranslation();
    const isAuthorized = useIsAuthorized();
    
    let locationId = props.locationsIds[0];

    const handleMouseEnter = () => {
        setEditVisible(true);
    };

    const handleMouseLeave = () => {
        setEditVisible(false);
    };
      
    return (
        <div  
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={{backgroundColor:editVisible ? 'var(--light-grey)' : ''}}>
        
            <span style={{ textDecoration: "none", fontSize: "16px", color: "#3C4043", marginLeft:"1em", cursor:'pointer' }} onClick={() => setEdit(true)}>
                Código de Tienda
                {editVisible && isAuthorized(['ROLE_ADMIN', 'ROLE_AGENT', 'ROLE_CLIENT', 'ROLE_GUEST_INFO_EDIT']) && <EditOutlined />}
            </span>
            {
                !edit
                ?
                <StoreCodeView titleStatus={props.storeCode!} />
                : 
                <StoreCodeEditEdit                     
                    storeCode={currentStoreCode}     
                    setCurrentStoreCode={setCurrentStoreCode}    
                    setEdit={setEdit} 
                    edit={edit}  
                    locationsIds={props.locationsIds}                                                        
                />
            }            
        </div>
    )
}
