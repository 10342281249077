import { Select } from 'antd';
import React, { useEffect } from 'react';
import { useFetch } from '../../../../hooks/useFetch/useFetch';
import { getGroupAccountStorage, setGroupAccountStorage } from '../../../../store/storageContext';

const GoogleAccounts = ( props: {project: string, groupAccount: string, setGroupAccount: any, googleAccounts: any, setGoogleAccounts: any } ) => {
	
	const { isLoading } = useFetch
			(
			`${process.env.REACT_APP_API}/users/principal/projects/` + props.project + '/platform/google/accounts', 
			{}, 
			[], 
			(data) => {		
						
				props.setGoogleAccounts(data.map);
				let myMap = new Map(Object.entries(data.map));
				const groupStorage = getGroupAccountStorage(props.project);

				if(groupStorage && myMap.has(groupStorage)) {					
					props.setGroupAccount(groupStorage);
				} else {
					props.setGroupAccount(data.defaultValue);
				}
			}
		);

		useEffect(() => {
			if (props.groupAccount) {		
				setGroupAccountStorage(props.project, props.groupAccount);					
			}		
		}, [props.groupAccount])

  return (
		<div>			
			{
			<Select 
				value={isLoading?'':props.groupAccount} 
				onChange={value => props.setGroupAccount(value)} 
				loading={isLoading} 
				bordered={false} 
				style={{ minWidth:'100px', textAlign:'right', background:'lightcyan', fontWeight: 600, borderRadius:'10px'}}>
				{
					props.googleAccounts && Object.keys(props.googleAccounts).map(account => {
						return (
						<Select.Option key={account} value={account}>
							{
								props.googleAccounts[account]
							}	
						</Select.Option>)
					})
				}
			</Select>	
			}				
		</div>
	)
}

export default GoogleAccounts;