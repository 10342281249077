import { Card } from 'antd';
import './GoogleReviews.css';
import '../../pages.scss';
import QuestionsContainer from '../../../components/model/reviews/questions/QuestionsContainer';
import { Synchronized } from '../../../components/design/Synchronized/Synchronized';
import { SCHEDULEDJOB } from '../../../../common/constants/services';

const GoogleQuestions = () => {


	return (
		<>
			<Card className='main-card-content' >
				<h1 className='title-card'>{'Responder Preguntas'}</h1>

				<Synchronized service={[SCHEDULEDJOB.GBP_REVIEWS, SCHEDULEDJOB.GBP_QUESTIONS]} />

				<QuestionsContainer />

			</Card>

		</>
	)
}

export default GoogleQuestions;