import { Menu } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {  CodeOutlined, CommentOutlined, FileTextOutlined, FundOutlined, LineChartOutlined, NotificationOutlined, SettingOutlined, ShareAltOutlined, ShopOutlined, StarOutlined, UserAddOutlined, UserOutlined, UsergroupAddOutlined } from '@ant-design/icons';
import { useAuth } from '../../../store/AuthContext/AuthContext';
import "../../pages/common/header/MainHeader.scss"
import { useIsAuthorized } from '../../pages/common/roles/Roles';

const MainMenu = () => {
	
	const { profile } = useAuth();
	const { t } = useTranslation();
	const location = useLocation();
	const isAuthorized = useIsAuthorized();
	const current = location.pathname.split('/')[1];
	//console.log('PATH >>>' + current);
	
	return (
				
		<Menu  disabledOverflow={true} style={{ background: 'transparent' }} mode='horizontal' 
			selectedKeys={[current]}>		
		{
			 [		
				<Menu.Item key={'negocios'} className="custom-menu-item1">
					<Link to={'/negocios/locales'}>
						<ShopOutlined style={{fontSize:'17px'}}/>Info
					</Link>
				</Menu.Item>,
				<Menu.Item key={'clientes'}>
					<Link to={'/clientes/locationsreviews'}>
						<StarOutlined style={{fontSize:'17px'}}/>{t('reviews.title')}
					</Link>
				</Menu.Item>,
				<Menu.Item key={'contenidos'}>
				<Link to={'/contenidos/locales'} >
					<NotificationOutlined style={{fontSize:'17px'}}/>{t('contents.title')}
				</Link>
				</Menu.Item>,					
				isAuthorized(['ROLE_ADMIN', 'ROLE_CLIENT', 'ROLE_AGENT']) && <Menu.Item key={'informes'}>
					<Link to={'/informes/lista'}>
						<ShareAltOutlined style={{fontSize:'17px'}}/>{'Informes'}
					</Link>
				</Menu.Item>,
				isAuthorized(['ROLE_ADMIN', 'ROLE_MODULE_USERS']) && <Menu.Item key={'configurar'}>
					<Link to={'/configurar/invitados'}>
						<SettingOutlined style={{fontSize:'17px'}}/>{'Configurar'}
					</Link>
				</Menu.Item>,
				// ADMIN
				profile.roles.includes('ROLE_ADMIN') && <Menu.Item key={'admin'}>
					<Link to={'/admin/usuarios'}>
						<SettingOutlined style={{fontSize:'17px', color:'lavender'}}/>
					</Link>
				</Menu.Item>,
				profile.roles.includes('ROLE_ADMIN') && <Menu.Item key={'tech'}>
					<Link to={'/tech/services'}>
						<CodeOutlined style={{fontSize:'17px', color:'lavender'}}/>
					</Link>
				</Menu.Item>				
			]
		}
		</Menu>			
	)
};

export default MainMenu;