import { Form, Input, Modal, Select } from 'antd';
import 'moment/locale/es';
import { useEffect, useState } from 'react';
import { useAuth } from '../../../../../../../store/AuthContext/AuthContext';
import { authRequest } from '../../../../../../../common/request/authRequest';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { getProjectStorage } from '../../../../../../../store/storageContext';
import { ButtonLZ } from '../../../../../design/Buttons/ButtonLZ';
import { authFetch } from '../../../../../../../common/request/authFetch';
import { t } from 'i18next';



const ServiceEditModal = ({
    category,
    locationsIds,
    visible,
    onFinish,
}: any) => {

    const [form] = Form.useForm();
    const { groupAccount } = useAuth();

    const [selectedCategory, setSelectedCategory] = useState<any>(null)
    const [serviceTypes, setServiceTypes] = useState<any>(null)
    const [addCustomService, setAddCustomService] = useState<any>(null)
    const [isLoading, setIsLoading] = useState(false)

    const onFormFinish = async (values: any) => {

        const serviceItemsData = values?.serviceType.map((serviceType: any) => ({
            isOffered: true,
            structuredServiceItem: {
              serviceTypeId: serviceType,
            },
          }));

          if(values.freeType){
            const customSerivice = {
                isOffered: true,
                freeFormServiceItem: {
                  categoryId: selectedCategory.categoryName,
                  label: {
                    displayName: values.freeType,
                  },
                },
              };
              
              serviceItemsData.push(customSerivice);
          }

        
        await authFetch(`${process.env.REACT_APP_API}/projects/` + getProjectStorage() + '/' + groupAccount + `/${locationsIds}/serviceTypes`, { method: 'PATCH', data: serviceItemsData })
    }


    useEffect(() => {

        setIsLoading(true)
        if (selectedCategory !== null) {

            const data = {
                categoryName: selectedCategory.categoryName,
                serviceItems: selectedCategory.serviceItems
            };
            authRequest(`${process.env.REACT_APP_API}` + '/projects/' + getProjectStorage() + `/${groupAccount}` + `/${locationsIds}/serviceTypes`, { method: 'POST', data: data }).then((res) => {
                setServiceTypes(res);
                setIsLoading(false)
            })
        }
    }, [selectedCategory]);

    const handleCancel = () => {
        setAddCustomService(false)
        form.resetFields();
    };

    return (

        <Modal
            footer={selectedCategory ? [
                <div style={{ padding: '10px' }}>
                    <ButtonLZ key="back" color='dark-white' onClick={() => handleCancel()}>
                        Cancelar
                    </ButtonLZ>
                    <ButtonLZ key="submit" onClick={() => form.submit()}>
                        Guardar
                    </ButtonLZ>
                </div>
            ] : null}

            visible={true} onOk={() => form.submit()} onCancel={onFinish} afterClose={form.resetFields}>

            {selectedCategory === null && <div style={{ paddingBottom: '20px' }}>
                <h2>{t('dictionary.services')}</h2>
                {category.map((cat: any) => (
                    <div className="title-box" key={cat.index} style={{ display: 'flex', flexDirection: 'column' }}>
                        <div style={{ marginTop: "1em", display: "flex", justifyContent: "flex-start", flexDirection: 'column' }}>
                            <b
                            >
                                {cat.categoryName}
                            </b>

                            {cat.serviceItems.map((service: any) => {
                                return <span style={{ color: "#666" }} >{service.displayName}</span>
                            })}
                        </div>
                        <a style={{ marginLeft: '10px' }} onClick={() => { setSelectedCategory(cat) }}>+ Añadir más servicios</a>

                    </div>

                ))}
            </div>}
            {selectedCategory && (

                <div style={{ paddingBottom: '20px' }}>
                    <h2><ArrowLeftOutlined onClick={() => { setSelectedCategory(null); handleCancel() }} /> Añadir Servicios</h2>

                    <div className="title-box" style={{ display: 'flex', flexDirection: 'column' }}>
                        <b>{selectedCategory.categoryName}</b>

                        <Form layout='vertical' form={form} onFinish={onFormFinish}>
                            <Form.Item name='serviceType' >
                                <Select
                                    placeholder="Selecciona un servicio"
                                    mode="multiple"

                                    onChange={(value) => {
                                    }}>
                                    {
                                        !isLoading && serviceTypes?.map((service: any) =>
                                            <Select.Option value={service.serviceTypeId}>{service.displayName}</Select.Option>
                                        )}
                                </Select>
                            </Form.Item>

                            <p>¿No ves el servicio que ofreces? Créalo</p>
                            {addCustomService &&
                                <div style={{ display: 'flex', margin: '10px 0px' }}>
                                    <Form.Item name='freeType'>
                                        <Input />
                                    </Form.Item>
                                    <button
                                        style={{ width: '20%', height: '32px', backgroundColor: "transparent", border: "none", fontWeight: 600, padding: "0 1em", cursor: "pointer" }}
                                        onClick={() => setAddCustomService(false)} >
                                        X
                                    </button>
                                </div>

                            }
                            <a onClick={() => setAddCustomService(true)}>+ Añadir servicio personalizado</a>
                        </Form>
                    </div>
                </div>
            )}

        </Modal>
    )
}

export default ServiceEditModal;