import { Layout as AntdLayout } from 'antd';
import AppRoutes from '../../../routing/AppRoutes';
import MainHeader from '../../pages/common/header/MainHeader';
import { useAuth } from '../../../store/AuthContext/AuthContext';
import { useLocation } from 'react-router-dom';


const { Content } = AntdLayout;

const MainContent = () => {

	const { isAuth } = useAuth();
	const location = useLocation();

	return (
		<>
		{isAuth && 
			<MainHeader />
		}
		<Content style={{
				overflow: 'initial'				
			}}>
			<AppRoutes />		
		</Content>
		</>
	)
};

export default MainContent;