import React from 'react';
import { Route, Switch } from 'react-router';
import { googleBizRoutes } from './constants';


const GoogleBizRoutes = () => {

  return (
		<Switch>
			{
				googleBizRoutes.map((route, index) => {
					return (
						<Route
							key={index}
							path={route.path}
							exact={route.exact}
							render={() => <route.component />} />						
					)
				})
			}
		</Switch>
	);
}

export default GoogleBizRoutes;