
import { useState } from 'react'
import { EditOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next';
import { WebEdit } from './WebEdit'
import { WebView } from './WebView'
import { useIsAuthorized } from '../../../../../pages/common/roles/Roles';

//url
export const Web = (props:{url : string, locationsIds : string[]}) => {
    
    const [edit, setEdit] = useState(false);
    const [editVisible, setEditVisible] = useState(false);
    const isAuthorized = useIsAuthorized();
    const [currentURL, setCurrentURL] = useState(props.url);
    const { t } = useTranslation();

    const handleMouseEnter = () => {
        setEditVisible(true);
    };
    
    const handleMouseLeave = () => {
        setEditVisible(false);
    };

    return (
        <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} style={{backgroundColor: editVisible ? 'var(--light-grey)' : ''}}>

            <span style={{ textDecoration: "none", fontSize: "16px", color: "#3C4043", marginLeft:"1em" , cursor:'pointer'}} onClick={() => setEdit(true)}>
                {t('dictionary.web')}
                {editVisible && isAuthorized(['ROLE_ADMIN', 'ROLE_AGENT', 'ROLE_CLIENT', 'ROLE_GUEST_INFO_EDIT']) && <EditOutlined />}
            </span>
            {
                edit ? 
                    <WebEdit isMassive={false} url={currentURL} locationsIds={props.locationsIds} 
                        setCurrentURL={setCurrentURL} 
                        setEdit={setEdit}
                        edit={edit}
                    />
                    : 
                    <WebView url={currentURL}/>
                    
            }
        </div>

    )
}







