import { Image } from "antd";
import { t } from "i18next";
import noLogo from "../../../../assets/add-user.png"
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import HoverIcon from "../../design/HoverIcon/HoverIcon";

export default function LogoAndTitle({ handleModal, logo, isLoading }: any) {
  return <div style={{ display: 'flex', gap: '35px', position: 'absolute', top: '70%' }}>
    <div className='container-logo'>
      {isLoading ? <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} /> :
        <>
          <Image
            style={{ cursor: 'pointer', borderRadius: '50%' }}
            width={70}
            preview={false}
            src={logo ? logo : noLogo}
            referrerPolicy="no-referrer" />

          <HoverIcon border={true} onClick={() => handleModal(`${t('dictionary.edit')} ${t('dictionary.image').toLowerCase()} ${t('dictionary.logo').toLowerCase()}`, 'LOGO')}
          />

        </>
      }
    </div>
  </div>
}