import { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import {  Button, Card, Form, Input, Radio} from 'antd';
import { useTranslation } from 'react-i18next';
import html2canvas from 'html2canvas';
import QRCodeStyling, { Options } from 'qr-code-styling';
import '../../pages.scss';
import './QR.scss';

const PRIMARY_COLOR = window.getComputedStyle(window.document.documentElement)
	.getPropertyValue('--primary').trim();

const QR_STYLES_DEFAULT: Partial<Options> = {
	width: 250,
	height: 300,
	dotsOptions: {
		color: PRIMARY_COLOR,
		type: 'square'
	},
	backgroundOptions: {
		color: '#ffffff'
	},
	cornersSquareOptions: {
		color: PRIMARY_COLOR
	},
	type: 'svg',
	data: 'https://g.page/r/CSsPOYiUP-tBEB0/review',
	imageOptions: {
		imageSize: 0.4,
		margin: 5
	}
};

export const QR = () => {
	const { t } = useTranslation();
	const qrRef = useRef<HTMLDivElement>(null);
	const outputRef = useRef<HTMLDivElement>(null);

	const [qrStyles, setQrStyles] = useState(QR_STYLES_DEFAULT);	
	const [qrFooter, setQrFooter] = useState({ text: t('view.forms.fields.qr.template.footer'), color: '#000000' });

	const qrCode = useMemo(() => new QRCodeStyling(qrStyles), []);


	useEffect(() => { qrRef.current && qrCode.append(qrRef.current); }, []);
	useEffect(() => { qrCode.update(qrStyles);}, [qrStyles, qrCode]);

  	return (
		<>			
			<Card className='main-card-content'>
			
				<h1 className='title-card'>{t('view.forms.fields.qr.title')}</h1>

				<div className="card-content">														
					<div className="card-preview" ref={outputRef} style={{ backgroundColor: qrStyles.backgroundOptions!.color }}>
						<span id="canvas" ref={qrRef}></span>
						<h3 className="preview-footer" style={{ color: qrFooter.color }}>{qrFooter.text}</h3>
					</div>
					<div className="card-settings">
						<h3>{t('dictionary.design')}</h3>
						<div className="settings-group">
							<Form.Item label={t('view.forms.fields.qr.template.marker')}>
								<Input style={{ width: '45px' }} type="color" value={qrStyles.dotsOptions!.color} onChange={(event: ChangeEvent<HTMLInputElement>) => setQrStyles({ ...qrStyles, dotsOptions: { ...qrStyles.dotsOptions, color: event.target.value }})}/>
							</Form.Item>
							<Form.Item label={t('view.forms.fields.qr.template.corners')}>
								<Input style={{ width: '45px' }} type="color" value={qrStyles.cornersSquareOptions!.color} onChange={(event: ChangeEvent<HTMLInputElement>) => setQrStyles({ ...qrStyles, cornersSquareOptions: { ...qrStyles.cornersSquareOptions, color: event.target.value }})}/>
							</Form.Item>
							<Form.Item label={t('view.forms.fields.qr.template.background')}>
								<Input style={{ width: '45px' }} type="color" value={qrStyles.backgroundOptions!.color} onChange={(event: ChangeEvent<HTMLInputElement>) => setQrStyles({ ...qrStyles, backgroundOptions: { ...qrStyles.backgroundOptions, color: event.target.value }})}/>
							</Form.Item>
						</div>
						<Form.Item label={t('dictionary.style')}>
							<Radio.Group value={qrStyles.dotsOptions!.type} onChange={(event: any) => setQrStyles({ ...qrStyles, dotsOptions: { ...qrStyles.dotsOptions, type: event.target.value }})}>
							<Radio.Button value="square">{t('view.forms.fields.qr.template.square')}</Radio.Button>
							<Radio.Button value="dots">{t('view.forms.fields.qr.template.dots')}</Radio.Button>
							<Radio.Button value="rounded">{t('view.forms.fields.qr.template.rounded')}</Radio.Button>
							<Radio.Button value="classy">{t('view.forms.fields.qr.template.classy')}</Radio.Button>
							<Radio.Button value="extra-rounded">{t('view.forms.fields.qr.template.extraRounded')}</Radio.Button>
							<Radio.Button value="classy-rounded">{t('view.forms.fields.qr.template.classyRounded')}</Radio.Button>
							</Radio.Group>
						</Form.Item>
						<Form.Item label={t('dictionary.logo')}>
							<Input type="file" onChange={(event: ChangeEvent<HTMLInputElement>) => {
								const styles = { ...qrStyles };

								if(event.target.files && event.target.files.length) {
									const file = event.target.files[0];
									const blob = new Blob([file])

									styles.image = URL.createObjectURL(blob);
								} else {
									styles.image = undefined;
								}

								setQrStyles(styles);
							}}/>
						</Form.Item>
						<Form.Item label={t('view.forms.fields.qr.footer')}>
							<div className="settings-group">
								<Input value={qrFooter.text} onChange={(event: ChangeEvent<HTMLInputElement>) => setQrFooter({ ...qrFooter, text: event.target.value })}/>
								<Input style={{ width: '45px' }} type="color" value={qrFooter.color} onChange={(event: ChangeEvent<HTMLInputElement>) => setQrFooter({ ...qrFooter, color: event.target.value })}/>
							</div>
						</Form.Item>
						<br />
						<h3>{t('dictionary.configuration')}</h3>
						<Form.Item label={t('dictionary.link')}>
							<Input placeholder={t('view.forms.fields.qr.link') as string} value={qrStyles.data} onChange={(event: ChangeEvent<HTMLInputElement>) => setQrStyles({ ...qrStyles, data: event.target.value })}/>
						</Form.Item>
						<Button type="primary" onClick={() => {
							outputRef.current && html2canvas(outputRef.current)
							.then((canvas) => {
								let link = document.createElement('a');
								link.download = 'qr.png';
								link.href = canvas.toDataURL('image/png');
								link.click();
							});
							}}>{t('dictionary.download')}</Button>
					</div>
				</div>
				
			</Card>
		</>
	)
}