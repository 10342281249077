import { ArrowRightOutlined, BarsOutlined, CheckCircleTwoTone, EditOutlined, FileImageOutlined, LineChartOutlined, LoadingOutlined, QuestionOutlined, SearchOutlined, ShopOutlined, SyncOutlined, ToTopOutlined, WarningTwoTone } from '@ant-design/icons';
import { Badge, Button, Card, Col, Input, Progress, Radio, Row, Switch, Tag, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import { useContext, useState } from 'react';
import { useFetch } from '../../../../hooks/useFetch/useFetch';
import { useAuth } from '../../../../store/AuthContext/AuthContext';
import { getProjectStorage } from '../../../../store/storageContext';
import { AddressGroup, Categories, ContactGroup, Location, NameGroup, StoreCodeGroup } from '../../../components/model/business/FieldsGroup';
import Logs from '../../common/sideMenu/Logs/Logs';
import {GoogleEditPanel} from './GoogleEditPanel';
import './GoogleLocations.css';
import TableLZM from '../../../components/design/table/TableLZM';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import LogoPanel from './LogoPanel';
import PortadaPanel from './PortadaPanel';
import { DrawerContext } from '../../../../store/DrawerContext/DrawerContext';
import { GlobalVariableContext } from '../../../../store/GlobalVariableContext/GlobalVariableContext';
import { Synchronized } from '../../../components/design/Synchronized/Synchronized';
import { SCHEDULEDJOB } from '../../../../common/constants/services';
import { CategoriesView } from '../../../components/model/location/info/category/CategoriesView';
import { AlertsView } from '../../common/reports/AlertsView';
import { BlockInfoKpis } from '../../menuReports/blocks/BlockInfoKpis';
import { useIsAuthorized } from '../../common/roles/Roles';
import { STATUS } from '../../../components/model/business/OpenForBusiness';

export const GoogleBusiness = () => {

	const { t } = useTranslation();
	const { groupAccount } = useAuth();
	const [editPanelVisible, setEditPanelVisible] = useState(false);
	const [logoPanelVisible, setLogoPanelVisible] = useState(false);
	const [portadaPanel, setPortadaPanel] = useState(false);
	const [updateData, setUpdateData] = useState(false);
	const [selectedRowKeys, setSelectedRowKeys] = useState<any>([]);
	const [indeterminate, setIndeterminate] = useState(false);
	// const [logsVisible, setLogsVisible] = useState(false);
	const [locationsData, setLocationsData] = useState([]);
	const isAuthorized = useIsAuthorized();
	const { setLogsDrawer } = useContext(DrawerContext);
	
	const [syncStatesPercentatge, setSyncStatesPercentatge] = useState(0);

	const [isLoading, setIsLoading] = useState(true);

	const [filterStatus, setFilterStatus] = useState('HAS_VOICE_OF_MERCHANT');
	const [kpis, setKpis] = useState<any>();	

	const { setLocationDrawer } = useContext(DrawerContext)
	const { updateGlobalData } = useContext(GlobalVariableContext)

	const isUserAuthorized = isAuthorized(['ROLE_ADMIN', 'ROLE_AGENT', 'ROLE_CLIENT', 'ROLE_GUEST_INFO_EDIT']);

	useFetch(`${process.env.REACT_APP_API}/projects/` + getProjectStorage() + '/' + groupAccount + '/locations'
		+ `?status=${filterStatus}`,//&locality=${filterLocality}
		{},
		[updateData, updateGlobalData],
		(data) => {
			setIsLoading(false);
			setLocationsData(data.locations);			
			setSyncStatesPercentatge(data.syncStatesPercentatge);
			setKpis(data.kpis);
		}
	);

	const columns: any = [
		{/*
			//title: 'openForBusiness',
			key: ["openForBusiness","status"],						
			filters: [
				{ text: 'No Especificado', value: 'OPEN_FOR_BUSINESS_UNSPECIFIED' },
				{ text: 'Abierto', value: 'OPEN' },
				{ text: 'Cerrado Permanentemete', value: 'CLOSED_PERMANENTLY' },
				{ text: 'Cerrado', value: 'CLOSED_TEMPORARILY' },
			  ],
			render: (text: string, record: any) => (
				<></>
			)
			*/},
		{
			title: 'Establecimiento',
			key: 'location',						
			//sorter: (a: any, b: any) => a.location.locality?.localeCompare(b.location.locality),
			filterDropdown: ({
			  setSelectedKeys,
			  selectedKeys,
			  confirm,			  
			}: FilterDropdownProps) => {
			  return (
				<Input
				  autoFocus
				  //placeholder="Filtro"
				  value={selectedKeys[0]}
				  onChange={(e: any) => {
					setSelectedKeys(e.target.value ? [e.target.value] : []);
					confirm({ closeDropdown: false });
				  }}
				  onPressEnter={() => {
					confirm();
				  }}
				  onBlur={() => {
					confirm();
				  }}
				></Input>
			  );
			},
			filterIcon: () => {
			  return <SearchOutlined />;
			},
			onFilter: (value: any, record: any) => {
			  return record.location.title?.toLowerCase().includes(value?.toLowerCase()) 
			  || record.location?.administrativeArea?.toLowerCase().includes(value?.toLowerCase())
			  || record.location?.locality?.toLowerCase().includes(value?.toLowerCase());
			},
			render: (text: string, record: any) => (
				<Location record={record.location} drawer={true} onChange={() => {setLocationDrawer(record.location.composeId);}}/>									
			)			
		},			
		{
			title: 'Categorías',
			key: 'websiteUri',						
			width: '300px',
			render: (text: string, record: any) => (<Categories categories={record.location.categories} />)
		},	
		{
			title: t('dictionary.contact'),
			key: 'websiteUri',
			sorter: (a: any, b: any) => a.location.websiteUri.localeCompare(b.location.websiteUri),
			render: (text: string, record: any) => (<ContactGroup record={record.location} />)
		},		
		{
			title: 'Perfil',
			key: 'completedRatio',
			render: (text: string, record: any) => {				
				return  <Progress width={50} type="circle" percent={Math.round(record.completedRatio.ratio * 100) } />					
			},
			sorter: (a: any, b: any) => a.completedRatio.ratio - b.completedRatio.ratio,
		},
		{
			title: 'Alertas',
			key: 'alerts',
			dataIndex: 'completedRatio',
			sorter: (a: any, b: any) => a.completedRatio.ratio - b.completedRatio.ratio,
			render: (text: string, record: any) => {				
				return record.completedRatio.alerts.length > 0 && 				
					<AlertsView alerts={record.completedRatio.alerts}/>
			}
		},
		{
			title: '',
			key: 'locationId',			
			render: (text: string, record: any) => (
				<div style={{display:'flex', justifyContent:'space-between'}}>										
					<Button onClick={() => {setLocationDrawer(record.location.composeId);}} size='small' style={{ borderRadius: '20px', background:'var(--primary)',  border:`solid 1.3px RoyalBlue`, color: "white" }}>    
						<ShopOutlined style={{ color: 'white' }}/> 								
					</Button>
					<Button onClick={()=>{}} size='small' style={{ borderRadius: '20px', background:'var(--primary)',  border:`solid 1.3px RoyalBlue`, color: "white" }}>    
						<LineChartOutlined style={{ color: 'white' }}/> 								
					</Button>
				</div>
			)
		}
	]

	const onSelectChange = (selectedRowKeys: any) => {
		setSelectedRowKeys(selectedRowKeys);
		setIndeterminate(true);
	}

	const onSelectAllCustom = (selected: boolean) => {
		setSelectedRowKeys(selected ? locationsData.map((row: any) => row.locationName) : []);
		setIndeterminate(false);
	}

	const rowSelection = {
		selectedRowKeys,
		onChange: onSelectChange,
		columnTitle: <Checkbox indeterminate={indeterminate} onChange={(e) => onSelectAllCustom(e.target.checked)}></Checkbox>,
		getCheckboxProps: (record: any) => ({
			disabled: !record.location.status,
			locationName: record.locationName,
		}),
	}

	const hasSelected = selectedRowKeys.length > 0

	/* Locales - Gestión masiva */

	return (
		<>
			<Card className='main-card-content'>
				<h1 className='title-card'>Gestión de Fichas GBP</h1>
				<div className="card-container">
					{kpis && <BlockInfoKpis kpis={kpis}/>}
				</div>
				<div style={{ minHeight: '35px', marginBottom: '10px' }}>

					<Synchronized service={[SCHEDULEDJOB.GBP_LOCATIONS]}/>																								
						
					<div style= {{display:'flex', justifyContent:'space-between'}}>
						<div style= {{display:'flex', alignItems:'center'}}>
							<div>
								{									
									<b style={{ color: 'black', font: '26px' }}>Masivo {`${selectedRowKeys.length} de ${locationsData.length}`}:</b>											
								}					
							</div>			
							
							<Button type='link' onClick={() => setEditPanelVisible(true)} disabled={!hasSelected || !isUserAuthorized}>
								<b><EditOutlined /> {t('dictionary.edit')}</b>
							</Button>								
							<Button type='link' onClick={() => setPortadaPanel(true)} disabled={!hasSelected || !isUserAuthorized}>
								<b><FileImageOutlined /> {t('dictionary.cover')}</b>
							</Button>
							<Button type='link' onClick={() => setLogoPanelVisible(true)} disabled={!hasSelected || !isUserAuthorized}>
								<b><FileImageOutlined /> {t('dictionary.logo')}</b>
							</Button>
						</div>
						<div>	
						<div style= {{display:'flex', alignItems:'center'}}>		
							<Radio.Group size='small' buttonStyle="outline" value={filterStatus} onChange={(obj: any) => { setFilterStatus(obj.target.value) }}>
								<Radio.Button value="HAS_VOICE_OF_MERCHANT">{t('dictionary.verified.plural.female')}</Radio.Button>
								<Radio.Button value="HAS_BUSINESS_AUTHORITY,NOT_REGISTERED">{t('dictionary.no')} {t('dictionary.verified.plural.female')}</Radio.Button>
							</Radio.Group>														
							<Button type='link' onClick={() => setLogsDrawer(true)}>
								<b>{'Logs >'}</b>
							</Button>
							</div>
						</div>
					</div>					
				</div>				
				
				<TableLZM
					rowKey='locationName'
					scroll={{ x: true }}
					rowSelection={rowSelection}
					columns={columns}
					//exportable={true}
					dataSource={locationsData}
					loading={isLoading}
				/>
				{editPanelVisible && (
					<GoogleEditPanel
						selectedRowKeys={selectedRowKeys}
						visible={editPanelVisible}
						onFinish={() => {
							setEditPanelVisible(false);
							setUpdateData(!updateData);
							setTimeout(() => {
								setLogsDrawer(true);
							}, 500);
						}}
						onCancel={() => {
							setEditPanelVisible(false);
						}}
					/>)
				}
				{logoPanelVisible && (
					<LogoPanel
						selectedRowKeys={selectedRowKeys}
						visible={logoPanelVisible}
						onFinish={() => {
							setLogoPanelVisible(false);
							setUpdateData(!updateData);
							setTimeout(() => {
								setLogsDrawer(true);
							}, 500);
						}}
						onCancel={() => {
							setLogoPanelVisible(false);
						}}
					/>)
				}
				{portadaPanel && (
					<PortadaPanel
						selectedRowKeys={selectedRowKeys}
						visible={portadaPanel}
						onFinish={() => {
							setPortadaPanel(false);
							setUpdateData(!updateData);
							setTimeout(() => {
								setLogsDrawer(true);
							}, 500);
						}}
						onCancel={() => {
							setPortadaPanel(false);
						}}
					/>)
				}
				{/* {
					logsVisible && (
						<Logs logsVisible={logsVisible} setLogsVisible={setLogsVisible} />
					)
				} */}
			</Card>
		</>
	)
}