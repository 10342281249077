import { getColor } from "color-thief-react";
import { ColorFormats } from "color-thief-react/lib/types";

function isColorDark(hexColor: any): boolean {

    const r = parseInt(hexColor.substring(1, 3), 16);
    const g = parseInt(hexColor.substring(3, 5), 16);
    const b = parseInt(hexColor.substring(5, 7), 16);
  
    const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
      return luminance <= 0.5;

    // const brightness = (r * 299 + g * 587 + b * 114) / 1000;
    // return brightness <= 127.5;
  }

  export const loadImageColor = async () => {
    const imageURL = "https://cdn.pixabay.com/photo/2016/03/27/22/06/sea-1284467_1280.jpg";
    const color = await getColor(imageURL, 'hex' as ColorFormats, 'Anonymous');
    // console.log('Color en formato hexadecimal:', color);
    const isDark = isColorDark(color);
    // console.log(isDark ? 'El color es oscuro' : 'El color es claro');
    
  };