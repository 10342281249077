import { MailOutlined, PlusCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { Badge, Button, Card, Input, message, Switch, Table, Tag } from 'antd';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFetch } from '../../../../hooks/useFetch/useFetch';
import { authFetch } from '../../../../common/request/authFetch';
import UsersAddModal from './ModalUsers/UsersAddModal';
import UsersAssignProjectsModal from './ModalUsers/UsersAssignProjectsModal';
import './Users.scss';
import '../../pages.scss'
import TableLZM from '../../../components/design/table/TableLZM';
import { ButtonLZ } from '../../../components/design/Buttons/ButtonLZ';
import { DrawerContext } from '../../../../store/DrawerContext/DrawerContext';
import { FilterDropdownProps } from 'antd/lib/table/interface';


const Users = () => {

  const [addUserVisible, setAddUserVisible] = useState(false);
  const [updateData, setUpdateData] = useState(false);
  const [projectsPanelVisible, setProjectsPanelVisible] = useState(false);
  const [selectedUser, setSelectedUser] = useState({email:'', platforms:[]});  
  const { t } = useTranslation();
  const { setUserDrawer } = useContext(DrawerContext)

  const { data: usersData, isLoading } = useFetch(`${process.env.REACT_APP_API}/users?isApp=false`, {}, [updateData]);

  const sendEmail = (email: string) => {
    authFetch(`${process.env.REACT_APP_API}/users/`+ email + '/invitation', { method: 'POST' });
  }
  
  const setEnabled = (email: string, enabled: boolean) => {

    const uri = enabled 
    ? 
    `${process.env.REACT_APP_API}/users/`+ email + '/actions/unlock' 
    : 
    `${process.env.REACT_APP_API}/users/`+ email + '/actions/lock'

    authFetch(uri, { method: 'PATCH' })
    .then((isOk) => {
      if(isOk) {
        message.info(t('view.forms.modal.user.success'))
      } else {					
        message.error(t('view.forms.modal.user.error'))
      }
    }
  );
  }

  const columns = [{
		title: t('dictionary.identifier'),
		key: 'email',
		dataIndex: 'email',
    render: (value:any, record:any) => {
      return <a onClick={()=>{setUserDrawer(record.email)}}>{record.email}</a>
    },
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
    }: FilterDropdownProps) => {
      return (
        <Input
          autoFocus
          placeholder="Email"
          value={selectedKeys[0]}
          onChange={(e: any) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
            confirm({ closeDropdown: false });
          }}
          onPressEnter={() => {
            confirm();
          }}
          onBlur={() => {
            confirm();
          }}
        ></Input>
      );
    },
    filterIcon: () => {
      return <SearchOutlined />;
    },
    onFilter: (value: any, record: any) => {
      return (record?.email.toLowerCase().includes(value.toLowerCase()));
    }
	},
  {
		title: t('dictionary.business'),
		key: 'projects',
		dataIndex: 'projects',
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
    }: FilterDropdownProps) => {
      return (
        <Input
          autoFocus
          placeholder="Negocio"
          value={selectedKeys[0]}
          onChange={(e: any) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
            confirm({ closeDropdown: false });
          }}
          onPressEnter={() => {
            confirm();
          }}
          onBlur={() => {
            confirm();
          }}
        ></Input>
      );
    },
    filterIcon: () => {
      return <SearchOutlined />;
    },
    onFilter: (value: any, record: any) => {

      if (Array.isArray(record.projects)) {
        return record.projects.some((project: string) => project.toLowerCase().includes(value.toLowerCase()));
      }
      return false; 
    
    },
    render: (value: Array<string>, record: any) => {
      return (
      <>        
        <PlusCircleOutlined style={{fontSize:'18px', marginRight: '5px', color: 'grey'}} 
          onClick={() => {
            setProjectsPanelVisible(true);
            setSelectedUser(record);
        }}/>        
        {
          value && value.map(val => {
            return (<Tag key={val}>{val}</Tag>)
          })
        }
      </>
      )
    }
	},
  {
		title: t('dictionary.name'),
		key: 'name',
		dataIndex: 'name'
	},{
		title: t('dictionary.rolePosition'),
		key: 'summary',
		dataIndex: 'summary'
	},{
		title: t('dictionary.roles'),
		key: 'roles',
		dataIndex: 'roles',
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
    }: FilterDropdownProps) => {
      return (
        <Input
          autoFocus
          placeholder="Roles"
          value={selectedKeys[0]}
          onChange={(e: any) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
            confirm({ closeDropdown: false });
          }}
          onPressEnter={() => {
            confirm();
          }}
          onBlur={() => {
            confirm();
          }}
        ></Input>
      );
    },
    filterIcon: () => {
      return <SearchOutlined />;
    },
    onFilter: (value: any, record: any) => {

      if (Array.isArray(record.roles)) {
        return record.roles.some((rol: string) => rol.toLowerCase().includes(value.toLowerCase()));
      }
      return false; 
    
    },
    render: (value: Array<string>) => {
      return (
      <>
        {
          value && value.map(val => {
            return (<Tag key={val}>{val}</Tag>)
          })
        }
      </>
      )
    }
	},{
		title: t('dictionary.state'),
		key: 'status',
		dataIndex: 'status'
	},
  {
		title: t('dictionary.actions'),
		key: 'enabled',
		dataIndex: 'enabled',
    align: 'center',
    render: (value: boolean, record: any) => {      
      
      return record.status === 'CREATED' || record.status === 'TO_EMAIL' 
      ?
        <ButtonLZ color='black' size='small' disabled={record.status === 'CREATED'} onClick={() => {sendEmail(record.email); setUpdateData(!updateData)}}>
          <MailOutlined/>          
        </ButtonLZ>               
      :
      <Switch defaultChecked={value} size='small' onChange={() => {setEnabled(record.email, !value); setUpdateData(!updateData)}}/>          
    } ,
    filters: [
      { text: 'Activos', value: true },
      { text: 'Inactivos', value: false },
    ],
    onFilter: (value: any, record: any) => record.enabled === value,


	}]  

  /*Gestión de Usuarios */

  return (
    <>
      <Card className='main-card-content'>
        <h1  className='title-card'>{t('dictionary.usersManagement')}</h1>
        <div className="buttonWrapper" >
          <ButtonLZ color='yellow' shape='round' onClick={() => setAddUserVisible(true)} type='primary' >
            <PlusCircleOutlined/>{t('dictionary.newUser')}
          </ButtonLZ>
        </div>
        <TableLZM  columns={columns} dataSource={usersData} rowKey={'email'} loading={isLoading} />
        {addUserVisible && <UsersAddModal
          visible={addUserVisible}
          onFinish={() => {
            setAddUserVisible(false);
            setUpdateData(!updateData);
          }}
          />}
          {projectsPanelVisible && <UsersAssignProjectsModal
          selectedUser={selectedUser}
          visible={projectsPanelVisible}
          onFinish={() => {
            setProjectsPanelVisible(false);
            setUpdateData(!updateData);
          }}
        />}
      </Card>
    </>
	)
}

export default Users;