import { useState, createContext } from 'react';

export const GlobalVariableContext = createContext<{

    updateGlobalData: boolean, 
    setUpdateGlobalData: React.Dispatch<React.SetStateAction<boolean>>,
    updateGlobalImage: boolean, 
    setUpdateGlobalImage: React.Dispatch<React.SetStateAction<boolean>>,
    updateUserData: boolean, 
    setUpdateUserData: React.Dispatch<React.SetStateAction<boolean>>,

}>({

    updateGlobalData: false, 
    setUpdateGlobalData:() => {},
    updateGlobalImage: false, 
    setUpdateGlobalImage:() => {},
    updateUserData: false, 
    setUpdateUserData:() => {}

})

const GlobalVariableProvider: React.FC = ({ children }) => {


    const [updateGlobalData, setUpdateGlobalData] = useState(false);
    const [updateGlobalImage, setUpdateGlobalImage] = useState(false);
    const [updateUserData, setUpdateUserData] = useState(false);


    return (
        <GlobalVariableContext.Provider value={{updateGlobalData ,setUpdateGlobalData, updateGlobalImage, setUpdateGlobalImage, updateUserData ,setUpdateUserData }}>

            {children}
        </GlobalVariableContext.Provider>
    );
};


export default GlobalVariableProvider;


