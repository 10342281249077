import { MailOutlined, PlusCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { Badge, Button, Card, Input, message, Switch, Table, Tag } from 'antd';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFetch } from '../../../../hooks/useFetch/useFetch';
import { authFetch } from '../../../../common/request/authFetch';
import UsersAddModal from './ModalUsers/UsersAddModal';
import UsersAssignProjectsModal from './ModalUsers/UsersAssignProjectsModal';
import './Users.scss';
import '../../pages.scss'
import TableLZM from '../../../components/design/table/TableLZM';
import { ButtonLZ } from '../../../components/design/Buttons/ButtonLZ';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import { DrawerContext } from '../../../../store/DrawerContext/DrawerContext';
import moment from 'moment';
import { UserModule } from '../../common/Drawers/UserModule';


const AppUsers = () => {

  const [addUserVisible, setAddUserVisible] = useState(false);
  const [updateData, setUpdateData] = useState(false);
  const [projectsPanelVisible, setProjectsPanelVisible] = useState(false);
  const [selectedUser, setSelectedUser] = useState({email:'', platforms:[]}); 
  const { userDrawer, setUserDrawer } = useContext(DrawerContext)

  const { t } = useTranslation();

  const { data: usersData, isLoading } = useFetch(`${process.env.REACT_APP_API}/users?isApp=true`, {}, [updateData, userDrawer]);

  const sendEmail = (email: string) => {
    authFetch(`${process.env.REACT_APP_API}/users/`+ email + '/invitation', { method: 'POST' });
  }

  const setEnabled = (email: string, enabled: boolean) => {

    const uri = enabled 
    ? 
    `${process.env.REACT_APP_API}/users/`+ email + '/actions/unlock' 
    : 
    `${process.env.REACT_APP_API}/users/`+ email + '/actions/lock'

    authFetch(uri, { method: 'PATCH' })
    .then((isOk) => {
      if(isOk) {
        message.info(t('view.forms.modal.user.success'))
      } else {					
        message.error(t('view.forms.modal.user.error'))
      }
    }
  );
  }

  const columns = [{
		title: t('dictionary.identifier'),
		key: 'email',
		dataIndex: 'email',
    render: (value:any, record:any) => {
      return <a onClick={()=>{setUserDrawer(record.email)}}>{record.email}</a>
    },
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
    }: FilterDropdownProps) => {
      return (
        <Input
          autoFocus
          placeholder="Email"
          value={selectedKeys[0]}
          onChange={(e: any) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
            confirm({ closeDropdown: false });
          }}
          onPressEnter={() => {
            confirm();
          }}
          onBlur={() => {
            confirm();
          }}
        ></Input>
      );
    },
    filterIcon: () => {
      return <SearchOutlined />;
    },
    onFilter: (value: any, record: any) => {
      return (record?.email.toLowerCase().includes(value.toLowerCase()));

    }
	},
  {
		title: t('dictionary.business'),
		key: 'projects',
		dataIndex: 'projects',
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
    }: FilterDropdownProps) => {
      return (
        <Input
          autoFocus
          placeholder="Negocio"
          value={selectedKeys[0]}
          onChange={(e: any) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
            confirm({ closeDropdown: false });
          }}
          onPressEnter={() => {
            confirm();
          }}
          onBlur={() => {
            confirm();
          }}
        ></Input>
      );
    },
    filterIcon: () => {
      return <SearchOutlined />;
    },
    onFilter: (value: any, record: any) => {

      if (Array.isArray(record.projects)) {
        return record.projects.some((project: string) => project.toLowerCase().includes(value.toLowerCase()));
      }
      return false; 
    
    },
    render: (value: Array<string>, record: any) => {
      return (
      <>        
        <PlusCircleOutlined style={{fontSize:'18px', marginRight: '5px', color: 'grey'}} 
          onClick={() => {
            setProjectsPanelVisible(true);
            setSelectedUser(record);
        }}/>        
        {
          value && value.map(val => {
            return (<Tag key={val}>{val}</Tag>)
          })
        }
      </>
      )
    }
	},
  {
		title: t('dictionary.name'),
		key: 'name',
		dataIndex: 'name'
	},{
		title: t('dictionary.rolePosition'),
		key: 'summary',
		dataIndex: 'summary'
	},{
		title: t('dictionary.roles'),
		key: 'roles',
		dataIndex: 'roles',
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
    }: FilterDropdownProps) => {
      return (
        <Input
          autoFocus
          placeholder="Roles"
          value={selectedKeys[0]}
          onChange={(e: any) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
            confirm({ closeDropdown: false });
          }}
          onPressEnter={() => {
            confirm();
          }}
          onBlur={() => {
            confirm();
          }}
        ></Input>
      );
    },
    filterIcon: () => {
      return <SearchOutlined />;
    },
    onFilter: (value: any, record: any) => {

      if (Array.isArray(record.roles)) {
        return record.roles.some((rol: string) => rol.toLowerCase().includes(value.toLowerCase()));
      }
      return false; 
    
    },
    render: (value: Array<string>) => {
      return (
      <>
        {
          value && value.map(val => {
            return (<Tag key={val}>{val}</Tag>)
          })
        }
      </>
      )
    }
	},
    {
		title: 'Web',
		key: 'isActiveWeb',
		dataIndex: 'isActiveWeb',
        render: (isActiveWeb: boolean) => {
            return (
                isActiveWeb &&
                <Switch checked={isActiveWeb} unCheckedChildren='inactiva' checkedChildren='activa'/>
            )
          }
	},
    {
		title: 'Dominio',
		key: 'domainWeb',
		dataIndex: 'domainWeb'
	},
    {
		title: 'Color Web',
		key: 'colorWeb',
		dataIndex: 'colorWeb',
        render: (colorWeb: string) => {
            return (
                colorWeb &&
                <input
                value={colorWeb}
                type='color'
                style={{ width: '30px', height: '30px', marginTop: '5px', paddingTop: 0 }}
                disabled
            />
            )
          }
	},
    {
		title: t('dictionary.state'),
		key: 'status',
		dataIndex: 'status',
    filters: [
      { text: 'REGISTERED', value: 'REGISTERED' },
      { text: 'CONFIGURED', value: 'CONFIGURED' },
      { text: 'ACTIVE', value: 'ACTIVE' }
    ],
    onFilter: (value: any, record: any) => record.status === value,
	},
  {
		title: 'Teléfono',
		key: 'phone',
		dataIndex: 'phone'
	},
  {
		title: 'Módulos',
		key: 'modules',		
    align: 'center',
    render: (value: boolean, record: any) => {      
      
      return record.modules &&
      (
        <div>
          <div>
            <UserModule moduleId='ai' moduleInfo={record.modules.ai} onChange={() => {}}/>
          </div>
          <div>
            <UserModule moduleId='web' moduleInfo={record.modules.web} onChange={() => {}}/>            
          </div>
        </div>
      )
    }, 
  },
  {
  title: 'Creación',
  key: 'created',
  render: (value: boolean, record: any) => {   

    return <>{record.created && moment(record.created).format('L')}</>
  }
  },
  {
		title: t('dictionary.actions'),
		key: 'enabled',
		dataIndex: 'enabled',
    align: 'center',
    render: (value: boolean, record: any) => {      
      
      return record.status === 'CREATED' || record.status === 'TO_EMAIL' 
      ?
        <ButtonLZ color='black' size='small' disabled={record.status === 'CREATED'} onClick={() => {sendEmail(record.email); setUpdateData(!updateData)}}>
          <MailOutlined/>          
        </ButtonLZ>               
      :
      <Switch defaultChecked={value} size='small' onChange={() => {setEnabled(record.email, !value); setUpdateData(!updateData)}}/>          
    } ,
    filters: [
      { text: 'Activos', value: true },
      { text: 'Inactivos', value: false },
    ],
    onFilter: (value: any, record: any) => record.enabled === value,


	}]

  /*Gestión de Usuarios */
  
  return (
    <>
      <Card className='main-card-content'>
          <h1  className='title-card'>{'Usuarios de POCKET'}</h1>
        
        <TableLZM  columns={columns} dataSource={usersData} rowKey={'email'} loading={isLoading} />
        {addUserVisible && <UsersAddModal
          visible={addUserVisible}
          onFinish={() => {
            setAddUserVisible(false);
            setUpdateData(!updateData);
          }}
          />}
          {projectsPanelVisible && <UsersAssignProjectsModal
          selectedUser={selectedUser}
          visible={projectsPanelVisible}
          onFinish={() => {
            setProjectsPanelVisible(false);
            setUpdateData(!updateData);
          }}
        />}
      </Card>
    </>
	)
}

export default AppUsers;