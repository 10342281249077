import { Badge, Switch, message } from "antd"
import { authRequest } from "../../../../common/request/authRequest";
import { useState } from "react";

export const UserModule = (props: {userId?: string, moduleId: string, moduleInfo: any, onChange: any}) => {    
        
    const [paid, setPaid] = useState(props.moduleInfo.paid);

    const changeStatus = (status : boolean) => {
        
        authRequest(`${process.env.REACT_APP_API}/users/${props.userId}?moduleId=${props.moduleId}&paymentStatus=${status}`, { method: 'PATCH' })
        .then((response) => {            
            setPaid(status);
            message.success('Estado cambiado con éxito');
            props.onChange();
        }).catch(() => {
            message.error('Error inesperado');
        });
    }

    return (
        <>                        
            {props.moduleInfo.active ? 
                <div style={{display:'flex'}}>                    
                    {
                        props.userId && <Switch style={{marginRight:'5px'}} size='small' checkedChildren="Pagado" unCheckedChildren="Por pagar" defaultChecked={props.moduleInfo.paid} onChange={(e) => {changeStatus(e)}}/>                    
                    }
                    {paid ? 
                        <div style={{display:'flex'}}>
                            <Badge status="success" /><p> {props.moduleId} </p> 
                        </div>
                        :
                        <div style={{display:'flex'}}>
                            <Badge status="processing" /><p> {props.moduleId} </p> 
                        </div>                        
                    }
                </div>
                : 
                <p> <Badge status="default" /> {props.moduleId}</p>
            }
        </>
    )
}