import { t } from 'i18next';
import moment from 'moment';
import { FestiveSchedule } from './single/FestiveSchedule';


export const SpecialSchedule = (props: { location: any }) => {

    return (
        <div>            
            <span>
                <b className="title-box">Festivos</b>
            </span>

            <div style={{ margin: "1em" }}>
                
                {props.location.specialHours && Object.entries(props.location.specialHours).map(([date, periods]) => {
                    return (
                        <div key={date}>
                            <div style={{ display: "flex", flexDirection: 'column', justifyContent: "flex-start", marginTop:'10px' }}>                                                                        
                                <FestiveSchedule date={date} periods={periods} locationName={props.location.name}/>                                                                            
                            </div>
                        </div>
                    );
                })}
            </div>               
        </div >
    );
};