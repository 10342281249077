import { Button, Form, message, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../../../../store/AuthContext/AuthContext';
import { authFetch } from '../../../../../common/request/authFetch';
import { getProjectStorage } from '../../../../../store/storageContext';
import FormItemPublishPhotoType from '../../../../components/model/business/forms/FormItemPublishPhotoType';
import FormItemUploadPhoto from '../../../../components/model/business/forms/FormItemUploadPhoto';
import { useState } from 'react';



const PostImages = ({
    location,
    visible,
    onCancel,
    onFinish,
}: any) => {

    const { groupAccount } = useAuth();
    const [form4] = Form.useForm();
    const { t } = useTranslation();

    const [isButtonDisabled, setIsButtonDisabled] = useState(true);

    const handleFormChange = () => {
        const values = form4.getFieldsValue();
        const { mediaType, photo } = values;
            if (mediaType && photo?.fileList?.length > 0) {
          setIsButtonDisabled(false);
        } else {
          setIsButtonDisabled(true);
        }
      };      

    const submitMedia = async (values: any) => {

        const url = values.photo.fileList[0].url;

        const data = {
            category: values.mediaType,
            photoURL: url,
            locationsNames: [location.name]
        };
        massivePublish(data, 'localPostMedia');
    };

    const massivePublish = async (data: any, type: string) => {
        await authFetch(`${process.env.REACT_APP_API}/projects/` + getProjectStorage() + '/' + groupAccount + '/locations/' + type, { method: 'POST', data: data })
            .then((res) => {
                if (res) {
                    message.loading(t('view.forms.modal.bulk.triggered'), 5);
                } else {
                    message.error(t('view.forms.modal.bulk.error'), 5);
                }
            });
        onFinish();
    }

    return (
        <Modal visible={visible} onCancel={onCancel} footer={null} width={'45%'}>

            <h2 style={{ paddingTop: '5px', margin:0, paddingRight: '15px' }}>Pulicación de fotos</h2>
            {location.locality ? <small > {location.locality}, {location.administrativeArea}, {location.addressLine}</small> : <small>Sin ubicación</small>}

            <Form layout='vertical' form={form4} onFinish={submitMedia} style={{marginTop:'10px'}}  onValuesChange={handleFormChange}
>                <FormItemPublishPhotoType />
                <FormItemUploadPhoto maxPhotos={5} />
                <div style={{display:'flex', justifyContent:'flex-end'}} >
                <Button shape='round' type='primary' onClick={form4.submit} disabled={isButtonDisabled}>
                    Publicar foto
                </Button>
                </div>
            </Form>

        </Modal>
    )
}

export default PostImages;

