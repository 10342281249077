import { Badge, Card, Col, Divider, Rate, Row, Tag } from "antd";
import { RingProgress } from "@ant-design/charts";
import { ShopOutlined } from "@ant-design/icons";
import infoIcon from '../../../../assets/icons/HOME-ICON-light.png';
import MathUtils from "../../../../common/utils/MathUtils";

export const BlockInfoKpis = (props: {kpis: any}) => {    
    
    const configProgress: any = {
        height: 75,
        width: 75,
        autoFit: false,
        color: ['#5B8FF9', '#E8EDF3'],
    };

    return (
        <>
            <Row gutter={36} style={{ marginBottom: '20px' }}>
                <Col span={8}>
                    <Card bodyStyle={{ backgroundColor: 'white', borderRadius: '25px', height: '150px' }}>
                        <div className="card-kpis">
                            <h1>Locales</h1>
                        </div>
                        <div className="locations-container" >
                            <img src={infoIcon} style={{height:'60px', borderRadius: '17px'}}/>                                    
                            <span >
                                {props.kpis === null ? '-' : props.kpis?.numberOfLocations}
                            </span>
                        </div>
                        {/*<div style={{color:'grey'}}><Badge status="warning"/> {props.kpis?.closed} cerrados</div>
                        <div style={{color:'grey'}}><Badge status="error"/> {props.kpis?.closedPermanently} cerrados permanentemente</div>*/}
                    </Card>
                </Col>
                <Col span={8}>
                    <Card bodyStyle={{ backgroundColor: 'white', borderRadius: '25px', height: '150px' }}>
                        <div className="title-charts">
                            <h1>Estado</h1>
                        </div>
                        <div style={{display:'flex', justifyContent:'center'}}>
                            <span style={{ fontSize: '28px' }}>
                                <Row>
                                    <Col>
                                        <Tag style={{ verticalAlign: 'middle', borderRadius: '8px' }} color="steelblue"><b>Verificados</b></Tag>
                                    </Col>
                                    <Col>
                                        <b style={{ fontSize: '25px' }}>{props.kpis === null ? '-' : props.kpis?.verified}</b>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Tag style={{ verticalAlign: 'middle', borderRadius: '8px' }} color="grey"><b>Sin verificar</b></Tag>
                                    </Col>
                                    <Col>
                                        <b style={{ fontSize: '25px' }}>{props.kpis === null ? '-' : props.kpis?.notVerified}</b>
                                    </Col>
                                </Row>
                            </span>
                        </div>
                    </Card>
                </Col>
                <Col span={8}>
                    <Card bodyStyle={{ backgroundColor: 'white', borderRadius: '25px', height: '150px' }}>
                        <div className="title-charts">
                            <h1>Info Completada</h1>
                        </div>
                        <div className="values-kpis">                        
                            <RingProgress percent={MathUtils.round(props.kpis?.completedListing, 1)} {...configProgress} />
                        </div>
                    </Card>
                </Col>
            </Row>                    
        </>
    )
}