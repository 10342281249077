import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { ButtonLZ } from '../../design/Buttons/ButtonLZ';
import {useRef, useState} from 'react'
import { SyncOutlined } from '@ant-design/icons';


const PDFGenerator = (props: {element: any}) => {
  
  const buttonRef:any = useRef();
  const [isGenerating, setIsGenerating] = useState(false);	

  const generatePDF = () => {
    //const element:any = document.getElementById('element-to-capture');
    
    
   
    const button:any = buttonRef.current;
    //button.style.display = 'none';
    setIsGenerating(true);

    html2canvas(props.element.current).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        var pdf = new jsPDF('p', 'pt', [canvas.width, canvas.height]);
        
        //const imgProps = pdf.getImageProperties(imgData);

          
        const pdfWidth = pdf.internal.pageSize.getWidth();
        //const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        const pdfHeight = pdf.internal.pageSize.getHeight();

        pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight, '', 'FAST');
        pdf.save('documento.pdf');
        
        setIsGenerating(false);

        //button.style.display = 'block';

      });

  }

    return (
      <div>
        {isGenerating ?
          <SyncOutlined spin/>
          :
          <ButtonLZ ref={buttonRef} onClick={generatePDF}>Generar PDF</ButtonLZ>
        }
      </div>
    );
  
}

export default PDFGenerator;
