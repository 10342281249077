import { Divider } from 'antd';
import { Web } from '../location/contact/web/Web';
import { PhoneGroup } from '../location/contact/phone/PhoneGroup';

const ContactTab = ({ location, locationsIds }:any) => {
    return (
        <>
        <Web
            url={location.websiteUri}
            locationsIds={locationsIds} 
            />
        <Divider orientation="left" style={{ margin: "15px" }} plain></Divider>
        <PhoneGroup primaryPhone={location.primaryPhone ? location.primaryPhone : ''} additionalPhones={location.additionalPhones ? location.additionalPhones : []} locationsIds={locationsIds} />
        </>
    );
};

export default ContactTab;