const TOKEN_NAME = 'localizame_token';

export const storeToken = (token: string) => {
  localStorage.setItem(TOKEN_NAME, token);
}

export const getTokenForStorage = () => {
  const token = localStorage.getItem(TOKEN_NAME);

  return token;
}

export const removeToken = () => {
  localStorage.removeItem(TOKEN_NAME);
}