import { ButtonLZ } from "../../design/Buttons/ButtonLZ";
import SwiperCarousel from "../../design/Swiper/Swiper";
import photo from "../../../../assets/background/photo_icon.png"

const PhotosTab = ({ mediaItems, setPostImagesVisible }: any) => {
    return (
        <div>
            {mediaItems && mediaItems.length > 0 ? (
                <SwiperCarousel onClick={() => { setPostImagesVisible(true) }} mediaItems={mediaItems} />
            ) : (
                <div
                    style={{
                        position: 'relative',
                        height: '300px',
                        justifyContent: 'center',
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <h1 style={{ color: '#AAADAE', fontWeight: 700, fontSize: '40px' }}>
                        No tienes fotos!
                    </h1>
                    <ButtonLZ color="yellow"  onClick={() => { setPostImagesVisible(true) }} >Subir fotos</ButtonLZ>
                    
                    {/* <img src={circleGreen} style={{ position: 'absolute', width: '40px', bottom: '15%', left: '15%' }} />
                    <img src={donutYellow} style={{ position: 'absolute', width: '270px', bottom: 0, right: 0 }} /> */}
                    <img src={photo} style={{ position: 'absolute', width: '100px', top: '5%', right: '5%' }} />
                    <img src={photo} style={{position: 'absolute',width: '70px',bottom: '20%',left: 0, transform: 'rotate(-50deg)' }}/>
               
                    {/* <img src={bluePill} style={{ position: 'absolute', width: '80px', bottom: '10%', right: 0, transform: 'rotate(100deg)'}} /> */}
                </div>
            )}
        </div>
    );
};

export default PhotosTab;