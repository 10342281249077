import React from 'react';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';

const Selector: React.FC = () => {
  const { i18n } = useTranslation();

  return (
    <Select
      defaultValue={i18n.resolvedLanguage}
      bordered={false}
      onChange={(value: string) => i18n.changeLanguage(value)}
      options={[
        {
          value: 'es',
          label: '🇪🇸 Español',
        },
        {
          value: 'en',
          label: '🇬🇧 English',
        }
      ]}
    />
  );
}

export default Selector;