import { CloseCircleOutlined, DeleteFilled, EyeOutlined, LineChartOutlined, MailOutlined, MinusCircleOutlined, NotificationOutlined, PlusCircleOutlined, PlusOutlined, SearchOutlined, ShopOutlined, StarOutlined, UserAddOutlined, UserOutlined } from '@ant-design/icons';
import { Badge, Button, Card, Input, message, Spin, Switch, Table, Tag, Tooltip } from 'antd';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import { DrawerContext } from '../../../store/DrawerContext/DrawerContext';
import { useFetch } from '../../../hooks/useFetch/useFetch';
import { authFetch } from '../../../common/request/authFetch';
import { ButtonLZ } from '../../components/design/Buttons/ButtonLZ';
import TableLZM from '../../components/design/table/TableLZM';
import UsersAddModal from '../menuAdmin/4_1_users/ModalUsers/UsersAddModal';
import UsersAssignProjectsModal from '../menuAdmin/4_1_users/ModalUsers/UsersAssignProjectsModal';
import '../pages.scss'
import { GuestAddModal } from './guests/GuestAddModal';
import { getProjectStorage } from '../../../store/storageContext';
import { authRequest } from '../../../common/request/authRequest';
import { GuestAssignModal } from './guests/GuestAssignModal';
import { useAuth } from '../../../store/AuthContext/AuthContext';
import { customRequest } from '../../../common/request/customRequest';

export const GuestPermissions = () => {

  const { profile} = useAuth();

  const [groupSelected, setGroupSelected] = useState(null);  
  const [updateData, setUpdateData] = useState(false);  

  const [groups, setGroups] = useState();    

  const { isLoading } = useFetch(`${process.env.REACT_APP_API}/config/projects/${getProjectStorage()}`,
		{},
		[updateData],
		(data) => {			
			setGroups(data);		      	      
		}
	);
  
  const deassignUser = async (groupId: string, userId: string) => {
		    
    const data = {
			group: groupId,
      project: getProjectStorage(),	
			guestEmail: userId
		};		

		await authRequest(`${process.env.REACT_APP_API}/config/guest/deassign`, { method: 'PATCH', data: data })
      .then((data) => {
      setUpdateData(!updateData);
      message.success('Usuario desvinculado correctamente');
    }).catch((error) => {
      message.error('Error imprevisto');
    });
	};	

  const columns: any = [
  {
    title: 'Grupo',
    key: 'name',
    dataIndex: 'name',
    width:'30%',
    align: 'right',
    render: (text: string, record: any) => {				
      return  <Card bodyStyle={{background:'white', borderRadius: '15px'}}>
                <b>{record.name}</b>
              </Card>
    }
  },
  {
    title: 'Permisos',
    key: 'guests',    
    align:'left',
    //sorter: (a: any, b: any) => a.completedRatio.ratio - b.completedRatio.ratio,
    render: (text: string, record: any) => {				
      return  <>
                {record.guests.length == 0 ?
                <small style={{color:'grey'}}>No hay invitados</small>      
                :
                record.guests.map((guest: any) => (
                  <div> 
                    <Tag style={{borderRadius:'5px', margin:'4px'}} color={guest.enabled && guest.editInfo && 'blue'}><div><ShopOutlined/></div></Tag>
                    <Tag style={{borderRadius:'5px', margin:'4px'}} color={guest.enabled && guest.editReviews &&'blue'}><div><StarOutlined/></div></Tag>
                    <Tag style={{borderRadius:'5px', margin:'4px'}} color={guest.enabled && guest.editContent && 'blue'}><div><NotificationOutlined/></div></Tag>                    
                    <b style={{color:guest.enabled?'steelblue':'lightgrey', marginLeft:'80px'}}>
                      {guest.email}
                      <Tooltip title='Desvincular Usuario'>
                           <MinusCircleOutlined disabled={!profile.roles.includes('ROLE_MODULE_USERS')} style={{ margin: '10px' }} onClick={()=>{deassignUser(record.id, guest.email)}}/>                        
                      </Tooltip>
                    </b>
                  </div>
                ))
              }
      </>
    }
  },
  {
    title: '',    
    width: '100px',
    render: (record: any) => (
      <div style={{display:'flex', justifyContent:'space-between'}}>										        
        <Button onClick={()=>{setGroupSelected(record)}} size='small' style={{ borderRadius: '20px', background:'var(--primary)',  border:`solid 1.3px RoyalBlue`, color: "white" }}>    
          <UserAddOutlined disabled={!profile.roles.includes('ROLE_MODULE_USERS')} style={{ color: 'white' }}/> Vincular usuario
        </Button>
      </div>
    )
  }
]
  
  return (
    <>
      <Card className='main-card-content'>
        <h1  className='title-card'>Permisos por Grupo</h1>                
        {isLoading && <div style={{display:'flex', justifyContent:'center'}}><Spin style={{margin:'200px'}}/></div>}
        {!isLoading && <TableLZM  columns={columns} dataSource={groups} rowKey={'email'} loading={isLoading} />}        
      </Card>

      {groupSelected && <GuestAssignModal
          group={groupSelected}
          onFinish={() => {
            setGroupSelected(null);
            setUpdateData(!updateData);
          }}
        />}           
    </>
	)
}
