import { CalendarOutlined, NotificationOutlined, PictureOutlined, TagOutlined } from '@ant-design/icons';
import { Tag, Button, Form, message, Modal, Tabs, Input, Alert } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../../../store/AuthContext/AuthContext';
import { getProjectStorage } from '../../../../store/storageContext';
import { authFetch } from '../../../../common/request/authFetch';
import FormItemCallToAction from '../../../components/model/business/forms/FormItemCallToAction';
import FormItemEvent from '../../../components/model/business/forms/FormItemEvent';
import FormItemOffer from '../../../components/model/business/forms/FormItemOffer';
import FormItemSummary from '../../../components/model/business/forms/FormItemSummary';
import FormItemTitle from '../../../components/model/business/forms/FormItemTitle';
import FormItemUploadPhoto from '../../../components/model/business/forms/FormItemUploadPhoto';

const GooglePublishPanel = ({
	selectedRowKeys,
	visible,
	onCancel,
	onFinish,
}: any) => {

	const { groupAccount } = useAuth();
	const [form1] = Form.useForm();
	const [form2] = Form.useForm();
	const [form3] = Form.useForm();
	const { t } = useTranslation();

	const submitStandard = async (values: any) => {

		const data = {
			summary: values.summary,
			callToActionType: values.callToActionType,
			callToActionURL: values.callToActionURL,		
			callToActionURLLocal: values.callToActionURLLocal,	
			locationsNames: selectedRowKeys,
			photosURL: values.photo != null ? 
			values.photo.fileList.map((file: { url: any; }) => file.url)
			:
			[]
		};

		massivePublish(data, 'localPostStandard');			
	};

	const submitOffer = async (values: any) => {
		debugger;
		const data = {					
			summary: values.summary,
			eventTitle: values.title,
			eventScheduleStart: values.dateRange[0].format('YYYY-MM-DDTHH:mm[Z]'),
			eventScheduleEnd: values.dateRange[1].format('YYYY-MM-DDTHH:mm[Z]'),	
			offerCouponCode: values.code,
			offerRedeemOnlineUrl: values.redeemOnlineUrl,
			offerTermsConditions: values.termsAndConditions,
			locationsNames: selectedRowKeys,
			photosURL: values.photo != null ? 
			values.photo.fileList.map((file: { url: any; }) => file.url)
			:
			[]
		};
		
		massivePublish(data, 'localPostOffer');			
	};

	const submitEvent = async (values: any) => {		
	
		const data = {
			summary: values.summary,
			eventTitle: values.title,
			eventScheduleStart: values.dateRange[0].format('YYYY-MM-DDTHH:mm[Z]'),
			eventScheduleEnd: values.dateRange[1].format('YYYY-MM-DDTHH:mm[Z]'),
			callToActionType: values.callToActionType,
			callToActionURL: values.callToActionURL,				
			locationsNames: selectedRowKeys,
			photosURL: values.photo != null ? 
			values.photo.fileList.map((file: { url: any; }) => file.url)
			:
			[]
		};

		massivePublish(data, 'localPostEvent');			
	};

	const massivePublish = async (data : any, type: string) => {
		await authFetch(`${process.env.REACT_APP_API}/projects/` + getProjectStorage() + '/' + groupAccount + '/locations/' + type, { method: 'POST', data: data })
		.then((res) => {
			if (res) {	
				message.loading(t('view.forms.modal.bulk.triggered'), 5);											
			} else {
				message.error(t('view.forms.modal.bulk.error'), 5);							
			}
		});
		onFinish();	
	}

  return (
		<Modal visible={visible} onCancel={onCancel} footer={null} width={'60%'} centered style={{margin:'5px'}} bodyStyle={{minHeight:'90vh'}}> 
			
			<div style={{position:'relative'}}>
				<h2 style={{paddingTop:'5px', paddingBottom:'5px', paddingRight:'15px'}}>{t('dictionary.bulkPostPublish')}</h2>
				<Alert message={<div>{selectedRowKeys.length} locales seleccionados</div>} type="info" showIcon />
			</div>

			<Tabs defaultActiveKey="1" type="line" style={{overflow:'visible'}}>
				<Tabs.TabPane id='tabs' tab={<span className='title-tabPane'><NotificationOutlined/>{t('dictionary.novelty')}</span>} key="1">
					<Form layout='vertical' form={form1} onFinish={submitStandard}>
						<FormItemSummary isRequired={true} form={form1} type='Novedad'/>
						<FormItemUploadPhoto maxPhotos={1}/>				
						<FormItemCallToAction/>													
					</Form>
					<Button style={{float:'right'}} shape='round' type='primary' onClick={form1.submit}>
						{t('dictionary.publish')} {t('dictionary.novelty')}
					</Button>
				</Tabs.TabPane>
				<Tabs.TabPane tab={<span className='title-tabPane'><TagOutlined/>{t('dictionary.offer')}</span>} key="2">
					<Form layout='vertical' form={form2} onFinish={submitOffer}>												
						<FormItemTitle/>
						<FormItemSummary isRequired={false} form={form2} type='Oferta'/>
						<FormItemEvent/>
						<FormItemUploadPhoto maxPhotos={1}/>
						<FormItemOffer/>
						<Button style={{float:'right'}} shape='round' type='primary' onClick={form2.submit}>
							{t('dictionary.publish')} {t('dictionary.offer')}
						</Button>
					</Form>
				</Tabs.TabPane>
				<Tabs.TabPane tab={<span className='title-tabPane'><CalendarOutlined/>{t('dictionary.event')}</span>} key="3">
					<Form layout='vertical' form={form3} onFinish={submitEvent}>
						<FormItemTitle/>
						<FormItemSummary isRequired={false} form={form3} type='Evento' />
						<FormItemEvent/>
						<FormItemCallToAction/>
						<FormItemUploadPhoto maxPhotos={1}/>
						<Button style={{float:'right'}} shape='round' type='primary' onClick={form3.submit}>
							{t('dictionary.publish')} {t('dictionary.event')}
						</Button>
					</Form>
				</Tabs.TabPane>
			</Tabs>			
		</Modal>
	)
}

export default GooglePublishPanel;