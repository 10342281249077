import { InfoCircleOutlined } from '@ant-design/icons';
import { Checkbox, Collapse, Form, Input, Select } from 'antd';
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

const FormItemCallToAction = () => {		

	const [isActionTypeRequired, setActionTypeRequired] = useState(false);
	const [isLocalURLSelected, setLocalURLSelected] = useState(false);
	const { t } = useTranslation();

  	return (		
		<Collapse ghost>						
			<Collapse.Panel key="1" style={{background:'#f5f9fb', margin:'15px 0px'}} header={<>
				<b>{t('view.forms.header.addAction')}</b>
				<small style={{marginLeft:'10px', color:'darkgrey'}}>
					{t('view.forms.header.fieldset')}
				</small>
			</>}>
				<Form.Item initialValue={''} 
				label={t('view.forms.fields.type.label')} 
				name='callToActionType' 
				rules={[{ required: false}]}			
				tooltip={{ title: t('view.forms.fields.type.tooltip'), icon: <InfoCircleOutlined /> }}>
					<Select onChange={() => setActionTypeRequired(true)}>															
						<Select.Option value='BOOK'>
							{t('dictionary.book')}
						</Select.Option>
						<Select.Option value='ORDER'>
							{t('dictionary.order')}
						</Select.Option>
						<Select.Option value='SHOP'>
							{t('dictionary.shop')}
						</Select.Option>
						<Select.Option value='LEARN_MORE'>
							{t('dictionary.learnMore')}
						</Select.Option>
						<Select.Option value='SIGN_UP'>
							{t('dictionary.signUp')}
						</Select.Option>
						<Select.Option value='CALL'>
							{t('dictionary.call')}
						</Select.Option>
					</Select>
				</Form.Item>	
				<Form.Item 
					label={t('view.forms.fields.link.label')} name='callToActionURL' 
					tooltip={{ title: t('view.forms.fields.link.tooltip'), icon: <InfoCircleOutlined /> }}
					rules={[{ required: isActionTypeRequired && !isLocalURLSelected, type: 'url', message: t('view.forms.fields.link.rule') as string }]
					}>
					<Input disabled={!isActionTypeRequired || isLocalURLSelected} placeholder={t('view.forms.fields.link.inputPlaceholder') as string}/>
				</Form.Item>	
				<Form.Item name='callToActionURLLocal' valuePropName="checked" style={{marginLeft:'15%'}}>
					<Checkbox disabled={!isActionTypeRequired} value={isLocalURLSelected} onChange={(e)=>{setLocalURLSelected(e.target.checked)}}>
						<i>
						<Trans t={t} i18nKey="view.forms.fields.link.info">
							Usar la <b>web</b> de la ficha como URL de enlace
						</Trans>
						</i>
					</Checkbox>
				</Form.Item>	
			</Collapse.Panel>	
		</Collapse>			
	)
}

export default FormItemCallToAction;