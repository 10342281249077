import { Form, Input, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { ButtonLZ } from '../../../design/Buttons/ButtonLZ';
import { authRequest } from '../../../../../common/request/authRequest';
import { getProjectStorage } from '../../../../../store/storageContext';
import { useAuth } from '../../../../../store/AuthContext/AuthContext';
import { useState } from 'react';
import { EmojiPickerCustom } from '../../../design/EmojiPicker/EmojiPickerCustom';

const FormItemSummary = (props: { isRequired?: boolean, form: any, type: string }) => {

	const { t } = useTranslation();
	const { groupAccount } = useAuth();
	const [isLoading, setIsLoading] = useState(false);
	const [phrase, setPhrase] = useState('');

	const getResponseIa = async () => {
		setIsLoading(true)
		await authRequest(`${process.env.REACT_APP_API}/projects/` + getProjectStorage() + `/${groupAccount}` + `/post?prompt=${encodeURIComponent(`
		 .El contenido de la publicación es para \`\`\`${props.type}\`\`\`
		 .El tema de la publicación tiene es sobre: \`\`\`${phrase}\`\`\``)}`, {}).then((data) => {
			handleOpenTextArea(data)
			setIsLoading(false)
		}).catch((error) => {
			setIsLoading(false)
		});

	}

	const handleOpenTextArea = (responseText: any) => {
		props.form.setFieldsValue({ summary: responseText });
	};

	const [showPicker, setShowPicker] = useState(false);

	const [cursorIndex, setCursorIndex] = useState(0);

	const handleFocus = (event: any) => {
		setShowPicker(false);
	}

	const handleBlur = (event: any) => {
		setCursorIndex(event.target.selectionStart);
	};

	const handleEmojiClick = (emoji: any) => {

		const textArea = props.form.getFieldValue('summary');
		const textAreaWithEmoji =
			textArea.slice(0, cursorIndex) +
			emoji.native +
			textArea.slice(cursorIndex);
		props.form.setFieldsValue({ summary: textAreaWithEmoji });
	};


	return (

		<div style={{position:'relative'}}>
			
			<Form.Item
				label={t('view.forms.fields.details.label')}
				name={'summary'}
				rules={[{ required: props.isRequired, message: t('view.forms.fields.details.required') as string }]}
				style={{ marginBottom: '3px' }}
			>
				<Input.TextArea rows={4} placeholder={t('view.forms.fields.details.placeholder') as string} />
			</Form.Item>

			<div style={{ position: 'absolute', zIndex: 999, top: '80%', left:'0%'}}>
				<EmojiPickerCustom handleEmojiClick={handleEmojiClick} showPicker={showPicker} setShowPicker={setShowPicker} />
			</div>


			<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', }}>


				<div style={{ display: 'flex', gap: '5px' }}>
					<Input
						placeholder='Contenido principal del post..' style={{ width: '250px', height: '25px', color: 'grey' }} onChange={(e) => { setPhrase(e.target.value) }} />
					<ButtonLZ
						disabled={!phrase.trim()}
						onClick={() => {
							getResponseIa();
						}}
						style={{ width: '40px' }}
						size='small' color='dark-white'>{isLoading ? <span style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><Spin indicator={<LoadingOutlined spin />} /></span> : t('reviews.page.reply.review.btn.ia')}</ButtonLZ>
				</div>
			</div>



		</div>
	)
}

export default FormItemSummary;