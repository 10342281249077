import React, { useState } from 'react';
import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'
import { SmileOutlined } from '@ant-design/icons';
import { useTranslation } from "react-i18next";

export const EmojiPickerCustom = ({showPicker, setShowPicker, handleEmojiClick}:any) => {

  const { i18n } = useTranslation();

  return (
    <div >
      <a style={{fontSize:'20px'}} onClick={() => setShowPicker(!showPicker)}><SmileOutlined style={{color: 'grey'}}/></a>
      {showPicker && (
        <div>
          <Picker 
          data={data} 
          locale={i18n.language === "es-ES" ? "es" : i18n.language}
          onEmojiSelect={(e:any)=>{handleEmojiClick(e)}} onClickOutside={() => setShowPicker(!showPicker)} skinTonesDisabled={true} searchDisabled={true} />
        </div>
      )}
    </div>
  );
};

