import { useTranslation } from 'react-i18next';
import { Period } from '../components/Period';

export const FestiveScheduleView = (props: {
	data: any
}) => {
	
	const { t } = useTranslation();

	return (
		<div>
			{props.data[0]?.closed ? 
                        <b style={{color:'LightSalmon'}}>Cerrado</b>
                        :
                        props.data.map((period: any, i: number) => (                    
                            <Period data={period} key={i} />
                        ))
			}
		</div>
	)
}
