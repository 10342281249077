import React from 'react';
import { Layout } from 'antd';
import { useAuth } from '../../../../store/AuthContext/AuthContext';
import LogoLocalizame from '../../../components/model/logos/LogoLocalizame';

let bgColor = "#ddf4ff"
if(process.env.REACT_APP_DEVELOPMENT_COLOR) {
	bgColor = process.env.REACT_APP_DEVELOPMENT_COLOR
}

const { Header } = Layout;

const MainHeaderPublic = () => {

	const { logOut, isAuth} = useAuth();	
	
	return (
		<Header style={{ background: bgColor, display: 'flex', justifyContent: 'space-between', padding: '0px 25px', borderBottom: '2px solid #d9e9f7' }}>
			<LogoLocalizame style={{ display: 'inline', width: '220px', marginRight:'20px', marginLeft:'10px' }} />																
		</Header>
	)
};

export default MainHeaderPublic;