import {useContext } from "react";
import { DrawerContext } from "../../../../store/DrawerContext/DrawerContext";
import LocationDrawer from "./LocationDrawer";
import { ReviewsDrawer } from "./ReviewsDrawer";
import { PostsDrawer } from "./PostsDrawer";
import Logs from "../../../pages/common/sideMenu/Logs/Logs";
import { QuestionsDrawer } from "./QuestionsDrawer";
import { UserDrawer } from "../../../pages/common/Drawers/UserDrawer";
import { LocationStatisticsDrawer } from "../../../pages/menuReports/reviews/location/LocationStatisticsDrawer";




const AllDrawers = () => {

  const {locationDrawer, locationStatisticsDrawer, reviewsDrawer, questionsDrawer, postsDrawer, logsDrawer, userDrawer} = useContext(DrawerContext)
  
  return (
    <>
        {locationDrawer && <LocationDrawer/>}
        {locationStatisticsDrawer && <LocationStatisticsDrawer/>}
        {reviewsDrawer && <ReviewsDrawer/>}
        {questionsDrawer && <QuestionsDrawer/>}
        {postsDrawer && <PostsDrawer/>}
        {logsDrawer && <Logs/>}
        {userDrawer && <UserDrawer/>}
    </>
  )
}

export default AllDrawers;