
import { t } from 'i18next';
import { useState } from 'react';
import { EditOutlined } from '@ant-design/icons';
import { RegularDayScheduleView } from '../../business/forms/RegularDayScheduleView';
import { Tag } from 'antd';
import { RegularWeekScheduleEdit } from './single/RegularWeekScheduleEdit';
import { RegularWeekScheduleEditLJ } from './single/RegularWeekScheduleEditLJ';
import { RegularWeekScheduleEditLV } from './single/RegularWeekScheduleEditLV';
import { RegularWeekScheduleEditLS } from './single/RegularWeekScheduleEditLS';
import { useIsAuthorized } from '../../../../pages/common/roles/Roles';

const NormalSchedule = ({ location }: any) => {
    
    const [edit, setEdit] = useState(false);
    const [editLJ, setEditLJ] = useState(false);
    const [editLV, setEditLV] = useState(false);
    const [editLS, setEditLS] = useState(false);
    const [editVisible, setEditVisible] = useState(false);
    const isAuthorized = useIsAuthorized();

    const isUserAuthorized = isAuthorized(['ROLE_ADMIN', 'ROLE_AGENT', 'ROLE_CLIENT', 'ROLE_GUEST_INFO_EDIT']);
    const handleMouseEnter = () => {
        setEditVisible(true);
    };

    const handleMouseLeave = () => {
        setEditVisible(false);
    };

    return (

        <div onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            style={{ backgroundColor: editVisible ? 'var(--light-grey)' : '' }}>
         
            {t('dictionary.schedule')}
            { (!editLJ && !editLV && !editLS ) && 
                <span style={{ textDecoration: "none", fontSize: "16px", color: "#3C4043", marginLeft: "1em", cursor: 'pointer' }} onClick={() => setEdit(true)}>        
                    {editVisible && isUserAuthorized &&<Tag style={{borderRadius:'10px'}}><EditOutlined /> Día a día</Tag>}
                </span>
            }
            { (!edit && !editLV && !editLS ) && 
                <span style={{ textDecoration: "none", fontSize: "16px", color: "#3C4043", marginLeft: "1em", cursor: 'pointer' }} onClick={() => setEditLJ(true)}>                    
                    {editVisible && isUserAuthorized && <Tag style={{borderRadius:'10px'}}><EditOutlined /> Bloque L-J</Tag>}
                </span>
            }
            { (!edit && !editLJ && !editLS ) && 
                <span style={{ textDecoration: "none", fontSize: "16px", color: "#3C4043", marginLeft: "1em", cursor: 'pointer' }} onClick={() => setEditLV(true)}>        
                    {editVisible && isUserAuthorized && <Tag style={{borderRadius:'10px'}}><EditOutlined /> Bloque L-V</Tag>}
                </span>
            }
            { (!edit && !editLJ && !editLV ) && 
                <span style={{ textDecoration: "none", fontSize: "16px", color: "#3C4043", marginLeft: "1em", cursor: 'pointer' }} onClick={() => setEditLS(true)}>        
                    {editVisible && isUserAuthorized && <Tag style={{borderRadius:'10px'}}><EditOutlined /> Bloque L-S</Tag>}
                </span>
            }

            {!edit && !editLJ && !editLV && !editLS &&
                <div className="title-box">
                    <div style={{ margin: "1em 0", display: "flex",  flexDirection:'column', justifyContent: "flex-start" }}>
                        <RegularDayScheduleView title="Lunes" regularHour={location.regularHours![0]} />
                        <RegularDayScheduleView title="Martes" regularHour={location.regularHours![1]} />
                        <RegularDayScheduleView title="Miércoles" regularHour={location.regularHours![2]} />
                        <RegularDayScheduleView title="Jueves" regularHour={location.regularHours![3]} />
                        <RegularDayScheduleView title="Viernes" regularHour={location.regularHours![4]} />
                        <RegularDayScheduleView title="Sábado" regularHour={location.regularHours![5]} />
                        <RegularDayScheduleView title="Domingo" regularHour={location.regularHours![6]} />
                    </div>                    
                </div>
            }   
            {
                edit && <RegularWeekScheduleEdit 
                    location={location}
                    setEdit={setEdit}
                    edit={edit} />
            }
            {
                editLJ && <RegularWeekScheduleEditLJ 
                    location={location}
                    setEdit={setEditLJ}
                    edit={editLJ} />
            }
            {
                editLV && <RegularWeekScheduleEditLV
                    location={location}
                    setEdit={setEditLV}
                    edit={editLV} />
            }
            {
                editLS && <RegularWeekScheduleEditLS
                    location={location}
                    setEdit={setEditLS}
                    edit={editLS} />
            }            
        </div>
    );
};

export default NormalSchedule;