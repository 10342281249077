import { EditOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next';
import { useState } from 'react'
import { PhoneGroupEdit } from '../PhoneGroupEdit';


export const PhoneGroupMassive = (props: { primaryPhone: string, additionalPhones: string[], locationsIds: string[] }) => {
    
    const [edit, setEdit] = useState(false);
    const { t } = useTranslation();

return (
    <div>
       	<span onClick={() => setEdit(true)} style={{ display:"block",width:"100%",textDecoration: "none", fontSize: "16px", color: "#3C4043", cursor:"pointer" }}>{t('dictionary.phone')} <EditOutlined /></span>
       	<PhoneGroupEdit field='phone' setIsEdit={setEdit} isEdit={edit} isMassive={true} locationsIds={props.locationsIds} primaryPhone='' additionalPhones={[]}/>
    </div>
)
}