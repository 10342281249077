
export const getColor = (value: number) => {
    if (value < 7) {
        return 'rgba(20, 255, 0, 0.35)';
    } else if (value > 14) {
        return 'rgba(248, 132, 132)';
    } else {
        return 'rgba(248, 210, 132)';
    }
};

const ColorScore = ({ value }: any) => {

    return <div style={{
        border: `solid 2px ${getColor(value)}`, borderRadius: '22.5px', width: '80px', height: '25px', display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }}>
        <span>{value} días</span>
    </div>
};

export default ColorScore;