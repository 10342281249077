import { Button, Form, Input, Modal, message } from 'antd'
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../../../../store/AuthContext/AuthContext';
import { authFetch } from '../../../../../common/request/authFetch';


export const EditableBlockForm = (props: {field: string, reportId : string, placeholder: string, onFinish: any}) => {    
    
    const [form] = Form.useForm();
    const { t } = useTranslation();
    
    const submitText = async(value: any) => {
    
      const data = {		
        reportId: props.reportId,
        //field: props.field,		
        value: value.field
      };
     
        await authFetch(`${process.env.REACT_APP_API}/reports/${props.reportId}/fields/${props.field}`, { method: 'PATCH', data: data })
        .then((res) => {

          if (res) {
            message.success('Texto editado correctamente', 3);			
            props.onFinish();				            
          } else {
            message.error('Error inespedado al editar el texto', 3);							            
          }
        });		
      }
    
    return (
        <div>
          <Form layout='vertical' form={form} onFinish={(values) => submitText(values)} initialValues={{field: props.placeholder}}>					
            <Form.Item name='field' label='Añade tu texto al informe:' style={{ width: '100%' }}>                              
                  <Input.TextArea name='field'
                    placeholder={props.placeholder}                      
                  />
                
          </Form.Item>
            
					<Form.Item shouldUpdate style={{ marginTop: '15px' }}>
						{() => {
							return (
								<><Button
									///disabled={props.enabled != props.field + 'inputID'}
									onClick={
										() => {
											Modal.confirm({
												title: 'Confirmar nuevo texto',
												okText: t('dictionary.confirm'),
												cancelText: t('dictionary.cancel'),
												onOk: form.submit
											});
										}
									}
									type="primary">
									{t('dictionary.save')}
								</Button>
									<Button
										style={{ background: 'white', color: 'dodgerblue', marginLeft: '20px' }}
										onClick={() => { 
											form.resetFields();
											props.onFinish();
                    }}
										type="primary">
										{t('dictionary.cancel')}
									</Button>
								</>
							)
						}}
					</Form.Item>
					
          </Form>
        </div>
    )
}