import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { EditOutlined } from '@ant-design/icons';
import { CategoriesView } from '../CategoriesView';
import { CategoriesEdit } from './CategoriesEdit';
import { useIsAuthorized } from '../../../../../../pages/common/roles/Roles';

export const Categories = (props: { categories: any, locationId: string }) => {
        
    const [edit, setEdit] = useState(false);

    const [categories, setCategories] = useState(props.categories);    
    const [editVisible, setEditVisible] = useState(false);    
    const isAuthorized = useIsAuthorized();

    const { t } = useTranslation();

    const handleCancel = () => {
        setCategories(categories);
        setEdit(false);
    }

    const handleSuccess = (categories: any) => {
        setCategories(categories);
        setEdit(false);
    }

    const handleMouseEnter = () => {
        setEditVisible(true);
    };
    
    const handleMouseLeave = () => {
        setEditVisible(false);
    };
    
    return (
        <div  
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            style={{backgroundColor:editVisible ? 'var(--light-grey)' : ''}}>

            <span  onClick={() => setEdit(true)} style={{ textDecoration: "none", fontSize: "16px", color: "#3C4043", marginLeft:"1em", cursor:'pointer' }}>
                {t('dictionary.category')}
                {editVisible && isAuthorized(['ROLE_ADMIN', 'ROLE_AGENT', 'ROLE_CLIENT', 'ROLE_GUEST_INFO_EDIT']) && <EditOutlined />}
            </span>
        
            {
                !edit 
                    ?  
                    <CategoriesView categories={categories}/>                    
                    :                     
                    <CategoriesEdit 
                        locationId = {props.locationId}
                        primaryCategory = {categories.primaryCategory} 
                        additionalCategories = {categories.additionalCategories} 
                        handleSuccess = {handleSuccess}
                        handleCancel = {handleCancel} />        
            }
        </div>
    )
}
