import { Card } from "antd";
import { useFetch } from "../../../hooks/useFetch/useFetch";
import { useAuth } from "../../../store/AuthContext/AuthContext";

export const Notifications = () => {


    const { data: storesData }: any = useFetch(`${process.env.REACT_APP_API}/users/notifications/`, {}, []);

    return (
        <Card className='main-card-content'>
            <h1 className='title-card'>Notificaciones</h1>


            <div style={{ padding: '10px 20px' }}>
                <ul>
                    {storesData?.notificationList?.map((n: any) => {
                        return <li><div><h1>{n.title}</h1>{n.content}</div></li>
                    })}
                </ul>
            </div>
        </Card>
    )
}