import { Form, Input, Modal, message } from 'antd';
import 'moment/locale/es';
import { useAuth } from '../../../../../store/AuthContext/AuthContext';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { authFetch } from '../../../../../common/request/authFetch';


export const AddClientWebModal = ({
    initialValue,
    visible,
    onFinish,
}: any) => {

    const [errorMessage, setErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const { t } = useTranslation();

    const [form] = Form.useForm();
    const { groupAccount } = useAuth();

    const onFormFinish = async (values: any) => {
        await authFetch(`${process.env.REACT_APP_API}/client/web/${groupAccount}?url=${values.url}`,{ method: 'POST' }
        )
        .then((res) => {
            if (res) {
                setErrorMessage('')
                onFinish();
            } else {
                message.error('Error al subir la url de la web');
            }
        })
    }


    return (

        <Modal visible={visible} onOk={() => form.submit()} onCancel={onFinish}>

            <h2>Añade la URL de tu web</h2>
            <i>La URL es necesaria para generar la clave de la API</i>
            <Form 
            initialValues={{url:initialValue}}
            layout='vertical' form={form} onFinish={onFormFinish} >
                <Form.Item 	rules={[{ required: true }]} name={`url`}>
                    <Input />
                </Form.Item>
            </Form>
        </Modal>
    )
}

