import { Button } from 'antd';
import React from 'react';
import { SyncOutlined } from '@ant-design/icons';
import { useGoogleLogin } from '@react-oauth/google';
import { useAuth } from '../../../../store/AuthContext/AuthContext';
import { useTranslation } from 'react-i18next';

const GoogleLogin = ( props: {project: string, 	onFinish?: () => void , onCancel?: () => void} ) => {

  const { googleLogIn } = useAuth();
  const { t } = useTranslation();

  const onLogIn = (data: any) => {
    googleLogIn(data, props.project).then((res) => {
      
      if (res) {
        props.onFinish!();
      }
    })
  }
  
  const onFailure = (error: any) => {
    props.onCancel!();
    console.log(`Login error: ${error}`)
  }

  const login = useGoogleLogin({
    onSuccess: onLogIn,
    onError: onFailure,
    flow: 'auth-code',
    scope: 'https://www.googleapis.com/auth/business.manage'
  });

  return (
      <Button
        type="primary"
        onClick={login}
        icon={<SyncOutlined />}
      >
        {t('dictionary.signIn')}
      </Button>
  )
}

export default GoogleLogin;