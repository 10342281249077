import { Button, Form, Input, Modal } from 'antd';
import { FormInstance } from 'antd/es/form';
import { Rule } from 'antd/lib/form';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const EditableInputItem = (props: {
	field: string,
	label?: string,
	rules?: Rule[],
	value?: any,
	index?: number,
	form: FormInstance,
	setEdit?: any,
	edit?: boolean,
	isMassive?: boolean,
	initialValue?:any,
	onSubmitCallback: () => void
}) => {

	const [enabled, setEnabled] = useState('');

	const fieldId = props.field + 'ID';
	const { t } = useTranslation();

	return (
		<Form.Item>
			<Input.Group compact style={{display:'flex', flexDirection:'column'}}>
				<Form.Item name={props.field} style={{ margin: '0px', width: '80%' }} rules={props.rules} initialValue={props.value} >
					<Input
						id={fieldId}
						onChange={(e) => { setEnabled(e.target.value !== '' ? e.target.id : '') }}
						onFocus={(e) => { setEnabled(e.target.value !== '' ? e.target.id : '') }}
						placeholder={props.label ? t('view.forms.placeholder.input', { label: props.label }) as string : ''}
						disabled={!props.edit ? true : false} />
				</Form.Item>
				{props.edit &&
					<Form.Item shouldUpdate style={{ marginTop: '15px' }}>
						{() => {
							return (
								<><Button
									disabled={
										!!props.form.getFieldsError().filter(({ errors }) => errors.length).length
										|| enabled !== fieldId}
									onClick={() => {
										Modal.confirm({
											title: !props.isMassive ? t('view.forms.modal.single.title'): t('view.forms.modal.bulk.title'),
											content: !props.isMassive
											? t('view.forms.modal.single.content', { field: props.field })
											: t('view.forms.modal.bulk.content', { field: props.field }),
											okText: t('dictionary.confirm'),
											cancelText: t('dictionary.cancel'),
											onOk: props.onSubmitCallback
										})
									}}
									type="primary">
									{t('dictionary.save')}
								</Button>
									<Button
										style={{ background: 'white', color: 'dodgerblue', marginLeft: '20px' }}
										onClick={() => {
											props.form.resetFields();
											props.setEdit!(false);
										  }}
										type="primary">
										{t('dictionary.cancel')}
									</Button>
								</>
							)
						}}
					</Form.Item>}
			</Input.Group>
		</Form.Item>
	)
}

export default EditableInputItem;