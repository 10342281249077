import { Card, Col, Divider, Rate, Row, Tag } from "antd";
import { Area, Column, Pie, RingProgress } from "@ant-design/charts";
import { ShopOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useState } from "react";

export const BlockConversion = (props: {data: any}) => {
    
    /*const [viewsChart, setViewsChart] = useState([]);
    const [directionsChart, setDirectionsChart] = useState([]);
    const [clicksChart, setClicksChart] = useState([]);
    const [platformRatioChart, setPlatformRatioChart] = useState([]);*/

    const { t } = useTranslation();

    const map: any = {
        'BUSINESS_IMPRESSIONS_DESKTOP': 'Escritorio',
        'BUSINESS_IMPRESSIONS_MOBILE': 'Móvil',
        'BUSINESS_DIRECTION_REQUESTS': 'Total',
        'CALL_CLICKS': 'Llamadas',
        'WEBSITE_CLICKS': 'Visitas a la web'
    };

    const viewsChartConfig: any = {
        data: props.data?.viewsChart,
        isStack: true,
        xField: 'x',
        yField: 'value',
        seriesField: 'category',
        meta: {
            category: {
                formatter: (value: string | number) => {
                    const translationKey = map[value];
                    return translationKey ? t(translationKey) : value;
                },
            },

        },
        color: ['#80DEEA', '#1E88E5']
    };

    const clicksChartConfig: any = {
        data: props.data?.clicksChart,
        isStack: true,
        xField: 'x',
        yField: 'value',
        seriesField: 'category',
        meta: {
            category: {
                formatter: (value: string | number) => {
                    const translationKey = map[value];
                    return translationKey ? t(translationKey) : value;
                },
            },

        },
        color: ['#85C1E9', '#01579B']
    };

    const directionsChartConfig: any = {
        data: props.data?.directionsChart,
        isStack: true,
        xField: 'x',
        yField: 'value',
        seriesField: 'category',
        meta: {
            category: {
                formatter: (value: string | number) => {
                    const translationKey = map[value];
                    return translationKey ? t(translationKey) : value;
                },
            },

        },
        color: ['#66ACF0']
    };

    const configSearchMaps: any = {
        appendPadding: 10,
        data: props.data?.platformRatioChart,
        angleField: 'value',
        colorField: 'x',
        radius: 0.75,
        color: ['#7FB3D5', '#2663F0'],
        label: {
            type: 'inner',
            offset: '-30%',
            content: (obj: any) => `${obj.value}%`,
            style: {
                fontSize: 14,
                textAlign: 'center',
            },
        },
        interactions: [
            {
                type: 'element-selected',
            },
            {
                type: 'element-active',
            },
        ],
    };

    return (
        <>             
             <Row gutter={36} style={{ marginBottom: '20px' }}>
                    <Col span={16}>
                        <Card bodyStyle={{ backgroundColor: 'white', borderRadius: '25px', height: '327px' }} style={{ border: 'none' }}>
                            <div className="title-charts">
                                <h1>Impresiones</h1>
                            </div>
                            <div style={{ padding: '25px 0px 25px 25px' }}>
                                <Column {...viewsChartConfig} height={200} loading={false} />
                            </div>
                        </Card>
                    </Col>
                    <Col span={8}>
                        <Card bodyStyle={{ backgroundColor: 'white', borderRadius: '25px', height: '327px' }} style={{ border: 'none' }}>
                            <div className="title-charts">
                                <h1>Maps vs Search</h1>
                            </div>
                            <div style={{ padding: '25px 0px 25px 25px' }}>
                                <Pie {...configSearchMaps} height={200} loading={false} />
                            </div>
                        </Card>
                    </Col>
                </Row>
                <Row gutter={36} style={{ marginBottom: '20px' }}>
                    <Col span={12}>
                        <Card bodyStyle={{ backgroundColor: 'white', borderRadius: '25px', height: '327px' }} style={{ border: 'none' }}>
                            <div className="title-charts">
                                <h1>Búsquedas de como llegar</h1>
                            </div>
                            <div style={{ padding: '25px 0px 25px 25px' }}>
                                <Area {...directionsChartConfig} height={200} loading={false} />
                            </div>
                        </Card>
                    </Col>
                    <Col span={12}>
                        <Card bodyStyle={{ backgroundColor: 'white', borderRadius: '25px', height: '327px' }} style={{ border: 'none' }}>

                            <div className="title-charts">
                                <h1>Acciones desde la ficha</h1>
                            </div>

                            <div style={{ padding: '25px 0px 25px 25px' }}>
                                <Column {...clicksChartConfig} height={200} loading={false} />
                            </div>

                        </Card>
                    </Col>
                </Row>     
        </>
    )
}