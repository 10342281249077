import { Form, message } from 'antd'
import  { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../../../../../store/AuthContext/AuthContext';
import { getProjectStorage } from '../../../../../../store/storageContext';
import { authFetch } from '../../../../../../common/request/authFetch';
import EditableTextAreaItem from '../../../business/forms/EditableTextAreaItem';
import { GlobalVariableContext } from '../../../../../../store/GlobalVariableContext/GlobalVariableContext';
import { DrawerContext } from '../../../../../../store/DrawerContext/DrawerContext';

export const DescriptionEdit = (props: {currentDescription ?: string, locationsIds : string[], setEdit : (isEdit: boolean) => void, edit:boolean, setCurrentDescription : (currentDescription : string) => void, isMassive : boolean, handleCancel?:any}) => {    
    
    const { groupAccount } = useAuth();
    const [enabled, setEnabled] = useState('');
    const [descriptionForm] = Form.useForm();
    const { t } = useTranslation();

    const {updateGlobalData, setUpdateGlobalData } = useContext(GlobalVariableContext)

    const { setLogsDrawer } = useContext(DrawerContext)

    const submitDesc = async(values:any) => {
    
      const data = {		
        field: 'DESCRIPTION',		
        description: values.description,
        locationsNames: props.locationsIds
      };
      if (props.isMassive) {
          await authFetch(`${process.env.REACT_APP_API}/projects/` + getProjectStorage() + '/' + groupAccount + '/locations', { method: 'PATCH', data: data })
          .then((res) => {
            if (res) {	
              message.loading(t('view.forms.modal.bulk.triggered'), 5);							
              setUpdateGlobalData(!updateGlobalData)
              props.setEdit(false);
              setTimeout(() => {
                setLogsDrawer(true);
              }, 500)
            } else {
              message.error(t('view.forms.modal.bulk.error'), 5);							
            }
          });		
      } else {
        await authFetch(`${process.env.REACT_APP_API}/projects/` + getProjectStorage() + '/' + groupAccount + '/' + props.locationsIds[0], { method: 'PATCH', data: data })
        .then((res) => {

          if (res) {
            message.success(t('view.forms.modal.single.success', { field: t('dictionary.description').toLowerCase() }), 3);							
            props.setEdit(false);
            props.setCurrentDescription(values.description);
            setUpdateGlobalData(!updateGlobalData)
          } else {
            // TODO Mostrar error
            message.error(t('view.forms.modal.single.error'), 3)
          }
        });		
      }
    } 
    return (
        <div>
            <Form layout='vertical' form={descriptionForm} onFinish={(values) => submitDesc(values)} initialValues={{description:props.currentDescription}}>					
              <EditableTextAreaItem 
                form={descriptionForm}
                field='description'
                label={props.isMassive ? (t('dictionary.description') as string) : undefined}                       
                enabled={enabled} 
                setEnabled={setEnabled}
                onSubmitCallback={descriptionForm.submit}
                edit={props.edit}
                setEdit={props.setEdit}
                isMassive={props.isMassive}
                />
          </Form>
        </div>
    )
}