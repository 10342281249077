import { EditOutlined } from '@ant-design/icons'
import { useState } from 'react'
import { CategoryGroup } from '../CategoryGroup';
import { CategoriesEdit } from '../simple/CategoriesEdit';
import { CategoriesMassiveEdit } from './CategoriesMassiveEdit';


export const CategoriesMassive = (props: { primaryCategory: string, additionalCategories: string[], locationsIds: string[] }) => {
    
    const handleCancel = () => {     
        setEdit(false);
    }

    const handleSuccess = () => {
        setEdit(false);
    }
    const [edit, setEdit] = useState(false);

return (
    <div>
       	<span onClick={() => setEdit(true)} style={{ display:"block",width:"100%",textDecoration: "none", fontSize: "16px", color: "#3C4043", cursor:"pointer" }}>Categorias 
            {!edit &&<EditOutlined />}
        </span>
        {edit && 
        <CategoriesMassiveEdit 
            locationsIds = {props.locationsIds}            
            handleSuccess = {handleSuccess}
            handleCancel = {handleCancel} />     
        }           
    </div>
)
}