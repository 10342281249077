import React, { useContext, useState } from "react";
import { DrawerContext } from "../../../../store/DrawerContext/DrawerContext";
import { Drawer, Tabs } from "antd";
import { useFetch } from "../../../../hooks/useFetch/useFetch";
import Reviews from "../reviews/reviews/Reviews";
import { getProjectStorage } from "../../../../store/storageContext";
import { useAuth } from "../../../../store/AuthContext/AuthContext";
import CardDetailsReview from "../../../pages/menuReviews/3_1_Reviews/Reviews/CardDetailsReview";
import { DoubleLeftOutlined, EnvironmentOutlined, QuestionCircleOutlined, StarOutlined } from "@ant-design/icons";
import Questions from "../reviews/questions/Questions";
import QuestionDetails from "../../../pages/menuReviews/3_1_Reviews/Questions/QuestionDetails";
import { ButtonLZ } from "../../design/Buttons/ButtonLZ";
import AskAQuestion from "../../../pages/menuReviews/3_1_Reviews/Questions/modal/AskAQuestion";

export const QuestionsDrawer = () => {
    const { questionsDrawer, setQuestionsDrawer } = useContext(DrawerContext);

    const [askAQuestionModal, setAskAQuestionModal] = useState(false)
    const [updateData, setUpdateData] = useState(false)

    const [selectedQuestion, setSelectedQuestion] = useState<any>(null);

    const handleQuestionClick = (question: React.SetStateAction<null>) => {
        setSelectedQuestion(question);
    };


    const { data, isLoading }: any = useFetch(
        `${process.env.REACT_APP_API}/projects/` + getProjectStorage() + `/${questionsDrawer}/reviewsDrawer`,
        {},
        [updateData],
    );


    return (
        <>

            {questionsDrawer &&
                <Drawer
                    placement="right"
                    width={'45%'}
                    visible={questionsDrawer != null}
                    onClose={() => setQuestionsDrawer(null)}
                    bodyStyle={{ background: '#F4F7FA' }}
                    title={!isLoading && <div style={{ display: 'flex', gap: '15px' }}><EnvironmentOutlined /> <h4>
                        {data.adressLine ? `${data?.addressLine}, ${data?.locality}, ${data?.administrativeArea}` : <p>Sin ubicación</p>}</h4></div>}
                >
                    <h1 style={{marginBottom:'0px'}}>Preguntas</h1>


                    <div style={{ marginRight: '10px', display: 'flex', justifyContent: 'flex-end', height: '20px' }}>
                        {selectedQuestion ? (
                            <p style={{ cursor: 'pointer' }} onClick={() => { setSelectedQuestion(null) }}>
                                <DoubleLeftOutlined /> Atrás
                            </p>
                        ) : null}
                    </div>


                    <div style={{ display: 'flex', justifyContent: 'flex-end', minHeight: '20px', margin: '10px 0px' }}>
                        <ButtonLZ size='small' children={'Haz una pregunta'} onClick={() => { setAskAQuestionModal(true) }} />
                    </div>

                    {!selectedQuestion ?
                        <Questions questionsData={data?.questions} handleQuestionClick={handleQuestionClick} width='100%' />
                        :
                        <QuestionDetails questionId={selectedQuestion ? selectedQuestion.name : null} drawer={true} />
                    }

                    {askAQuestionModal &&
                        <AskAQuestion
                            visible={askAQuestionModal}
                            onFinish={() => {
                                setAskAQuestionModal(false);
                                setUpdateData(!updateData);
                            }}
                            locationID={questionsDrawer}

                        />}

                </Drawer>
            }
        </>
    );
};
