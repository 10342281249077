import { Card, Col, Divider, Rate, Row, Tag } from "antd";
import { RingProgress } from "@ant-design/charts";
import { ShopOutlined, StarFilled } from "@ant-design/icons";
import MathUtils from "../../../../common/utils/MathUtils";
import { useTranslation } from "react-i18next";
import reviewsIcon from '../../../../assets/icons/REVIEWS-ICON-light.png';
import { Increment } from "../../../../common/utils/Increment";

export const BlockReviewsKpis = (props: {reviewsKPIs: any}) => {

    const { t } = useTranslation();

    return (
        <>
          <Row gutter={36} style={{ marginBottom: '20px' }}>
                <Col span={6}>
                    <Card bodyStyle={{ backgroundColor: 'white', borderRadius: '25px', height: '180px' }}>
                        <div className="card-kpis">
                            <h1>{t('reviews.page.global.cards.averageRating')}</h1>
                        </div>
                        <div className="rating-container" >
                            <img src={reviewsIcon} style={{height:'60px', borderRadius: '17px'}}/>                                    
                            <span >
                                {props.reviewsKPIs?.rateAvg === null ? '-' : MathUtils.round(props.reviewsKPIs.rateAvg, 1)}
                            </span>
                        </div>
                        <div style={{marginTop:'6px'}}>
                            {props.reviewsKPIs?.rateAvgIncrement && <><Increment numberOfDecimals={2} value={props.reviewsKPIs.rateAvgIncrement}/> <small>últimos 30d</small></>}				
                        </div>
                    </Card>
                </Col>
                <Col span={6}>
                    <Card bodyStyle={{ backgroundColor: 'white', borderRadius: '25px', height: '180px' }}>
                        <div className="card-kpis">
                            <h1 >{t('reviews.page.global.cards.totalReviews')}</h1>
                        </div>
                        <div className="values-kpis">
                            <span>{props.reviewsKPIs.numberOfReviews}</span>
                        </div>
                        <div>
                            <span style={{color:'DodgerBlue'}}><b>{props.reviewsKPIs?.numberOfRecentPendingReviews} pendientes</b> últimos 30d</span>  
                        </div>
                    </Card>
                </Col>
                <Col span={6}>
                    <Card bodyStyle={{ backgroundColor: 'white', borderRadius: '25px', height: '180px' }}>
                        <div className="card-kpis">
                            <h1>Reseñas al mes</h1>
                        </div>
                        <div className="values-kpis">
                            <span>{props.reviewsKPIs.reviewsPerMonth?.toFixed(0)}</span>
                        </div>
                        <div>
                            <span style={{color:'DodgerBlue'}}><b>{'+'}{props.reviewsKPIs.numberOfRecentReviews}</b> reseñas en los últimos 30d</span>  
                        </div>
                    </Card>
                </Col>
                <Col span={6}>
                    <Card bodyStyle={{ backgroundColor: 'white', borderRadius: '25px', height: '180px' }}>
                        <div className="card-kpis">
                            <h1 >{t('reviews.page.global.cards.replyRatio')}</h1>
                        </div>
                        <div className="values-kpis">
                            <span >
                                {props.reviewsKPIs.repliedRatio === null ? '- ' : MathUtils.round(100 * props.reviewsKPIs.repliedRatio, 1)} %</span>

                        </div>
                    </Card>
                </Col>                
            </Row>                   
        </>
    )
}