import { Card, Col, Divider, Rate, Row, Tag, Tooltip } from "antd";
import { Column, RingProgress } from "@ant-design/charts";
import { InfoCircleOutlined, ShopOutlined, StarFilled } from "@ant-design/icons";
import MathUtils from "../../../../common/utils/MathUtils";
import { useTranslation } from "react-i18next";
import { getColor } from "../../../../common/utils/ColorRate";
import calendarIcon from '../../../../assets/icons/CONTENT-ICON-light.png';
import megafono from '../../../../assets/megafono.png';
import estrella from '../../../../assets/estrella-calendario.png';
import insignia from '../../../../assets/insignia.png';

export const BlockContentKpis = (props: {kpis: any}) => {

    const { t } = useTranslation();
        
    return (
        <>
            <Row gutter={20} style={{ marginBottom: '20px' }}>
                        <Col span={5}>
                            <Card bodyStyle={{ backgroundColor: 'white', borderRadius: '25px', height: '150px' }}>
                                <div className="card-kpis">
                                    <h1>{t('contents.page.global.cards.totalPosts')}</h1>
                                    <Tooltip title={t('contents.page.global.cards.totalPostsTooltip')}><InfoCircleOutlined /></Tooltip>
                                </div>
                                <div className="totalPost-container" >
                                    <img src={calendarIcon} style={{height:'60px', borderRadius: '17px'}}/>                                    
                                    <span>
                                        {props.kpis?.totalPosts}
                                    </span>
                                </div>
                            </Card>
                        </Col>
                        <Col span={9}>
                            <Card bodyStyle={{ backgroundColor: 'white', borderRadius: '25px', height: '150px' }}>
                                <div className="card-kpis">
                                    <h1 >{t('contents.page.global.cards.postType')}</h1>                                    
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                    <div>
                                        <div className="values-kpis-posts">
                                            <span>{props.kpis?.totalNews}</span>
                                            <img src={megafono} style={{ height: '28px' }} />
                                        </div>
                                        <div className="tag-kpis">{t('contents.page.global.cards.news')}</div>

                                    </div>
                                    <div>
                                        <div className="values-kpis-posts">
                                            <span>{props.kpis?.totalEvents}</span>
                                            <img src={estrella} style={{ height: '28px' }} /></div>
                                        <div className="tag-kpis">{t('contents.page.global.cards.events')}</div>

                                    </div><div>
                                        <div className="values-kpis-posts">
                                            <span>{props.kpis?.totalOffers}</span>
                                            <img src={insignia} style={{ height: '28px' }} /></div>
                                        <div className="tag-kpis">{t('contents.page.global.cards.offers')}</div>
                                    </div>
                                </div>
                            </Card>
                        </Col>
                        <Col span={5}>
                            <Card bodyStyle={{ backgroundColor: 'white', borderRadius: '25px', height: '150px' }}>
                                <div className="card-kpis">
                                    <h1 >{t('contents.page.global.cards.impressions')}</h1>
                                    <Tooltip title={t('contents.page.global.cards.impressionsTooltip')}><InfoCircleOutlined /></Tooltip>
                                </div>
                                <div className="values-kpis">
                                    <span style={{display:'flex', gap:'5px', alignItems:'center'}}>
                                        {props.kpis && props.kpis?.impressions === null ? '- ' : <span>{MathUtils.round(props.kpis?.impressions, 1)}</span>}                                        
                                    </span>
                                </div>                                
                            </Card>
                        </Col>
                        <Col span={5}>
                            <Card bodyStyle={{ backgroundColor: 'white', borderRadius: '25px', height: '150px' }}>
                                <div className="card-kpis">
                                    <h1 >{t('contents.page.global.cards.postFrequency')}</h1>
                                    <Tooltip title={t('contents.page.global.cards.postFrequencyTooltip')}><InfoCircleOutlined /></Tooltip>                                        
                                </div>
                                <div className="values-kpis">
                                    <span  style={{display:'flex', gap:'5px', alignItems:'center'}}>
                                        {props.kpis && props.kpis?.postFrequency === null ? '- ' : <span>{MathUtils.round(props.kpis?.postFrequency, 2)}</span>}
                                        <small>{t('contents.page.global.cards.days')}</small>                                        
                                    </span>
                                </div>
                            </Card>
                        </Col>
                    </Row>
        </>
    )
}