import { CheckCircleFilled } from "@ant-design/icons";
import { Card } from "antd";
import { useHistory } from 'react-router';
import background from '../../../assets/greyMap.png';
import { ButtonLZ } from "../../components/design/Buttons/ButtonLZ";
import './Welcome.css'

const Welcome = () => {

  const history = useHistory();

  return (
      <div className="container"  style={{backgroundImage: `url(${background})`}}>
        <Card bodyStyle={{ height: '369px', width: '747px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          <div className="content">
            <CheckCircleFilled className="icon" />
            <h1>Registro completado</h1>
            <p>Has completado con éxito el registro y configuración de tu cuenta.</p>
          </div>
          <div className="button-container">
            <ButtonLZ onClick={()=>{history.push('/negocios/locales')}} size='large' color="yellow">Comenzar</ButtonLZ>
          </div>
        </Card>
      </div>
  )
};

export default Welcome;