import { Alert, Button, Drawer, Radio, Switch, Tag } from 'antd';
import { useFetch } from '../../../../hooks/useFetch/useFetch';
import moment from 'moment';
import PDFGenerator from '../../../components/model/pdf/GeneratePDF';
import { BlockInfoKpis } from '../blocks/BlockInfoKpis';
import { BlockConversion } from '../blocks/BlockConversion';
import { BlockReviewsKpis } from '../blocks/BlockReviewsKpis';
import { BlockReviewsGlobal } from '../blocks/BlockReviewsGlobal';
import { BlockContentGlobal } from '../blocks/BlockContentGlobal';
import { BlockContentKpis } from '../blocks/BlockContentKpis';
import { useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { EditableBlock } from './form/EditableBlock';

export const PublicReport = (props : {reportId: string, onFinish: any, isEdit: boolean, pdfElement?: any}) => {
	
	//const reportId = new URLSearchParams(location.search).get('reportId');
	const { id: reportId } = useParams<any>();

	const isAuthenticated = props.reportId;
    const key = props.reportId || reportId;
	
	const [updateData, setUpdateData] = useState(false);	
	//props.pdfElement = useRef<HTMLDivElement>(null);

	const { data: reportData, isLoading }: any = useFetch(`${process.env.REACT_APP_API}/public/reports/${key}`, {}, [updateData]);
	
  	return (
		<>
		{reportData.template &&
		 	!isAuthenticated && !reportData.published ?
			 <Alert
				message="Este informe es privado."
				description="No tienes permisos para ver este informe."
				type="warning"
				showIcon
				//closable
			/>
			:
			<div className='title-card' id='element-to-capture' ref={props.pdfElement} style={{background: '#F4F7FA', margin:'20px', padding:'40px 150px', height:'500%', fontSize:'17px'}}>
				<h1 style={{marginTop:'30px', fontSize:'50px'}}>{reportData.template?.name}</h1>								
				<h3 style={{color:'steelblue'}}><b>Mes: {moment([reportData.year, reportData.month - 1]).format('YYYY MMMM')}</b></h3>
				<br/><br/>						
				{
					reportData.template?.info && 
					<>
						<h1 style={{marginTop:'30px', color:'steelblue'}}><i>Negocio</i></h1>
						{props.isEdit ? 
							<EditableBlock field='info' placeholder={reportData?.textBlocks['info']} reportId={props.reportId} onFinish={() => {setUpdateData(!updateData)}}/>
							:
							<p>{reportData?.textBlocks && reportData?.textBlocks['info']}</p>
						}
						{reportData && <BlockInfoKpis kpis={reportData.locationsSummary?.kpis}/>}
					</>
				}
				{
					reportData.template?.conversion && 
					<>
						<h1 style={{marginTop:'30px', color:'steelblue'}}><i>Conversión</i></h1>
						{props.isEdit ? 
							<EditableBlock field='conversion' placeholder={reportData?.textBlocks['conversion']} reportId={props.reportId} onFinish={() => {setUpdateData(!updateData)}}/>
							:
							<p>{reportData?.textBlocks && reportData?.textBlocks['conversion']}</p>
						}
						{reportData.locationsSummary && <BlockConversion data={reportData.locationsSummary}/>}
					</>
				}
				{
					reportData.template?.reviews && 
					<>
						<h1 style={{marginTop:'30px', color:'steelblue'}}><i>Reputación</i></h1>
						{props.isEdit ? 
							<EditableBlock field='reviews' placeholder={reportData?.textBlocks['reviews']} reportId={props.reportId} onFinish={() => {setUpdateData(!updateData)}}/>
							:
							<p>{reportData?.textBlocks && reportData?.textBlocks['reviews']}</p>
						}
						{reportData.reviewsSummary && <BlockReviewsKpis reviewsKPIs={reportData.reviewsSummary.reviewsKPIs}/>}
						{reportData.reviewsSummary && <BlockReviewsGlobal data={reportData.reviewsSummary}/>}                    
					</>
				}
				{
					reportData.template?.content && 
					<>
						<h1 style={{marginTop:'30px', color:'steelblue'}}><i>Contenido</i></h1>
						{props.isEdit ? 
							<EditableBlock field='content' placeholder={reportData?.textBlocks['content']} reportId={props.reportId} onFinish={() => {setUpdateData(!updateData)}}/>
							:
							<p>{reportData?.textBlocks && reportData?.textBlocks['content']}</p>
						}
						{reportData.contentsSummary && <BlockContentKpis kpis={reportData.contentsSummary.postKPIs}/>}
						{reportData.contentsSummary && <BlockContentGlobal data={reportData.contentsSummary}/>}                    
					</>
				}
				
			</div>
		}
	</>	
	)
}
