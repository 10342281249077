import { useState } from 'react';
import { DeleteOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Drawer, Popconfirm, Switch, Tooltip } from 'antd';
import TableLZM from '../../design/table/TableLZM';
import { ButtonLZ } from '../../design/Buttons/ButtonLZ';
import "./CompanyIAGuide.css"
import { authFetch } from '../../../../common/request/authFetch';
import { getProjectStorage } from '../../../../store/storageContext';
import { useAuth } from '../../../../store/AuthContext/AuthContext';
import { useFetch } from '../../../../hooks/useFetch/useFetch';
import IAGuideModal from './modal/IAGuideModal';

const mockData = [
    {
        caseToAnswer: "Reseña con puntuación 1 o 2 y sin texto",
        answer: "Pedir más información para poder entender el problema"
    },
    {
        caseToAnswer: "Reseña con puntuación 4 o 5 y sin texto",
        answer: "Agradecer la reseña"
    },
    {
        caseToAnswer: "Reseña con puntuación 1 o 2 y texto quejándose del servicio",
        answer: "Sugerir que envíen un email a servicio@nombreempresa.net"
    },
    {
        caseToAnswer: "Reseña con puntuación 1 o 2 y texto quejándose del producto",
        answer: "Sugerir que envíen un email a producto@nombreempresa.net"
    },
    {
        caseToAnswer: "Reseña con puntuación 3,4 o 5 y con texto",
        answer: "Decir la frase: Gracias por confiar en nosotros."
    },
    {
        caseToAnswer: "Para cualquier puntuación y texto",
        answer: "Firmar con: NombreDeEmpresa. Dejando una linea antes de la firma",
    }

]

const CompanyIAGuide = () => {

    const { groupAccount } = useAuth();

    const [openModal, setOpenModal] = useState(false);
    const [updateData, setUpdateData] = useState(false);
    const [isExample, setIsExample] = useState(false);

    const { data, isLoading } = useFetch(`${process.env.REACT_APP_API}/projects/` + getProjectStorage() + '/' + groupAccount + '/ia/guide', {}, [updateData]);

    const handleDelete = async (record: any) => {
        await authFetch(
            `${process.env.REACT_APP_API}/projects/` + getProjectStorage() + '/' + groupAccount + '/ia/guide',
            { method: 'DELETE', data: record }
        ).then(() => { setUpdateData(!updateData) })

    };

    const columns = [
        {
            title: 'Caso',
            dataIndex: 'caseToAnswer',
            editable: true,
            render: (text: any, record: any) => (
                <span style={{ color: isExample ? 'grey' : 'black' }}>{text}</span>
            ),
        },
        {
            title: 'Instrucción',
            dataIndex: 'answer',
            editable: true,
            render: (text: any, record: any) => (
                <span style={{ color: isExample ? 'grey' : 'black' }}>{text}</span>
            ),

        },
        {
            title: '',
            dataIndex: 'operation',
            render: (_: any, record: any) => {

                return data.length >= 1 ? (
                    <Popconfirm title="Confirmar eliminación" onConfirm={() => handleDelete(record)}>
                        <DeleteOutlined style={{ color: isExample ? 'grey' : 'black' }} />
                    </Popconfirm>
                ) : null
            }
        },
    ];

  
    return (
        <div>
            <div style={{ display: 'flex', gap: '10px', alignItems: 'center', marginBottom: '16px' }}>
                <ButtonLZ onClick={() => { setOpenModal(true) }} >
                    Añadir instrucción
                </ButtonLZ>
                <Tooltip placement="right" title='Las instrucciones que se añadan las tendrá en cuenta la IA para generar sus repuestas'>
                    <InfoCircleOutlined />
                </Tooltip>
            </div>

        

            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <a onClick={() => { setIsExample(true) }}>Ver ejemplo</a>
            </div>

            <TableLZM
                size='small'
                dataSource={data}
                columns={columns}
                loading={isLoading}
                style={{ color: 'grey' }}
            />

            {openModal && <IAGuideModal visible={openModal} onFinish={() => { setOpenModal(false) }} setUpdateData={setUpdateData} updateData={updateData} />}


            {
                isExample &&
                <Drawer
                width={'40%'}
                visible={isExample}
                onClose={()=>{setIsExample(false)}}
                >
                    <h1 style={{color:'grey'}}>Ejemplo de referencia</h1>
                    <p style={{color:'grey'}}>Este es un ejemplo que puedes usar como referencia para crear tus propias intrucciones</p>
                    <TableLZM
                        size='small'
                        dataSource={mockData}
                        columns={columns}
                        loading={isLoading}
                        style={{ color: 'grey' }}
                    />
                </Drawer>
            }
        </div>
    );
};

export default CompanyIAGuide;