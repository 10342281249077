const CURRENT_PROJECT = 'localizame_project';

export const setProjectStorage = (project: string) => {
  localStorage.setItem(CURRENT_PROJECT, project);
}

export const getProjectStorage = () => {
  return localStorage.getItem(CURRENT_PROJECT) || '';
}

export const setGroupAccountStorage = (project: string, value: string) => {
  localStorage.setItem(project, value);
}

export const getGroupAccountStorage = (project: string) => {
  return localStorage.getItem(project) || '';
}