import { Card, Col, Divider, Rate, Row, Tag, Tooltip } from "antd";
import { Column, RingProgress } from "@ant-design/charts";
import { InfoCircleOutlined, ShopOutlined, StarFilled } from "@ant-design/icons";
import MathUtils from "../../../../common/utils/MathUtils";
import { useTranslation } from "react-i18next";


export const BlockContentGlobal = (props: {data: any}) => {

    const { t } = useTranslation();
  
    const postTypeNames: any = {
        'STANDARD': 'contents.page.global.cards.news',
        'OFFER': 'contents.page.global.cards.offers',
        'EVENT': 'contents.page.global.cards.events'
    };

    const config : any = {        
        isStack: true,
        xField: 'x',
        yField: 'value',
        seriesField: 'category',
        color: ['#2DA485', '#52E15D', '#54F9B8'],
        label: {
            position: 'middle',
            formatter: (datum: any) => {
                return datum.value > 0 ? datum.value : '';
              },
        },
        meta: {
            category: {
                formatter: (value: string | number) => {
                    const translationKey = postTypeNames[value];
                    return translationKey ? t(translationKey) : value;
                },
            },

        },
    };

    return (
        <>
          <Row gutter={36} style={{ marginBottom: '20px' }}>
            <Col span={12}>
                {}
            </Col>
            <Col span={24}>
                {props.data.numberOfPostsByWeek && <Card bodyStyle={{ backgroundColor: 'white', borderRadius: '25px', height: '327px' }} style={{ border: 'none' }}>
                    <div className="title-charts">                                    
                        <h1>{t('contents.page.global.cards.totalNumberOfPosts')}
                            <p><small>{t('contents.page.global.cards.totalPostsOverTimeSmall')}</small></p>  
                        </h1>                                    
                    </div>                                
                    <div style={{ padding: '25px' }}>
                        <Column data={props.data.numberOfPostsByWeek} {...config} height={200} />
                    </div>
                </Card>}
            </Col>
        </Row>

        <Row gutter={36}>
            <Col span={12}>
                {}
            </Col>
            <Col span={24}>
                {props.data.numberOfImpressionsByWeek && <Card bodyStyle={{ backgroundColor: 'white', borderRadius: '25px', height: '327px' }} style={{ border: 'none' }}>
                    <div className="title-charts">                                    
                        <h1>{t('contents.page.global.cards.totalPostsOverTime')}
                            <p><small>{t('contents.page.global.cards.totalPostsOverTimeSmall')}</small></p>  
                        </h1>                                    
                    </div>                                
                    <div style={{ padding: '25px' }}>
                        <Column data={props.data.numberOfImpressionsByWeek} {...config} height={200} />
                    </div>
                </Card>}
            </Col>
        </Row>  
        </>
    )
}