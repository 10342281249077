import { Tooltip, Image } from "antd";
import { t } from "i18next";
import donutYellow from "../../../../assets/background/medio-donut-yellow.svg"
import circleGreen from "../../../../assets/background/ellipse-green.svg"
import circleYellow from "../../../../assets/background/circle-yellow.svg"
import circlePink from "../../../../assets/background/circle.pink.svg"
import nocover from "../../../../assets/no-cover.png"
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import HoverIcon from "../../design/HoverIcon/HoverIcon";

export default function Cover({ handleModal, portada, isLoading }: any) {
    return (
      <div className='portada-pic-card' style={{ overflow: 'hidden' }}>
        {isLoading ? (
          <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
        ) : (
          <>
            {portada !== '' ? (
              <Image
                width={'100%'}
                style={{ cursor: 'pointer', borderRadius: '5%' }}
                className='portada-pic-img'
                preview={false}
                src={portada}
                referrerPolicy="no-referrer"
              />
            ) : (
                <div style={{ width: '100%', height: '100%', position: 'relative', alignItems: 'center', display: 'flex', flexDirection: 'column' }}
            >
                <h1 style={{ color: '#AAADAE', fontWeight: 600, fontSize: '20px', marginTop: '50px', zIndex: 999, textShadow: '1px 2px 2px rgba(255, 255, 255)' }}>No hay portada</h1>
                <img src={nocover} style={{ position: 'absolute', width: '180px' }} />
                <img src={circleYellow} style={{ position: 'absolute', width: '80px', top: 0, right: '18%' }} />
                <img src={circleGreen} style={{ position: 'absolute', width: '50px', top: '20%', right: '16%' }} />
                <img src={circlePink} style={{ position: 'absolute', width: '200px', top: '-10%', left: '-10%', transform: 'rotate(100deg)' }} />
                <img src={donutYellow} style={{ position: 'absolute', width: '250px', bottom: '-8%', right: '-22px' }} />
            </div>            )}
            <HoverIcon onClick={() => handleModal(`${t('dictionary.edit')} ${t('dictionary.image').toLowerCase()} ${t('dictionary.cover').toLowerCase()}`, 'COVER')}
/>
          </>
        )}
      </div>
    );
  }