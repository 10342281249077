import { Form, Input, Modal, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import 'moment/locale/es';
import { useAuth } from '../../../../../store/AuthContext/AuthContext';
import { getProjectStorage } from '../../../../../store/storageContext';
import { authFetch } from '../../../../../common/request/authFetch';


const IAGuideModal = ({
	visible,
	onFinish,
	setUpdateData,
	updateData
}: any) => {

	const [form] = Form.useForm();
	const { groupAccount } = useAuth();

	const onFormFinish = async (values: any) => {

		await authFetch(`${process.env.REACT_APP_API}/projects/` + getProjectStorage() + '/' + groupAccount + '/ia/guide', { method: 'POST', data: values }).then(() => { setUpdateData(!updateData) })
		onFinish();
	}


	return (

		<Modal visible={visible} onOk={() => form.submit()} onCancel={onFinish} afterClose={form.resetFields}>

			<h1>Instrucciones</h1>
			<Form layout='vertical' form={form} onFinish={onFormFinish} >

				<Form.Item
					rules={[{ required: true, message:'Por favor, ingrese el caso' }]}
					name={`caseToAnswer`}
					label={
						<>
							Caso{' '}
							<span style={{ marginLeft: '5px' }}>
								{' '}
								<Tooltip placement="right" title="Casos de reseñas que pueden haber.">
									<InfoCircleOutlined />
								</Tooltip>
							</span>
						</>
					}
				>
					<Input.TextArea rows={4} placeholder='Reseña con puntuación 1 o 2 y texto quejándose del servicio'/>
				</Form.Item>
				<Form.Item rules={[{ required: true, message:"Por favor, ingrese la instrucción" }]} name="answer"
					label={
						<>
							Instrucción{' '}
							<span style={{ marginLeft: '5px' }}>
								{' '}
								<Tooltip placement="right" title="Lo que tiene que responder la IA cuando exista ese caso.">
									<InfoCircleOutlined />
								</Tooltip>
							</span>
						</>
					}
				>
					<Input.TextArea
						rows={4}
						placeholder='Sugerir que envíen un email a servicio@nombreempresa.net'
					/>
				</Form.Item>
			</Form>
		</Modal>
	)
}

export default IAGuideModal;