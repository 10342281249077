import { Modal, Form, Input, Select } from 'antd';
import { useEffect, useState } from 'react';
import { authFetch } from '../../../../../common/request/authFetch';
import { useTranslation } from 'react-i18next';
import { ROLES } from '../../../common/roles/Roles';

export const UserEditRoles = (props : {visible : boolean, onClose : any, roleSelected: any, setUpdateUserData?: any, rolesData : any[]}) => {

    
    const [form] = Form.useForm();

	const { t } = useTranslation();    

    const onFormFinish = async (values: any) => {
        const data = {
            email: props.roleSelected.email,
            roles:values.roles,
        };
        await authFetch(`${process.env.REACT_APP_API}/users/${data.email}/roles/${data.roles}`, { method: 'PUT', data: data }).then(()=>window.location.reload());
        props.setUpdateUserData( (c: any) => !c);
        props.onClose();
    };


  return (
    <>
        <Modal 
            visible={props.visible} 
            onOk={() => form.submit()} 
            onCancel={props.onClose} 
            afterClose={form.resetFields}>
            <h2>Editar roles</h2>
            <Form 
                layout='vertical' 
                form={form} 
                onFinish={onFormFinish}
                autoComplete="off">
                <Form.Item 
                    initialValue={props.roleSelected.email}
                    label='Email' 
                    name='email'
                    >
                    <Input disabled={true} className="disabled" />
                </Form.Item>
                <Form.Item label='Roles' name='roles' rules={[{ required: true }]}>
					<Select mode="multiple" defaultValue={props.roleSelected.roles}>
                        {Object.keys(ROLES).map((key: string) => (
                                <Select.Option value={key}>
                                    {key}
                                </Select.Option>
                            )
                        )}	
					</Select>
				</Form.Item>			
            </Form>
        </Modal>
    </>
  )
}