import { Button, Divider, Form, message, Modal } from "antd";

import { useTranslation } from 'react-i18next';
import { authFetch } from "../../../../../../common/request/authFetch";
import { getProjectStorage } from "../../../../../../store/storageContext";
import { TimeRangeItem } from "../../../business/forms/TimeRangeItem";
import { useAuth } from "../../../../../../store/AuthContext/AuthContext";
import { useContext } from "react";
import { DrawerContext } from "../../../../../../store/DrawerContext/DrawerContext";

export const RegularWeekScheduleMassiveEdit = (props: {locationsIds: string[], onFinish: any}) => {

	const { t } = useTranslation();
	const [shiftForm] = Form.useForm()
	const { groupAccount } = useAuth();
	const { setLogsDrawer } = useContext(DrawerContext);
		
	const submitEdit = async (values: any) => {

		function getPeriods(values: any, day: any) {

			var ranges = values[day + '_Ranges'] || [];
			
			var periods = ranges && ranges.map((r: any) => ({ 
				openDayName: day,
				openTimeHour: r[0].hour(),
				openTimeMinute: r[0].minute(),
				closeDayName: day,
				closeTimeHour: r[1].hour(),
				closeTimeMinute: r[1].minute()
			}));
			
			return periods;
		}

		const WEEK = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY']
		const periods: any[] = [];
		WEEK.forEach((value) => periods.push(getPeriods(values, value)));
		  
		const data = {
			field: 'REGULAR_HOURS',
			regularHours: periods,
			locationsNames: props.locationsIds
		};

		await authFetch(`${process.env.REACT_APP_API}/projects/` + getProjectStorage() + '/' + groupAccount + '/locations', { method: 'PATCH', data: data })
			.then((res) => {
				if (res) {
					message.loading(t('view.forms.modal.bulk.triggered'), 5);					
					setLogsDrawer(true);
				} else {
					message.error(t('view.forms.modal.bulk.error'), 5);
				}
			});
		props.onFinish();
	};

	return (
		<>
			<Form 
				layout='vertical' 
				form={shiftForm} 
				onFinish={(values) => submitEdit(values)}>						

				<TimeRangeItem ranges={[]} field='MONDAY' label={t('dictionary.monday')}/>
				<TimeRangeItem ranges={[]} field='TUESDAY' label={t('dictionary.tuesday')}/>
				<TimeRangeItem ranges={[]} field='WEDNESDAY' label={t('dictionary.wednesday')}/>
				<TimeRangeItem ranges={[]} field='THURSDAY' label={t('dictionary.thursday')}/>
				<TimeRangeItem ranges={[]} field='FRIDAY' label={t('dictionary.friday')}/>
				<TimeRangeItem ranges={[]} field='SATURDAY' label={t('dictionary.saturday')}/>
				<TimeRangeItem ranges={[]} field='SUNDAY' label={t('dictionary.sunday')}/>
				
				<Form.Item shouldUpdate style={{ marginTop: '15px' }}>
					{() => {
						return (
							<>
								<Button
									style={{ background: 'var(--primary)', color: 'white', marginLeft: '20px' }}
									onClick={
										() => {
											Modal.confirm({
												title: t('view.forms.modal.single.title'),											
												okText: t('dictionary.confirm'),
												cancelText: t('dictionary.cancel'),
												onOk: shiftForm.submit
											});
										}
									}>
								{t('dictionary.save')}
							</Button>
								<Button
									style={{ background: 'white', color: 'dodgerblue', marginLeft: '20px', borderColor: 'dodgerblue' }}
									onClick={() => {
										shiftForm.resetFields();
										props.onFinish();
									}}
									type="primary">
									{t('dictionary.cancel')}
								</Button>
							</>
						)
					}}
				</Form.Item>
			</Form>

		</>
	)
}