import { Button, Form, Modal, message } from 'antd'
import { useTranslation } from 'react-i18next';
import InputCategories from '../InputCategories';
import { authFetch } from '../../../../../../../common/request/authFetch';
import { getProjectStorage } from '../../../../../../../store/storageContext';
import { useAuth } from '../../../../../../../store/AuthContext/AuthContext';
import { useContext } from 'react';
import { GlobalVariableContext } from '../../../../../../../store/GlobalVariableContext/GlobalVariableContext';

export const CategoriesEdit = (props: { locationId: string, primaryCategory: any, additionalCategories: any, handleCancel?: () => any, handleSuccess?: (data: {}) => any}) => {

    const { t } = useTranslation();
    const { groupAccount } = useAuth();
    const {updateGlobalData, setUpdateGlobalData } = useContext(GlobalVariableContext);
    const [categoryForm] = Form.useForm();

    const categoriesField = 'CATEGORIES';

    const submitCategory = async (values: any) => {
 
        const data = {
          field: categoriesField,
          categories: {
            primaryCategory: values.primaryCategory,
            
            additionalCategories:  values.additionalCategories,
          },
          locationsNames: props.locationId
        };    
    
        await authFetch(`${process.env.REACT_APP_API}/projects/` + getProjectStorage() + '/' + groupAccount + '/' + props.locationId, { method: 'PATCH', data: data })
        .then((res) => {

            if (res) {
                message.success(t('view.forms.modal.single.content', { field: t('dictionary.category').toLowerCase() }), 3);
                setUpdateGlobalData(!updateGlobalData)
                props.handleSuccess!(data.categories)
            } else {
                message.error(t('view.forms.modal.single.error'), 3)
            }
        });
      }

    return (
        <>
        <Form layout='vertical' form={categoryForm} onFinish={(values) => submitCategory(values)} >
            <Form.Item name='primaryCategory'
                style={{ width: '80%' }}
                initialValue={props.primaryCategory}
            >
                <InputCategories id={categoriesField} defaultValue={props.primaryCategory}
                //   onChagenRule={(e: { target: { value: string; id: any; }; }) => { setEnabled(e.target.value !== '' ? e.target.id : '') }}
                //   onFocus={(e: { target: { value: string; id: any; }; }) => { setEnabled(e.target.value !== '' ? e.target.id : '') }}
                />
            </Form.Item>

            <Form.List name='additionalCategories' initialValue={props.additionalCategories}>

                {(fields, { add, remove }) => {
                    return (
                        <>
                            {
                                fields.map((field, index) => (
                                    <div key={field.key}>
                                        {
                                            <div style={{ display: 'flex' }}>
                                                <Form.Item name={[index]}
                                                    style={{ width: '80%' }}>
                                                    <InputCategories id={categoriesField}
                                                        defaultValue={props.additionalCategories[index]}
                                                        onChangeSelected={(selectedValue: any) => {
                                                            categoryForm.setFieldsValue({
                                                                [`additionalCategories[${index}]`]: selectedValue,
                                                            });
                                                        }}
                                                    />
                                                </Form.Item>
                                                {
                                                    fields.length > 0 && <button
                                                        style={{ width: '20%', height: '32px', backgroundColor: "transparent", border: "none", fontWeight: 600, padding: "0 1em", cursor: "pointer" }}
                                                        onClick={() => remove(field.name)} >
                                                        X
                                                    </button>
                                                }
                                            </div>
                                        }
                                    </div>
                                ))
                            }
                            {(fields.length < 9) &&
                                <button type='button' style={{ paddingBottom: "1em", border: "none", backgroundColor: "transparent", color: "#2781DD", fontWeight: 600, cursor: "pointer" }} onClick={() => add()}>
                                    {t('view.forms.fields.category.add')}
                                </button>}
                        </>)
                }}
            </Form.List>
            <div>
                <Button
                //   disabled={
                //     !!categoryForm.getFieldsError().filter(({ errors }:any) => errors.length).length
                //     || enabled !== fieldId}
                onClick={() => {
                    Modal.confirm({
                        title: t('view.forms.modal.bulk.title'),
                        content: t('view.forms.modal.bulk.content', { field: t('dictionary.category').toLowerCase() }),
                        okText: t('dictionary.confirm'),
                        cancelText: t('dictionary.cancel'),
                        onOk: categoryForm.submit
                    })
                }}
                type="primary">
                {t('dictionary.save')}
                </Button>
                <Button
                    style={{ background: 'white', color: 'dodgerblue', marginLeft: '20px' }}
                    onClick={() => {
                        props.handleCancel!()
                    }}
                    type="primary">
                    {t('dictionary.cancel')}
                </Button>
            </div>
            </Form>
        </>
    )
}
